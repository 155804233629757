import { useElectron } from '@fluency/ui/providers/electron/ElectronContext';
import { PlusIcon } from 'lucide-react';
import { useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useGetDocumentData, useInsertStep } from '../hooks/index';
import IconBar from './IconBar';
import { withPermissionGate } from '@fluency/ui/providers/permissions/PermissionGateProvider';
import RecordDropdown from './RecordFromExisting';
import { Step } from '@fluency/ui/hooks/documentation/types/master';

type StepInsertProps = {
  docId: string;
  stepBeforeId: string | null;
};

const StepInsert = ({ docId, stepBeforeId }: StepInsertProps) => {
  const { data } = useGetDocumentData(docId);
  const [showIconBar, setShowIconBar] = useState(false);
  const { isElectron } = useElectron();
  const iconBarRef = useRef<HTMLDivElement>(null);
  const insertStepsMutation = useInsertStep();

  const handlePlusClick = () => {
    setShowIconBar(!showIconBar);
  };

  const prepareInsertStep = (
    stepType: string,
    stepDetails: Partial<Step> = {}
  ) => {
    const stepId = uuidv4();
    const insertStepData = {
      documentationId: docId,
      stepBeforeId: stepBeforeId,
      insertedSteps: [
        {
          stepId: stepId,
          stepDescription:
            stepDetails.stepDescription || 'Enter description...',
          stepType: stepType,
          newIndex: 0,
        },
      ],
    };

    insertStepsMutation.mutate(insertStepData);
  };

  const handleIconClick = (action: string) => {
    setShowIconBar(false);
    prepareInsertStep(action);
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        iconBarRef.current &&
        !iconBarRef.current.contains(event.target as Node)
      ) {
        setShowIconBar(false);
      }
    }

    if (showIconBar) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showIconBar]);

  return (
    <div
      className="absolute flex print:hidden"
      style={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
    >
      <div
        className="flex items-center justify-center cursor-pointer rounded-full bg-slate-100 p-1 w-6 h-6"
        onClick={handlePlusClick}
      >
        {showIconBar ? (
          <IconBar onIconClick={handleIconClick} ref={iconBarRef} />
        ) : (
          <PlusIcon className="w-6 h-6 text-slate-500" />
        )}
      </div>
      {isElectron && data && !showIconBar && stepBeforeId && (
        <RecordDropdown stepBeforeId={stepBeforeId} documentationId={docId} />
      )}
    </div>
  );
};

export default withPermissionGate(StepInsert, ['documents:update']);
