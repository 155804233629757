import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@fluency/ui/components/ui/dialog';
import { Input } from '@fluency/ui/components/ui/input';
import { Button } from '@fluency/ui/components/ui/button';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@fluency/ui/components/ui/form';
import {
  contentSchema,
  titleSchema,
} from '@fluency/ui/features/ViewDocument/schemas';
import useUpdateDoc from '@fluency/ui/hooks/documentation/useUpdateDoc';
import { Loader2 } from 'lucide-react';

interface DocumentDialogProps {
  open: boolean;
  documentId?: string | null;
  setOpen: (open: boolean) => void;
  defaultValue?: { title?: string; description?: string };
}

export const DocumentNodeDialog = (props: DocumentDialogProps) => {
  const {
    open,
    documentId = '',
    setOpen,
    defaultValue = { title: '', description: '' },
  } = props;

  // Mutation
  const { mutateAsync: updateDoc, isLoading } = useUpdateDoc({
    id: documentId ?? '',
    type: 'node',
    invalidateQueries: [['documentation']],
  });

  // Schema Validation
  const renamedTitleSchema = titleSchema
    .extend({
      title: titleSchema.shape.value,
    })
    .omit({ value: true });

  const renamedContentSchema = contentSchema
    .extend({
      description: contentSchema.shape.value,
    })
    .omit({ value: true });
  const schema = renamedTitleSchema.merge(renamedContentSchema);
  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    defaultValues: {
      title: defaultValue.title,
      description: defaultValue.description,
    },
  });

  async function onSubmit(values: z.infer<typeof schema>) {
    await updateDoc({
      documentationName: values.title,
      documentationDescription: values.description,
    });
    setOpen(false);
  }

  return (
    <Dialog open={open} onOpenChange={() => setOpen(!open)}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{`Edit Document`}</DialogTitle>
          <DialogDescription>
            {'Update your document details.'}
          </DialogDescription>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
            <FormField
              control={form.control}
              name={'title'}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{'Document Name'}</FormLabel>
                  <FormControl>
                    <Input
                      placeholder={`Enter your document name`}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name={'description'}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>{'Document Description'}</FormLabel>
                  <FormControl>
                    <Input
                      placeholder={`Enter your document description`}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <DialogFooter>
              <Button variant="outline" onClick={() => setOpen(!open)}>
                Cancel
              </Button>
              {isLoading ? (
                <Button disabled>
                  <Loader2 className="animate-spin" />
                  Please wait
                </Button>
              ) : (
                <Button type="submit">Save</Button>
              )}
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
