import { PermissionGateProvider } from '@fluency/ui/providers/permissions/PermissionGateProvider';
import UserTable from '../../Settings/components/Members/UserTable/UserTable';
import InvitationsTable from '../../Settings/components/Members/InviteTable/InvitationsTable';

interface MemberDetailsProps {
  orgId: string;
}

const MemberDetails = ({ orgId }: MemberDetailsProps) => {
  return (
    <div className="space-y-8">
      {/* Header */}
      <div>
        <h2 className="text-lg font-medium mb-1">Members & Invites</h2>
        <p className="text-sm text-muted-foreground">{`Manage members & invites`}</p>
      </div>

      {/* Members Section */}
      <div className="space-y-8">
        {/* Members Management Card */}
        <div className="space-y-4">
          <div className="flex items-center justify-between">
            <div>
              <h3 className="text-md font-medium mb-1">Members</h3>
              <p className="text-sm text-muted-foreground">
                View and manage organisation members
              </p>
            </div>
          </div>

          {/* Members Table */}
          <div className="border rounded-lg">
            <UserTable orgId={orgId} />
          </div>
        </div>

        {/* Invitations Section */}
        <PermissionGateProvider permissions={['org:update']}>
          <div className="space-y-4">
            <div className="flex items-center justify-between">
              <div>
                <h3 className="text-md font-medium mb-1">Invitations</h3>
                <p className="text-sm text-muted-foreground">
                  Manage pending invitations
                </p>
              </div>
            </div>

            {/* Invitations Table */}
            <div className="border rounded-lg">
              <InvitationsTable orgId={orgId} />
            </div>
          </div>
        </PermissionGateProvider>
      </div>
    </div>
  );
};

export default MemberDetails;
