import { Link } from '@tanstack/react-router';
import logo from '@fluency/ui/assets/icon-blue.svg';

export default function Header() {
  return (
    <header className="sticky top-0 z-50 flex h-16 items-center gap-4 px-4 md:px-6 bg-white">
      <nav className="flex items-center gap-6 justify-between font-medium text-sm lg:gap-6 w-full">
        <Link to="/" className="flex items-center gap-2 font-semibold">
          <img className="w-10" src={logo} alt="Company name" />
        </Link>{' '}
        <div className="text-right">
          <p>Made with Fluency</p>
          <a
            href="https://getfluency.com.au"
            target="_blank"
            rel="noopener noreferrer"
            className="text-gray-400 font-normal hover:text-gray-600 transition-colors"
          >
            usefluency.com
          </a>
        </div>
      </nav>
    </header>
  );
}
