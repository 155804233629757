import { Avatar, AvatarFallback } from '@fluency/ui/components/ui/avatar';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@fluency/ui/components/ui/tooltip';
import { sizeStyles } from '@fluency/ui/components/common/MultipleAvatars';
import { cn } from '@fluency/ui/lib/utils';

interface OwnerAvatarProps {
  name: string;
  size?: 'xs' | 'sm';
  loading?: boolean;
}

const OwnerAvatar = (props: OwnerAvatarProps) => {
  const { name, size = 'xs' } = props;

  const initials = name
    .split(' ')
    .slice(0, 2)
    .map((n) => n.charAt(0))
    .join('');
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger>
          <Avatar className={cn(sizeStyles[size].avatar)}>
            <AvatarFallback
              className={cn(
                ' bg-fluency-50 text-fluency-600',
                sizeStyles[size].text
              )}
            >
              {initials}
            </AvatarFallback>
          </Avatar>
        </TooltipTrigger>
        <TooltipContent side="bottom" className="text-xs">
          <p>Created by {name}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

export default OwnerAvatar;
