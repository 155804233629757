import { LoaderCircle } from 'lucide-react';
import { useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@fluency/ui/components/ui/dialog';
import { Button } from '@fluency/ui/components/ui/button';
import { convertToPng } from '../../editor/utils/convertToPng';
import { Logger } from '@fluency/ui/features/Logger';
import { Alert, AlertDescription } from '@fluency/ui/components/ui/alert';
import useReplaceScreenshot from '../../../hooks/useReplaceScreenshot';
import { Step } from '@fluency/ui/hooks/documentation/types/master';

interface ReplaceImageDialogProps {
  isOpen: boolean;
  onClose: () => void;
  file: File | null;
  step: Step | null;
  documentId: string;
  mode: 'add' | 'replace';
}

export const ReplaceImageDialog: React.FC<ReplaceImageDialogProps> = ({
  isOpen,
  onClose,
  file,
  step,
  documentId,
  mode,
}) => {
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [error, setError] = useState<string>('');
  const { stepId, stepNumber } = step ?? {
    stepId: '',
    stepNumber: '',
  };
  const { mutate: replaceScreenshot, isPending } =
    useReplaceScreenshot(documentId);

  const isAddMode = mode === 'add';
  const title = isAddMode ? 'Add Screenshot' : 'Replace Screenshot';
  const description = isAddMode
    ? `Would you like to add this image to step ${stepNumber}?`
    : `Are you sure you want to replace the current image on step ${stepNumber}? This action cannot be undone.`;

  useEffect(() => {
    if (file && isOpen) {
      const url = URL.createObjectURL(file);
      setPreviewUrl(url);
      return () => {
        URL.revokeObjectURL(url);
      };
    }
    return undefined;
  }, [file, isOpen]);

  const handleClose = () => {
    setError('');
    setPreviewUrl(null);
    onClose();
  };

  const handleConfirm = async () => {
    if (!file) return;

    try {
      const pngFile = await convertToPng(file);
      replaceScreenshot(
        {
          file: pngFile,
          stepId,
          fileType: 'png',
        },
        {
          onSuccess: () => {
            handleClose();
          },
          onError: (error) => {
            setError('Failed to upload image. Please try again.');
            Logger.error('Error replacing screenshot:', error);
          },
        }
      );
    } catch (error) {
      setError('Failed to process image. Please try again.');
      Logger.error('Error converting image:', error);
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={handleClose}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
          <DialogDescription>{description}</DialogDescription>
        </DialogHeader>

        <div className="grid gap-4">
          {error && (
            <Alert variant="destructive">
              <AlertDescription>{error}</AlertDescription>
            </Alert>
          )}

          {previewUrl && (
            <div className="mt-4 relative rounded-lg overflow-hidden">
              <img
                src={previewUrl}
                alt="Preview"
                className="max-h-48 w-full object-contain bg-slate-100"
              />
            </div>
          )}

          <DialogFooter>
            <Button
              variant="outline"
              onClick={handleClose}
              size="sm"
              disabled={isPending}
            >
              Cancel
            </Button>
            <Button
              onClick={handleConfirm}
              disabled={isPending}
              className="flex-1"
              size="sm"
            >
              {isPending ? (
                <>
                  <LoaderCircle className="animate-spin w-4 h-4 mr-2" />
                  Uploading...
                </>
              ) : isAddMode ? (
                'Add Screenshot'
              ) : (
                'Replace Screenshot'
              )}
            </Button>
          </DialogFooter>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ReplaceImageDialog;
