import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '@fluency/ui/components/ui/card';
import { AlertCircle, ChevronDown, ChevronUp } from 'lucide-react';
import moment from 'moment';
import { useState } from 'react';

// Type for any nested object
type NestedObject = {
  [key: string]: any;
};

// Type for item with potential error and preview keys
type ArrayItem = {
  data?: NestedObject;
  error?: string;
  isLoading?: boolean;
  previewKeys?: string[]; // Add preview keys for each item
};

type FlexibleArrayDisplayProps = {
  items: ArrayItem[];
  direction?: 'horizontal' | 'vertical';
  itemTitles?: string[];
  containerClassName?: string;
  itemClassName?: string;
  defaultPreviewKeys?: string[]; // Default preview keys if not specified per item
};

const LoadingContent = () => (
  <div className="space-y-3">
    <div className="h-4 bg-gray-200 rounded animate-pulse w-3/4"></div>
    <div className="h-4 bg-gray-200 rounded animate-pulse w-1/2"></div>
    <div className="h-4 bg-gray-200 rounded animate-pulse w-2/3"></div>
    <div className="h-4 bg-gray-200 rounded animate-pulse w-1/3"></div>
  </div>
);

const getPreviewContent = (
  data: NestedObject,
  previewKeys?: string[]
): string => {
  if (!data) return '';

  // If we have previewKeys, use them to get preview content
  if (previewKeys && previewKeys.length > 0) {
    const availablePreviewKeys = previewKeys.filter(
      (key) => data[key] !== undefined
    );
    if (availablePreviewKeys.length > 0) {
      return availablePreviewKeys
        .map((key) => `${key}: ${data[key]}`)
        .join('\n');
    }
  }

  // Fallback to first available key if no preview keys match
  const keys = Object.keys(data);
  if (keys.length === 0) return '';

  const firstKey = keys[0];
  if (!firstKey) return '';

  const value = data[firstKey];
  if (value === undefined) return '';

  return `${firstKey}: ${String(value).substring(0, 30)}`;
};

const FlexibleArrayDisplay = ({
  items,
  direction = 'horizontal',
  itemTitles = [],
  containerClassName = '',
  itemClassName = '',
  defaultPreviewKeys = ['name', 'id', 'email', 'status'], // Default preview keys
}: FlexibleArrayDisplayProps) => {
  const [expandedItems, setExpandedItems] = useState<boolean[]>(
    items.map(() => false)
  );

  const toggleItem = (index: number) => {
    setExpandedItems((prev) => {
      const newState = [...prev];
      newState[index] = !newState[index];
      return newState;
    });
  };

  const renderValue = (key: string, value: any): React.ReactNode => {
    if (Array.isArray(value)) {
      return (
        <div className="pl-4">
          {value.map((item, index) => (
            <div key={index} className="my-1">
              {renderValue(key, item)}
            </div>
          ))}
        </div>
      );
    } else if (typeof value === 'object' && value !== null) {
      return (
        <div className="pl-4">
          {Object.entries(value).map(([key, val]) => (
            <div key={key} className="my-1">
              <span className="font-medium">{key}: </span>
              {renderValue(key, val)}
            </div>
          ))}
        </div>
      );
    }
    const renderFinalValue = (value: any): React.ReactNode => {
      if (key === 'createdAt' || key === 'updatedAt') {
        return <span>{moment(value).format('LL')}</span>;
      }
      return <span>{String(value)}</span>;
    };
    return renderFinalValue(value);
  };

  const containerStyles = `
    ${direction === 'horizontal' ? 'flex flex-row' : 'flex flex-col'}
    gap-4 w-full
    ${containerClassName}
  `.trim();

  const itemStyles = `
    ${direction === 'horizontal' ? 'flex-1' : 'w-full'}
    transition-all duration-200
    ${itemClassName}
  `.trim();

  return (
    <div className={containerStyles}>
      {items.map((item, index) => (
        <Card
          key={index}
          className={`${itemStyles} ${item.error ? 'bg-red-50' : ''}`}
        >
          <CardHeader
            className="cursor-pointer select-none"
            onClick={() => toggleItem(index)}
          >
            <div className="flex items-center justify-between">
              <CardTitle>{itemTitles[index] || `Item ${index + 1}`}</CardTitle>
              <button
                className="p-1 hover:bg-gray-100 rounded-full transition-colors"
                onClick={(e) => {
                  e.stopPropagation();
                  toggleItem(index);
                }}
              >
                {expandedItems[index] ? (
                  <ChevronUp className="h-5 w-5" />
                ) : (
                  <ChevronDown className="h-5 w-5" />
                )}
              </button>
            </div>
            {!expandedItems[index] &&
              item.data &&
              !item.error &&
              !item.isLoading && (
                <div className="text-sm text-gray-500 mt-1 whitespace-pre-line">
                  {getPreviewContent(
                    item.data,
                    item.previewKeys || defaultPreviewKeys
                  )}
                </div>
              )}
          </CardHeader>
          <div
            className={`transition-all duration-200 overflow-hidden ${
              expandedItems[index] ? 'max-h-[1000px]' : 'max-h-0'
            }`}
          >
            <CardContent>
              {item.isLoading ? (
                <LoadingContent />
              ) : item.error ? (
                <div className="flex items-center gap-2 text-red-600">
                  <AlertCircle className="h-5 w-5" />
                  <span className="font-medium">{item.error}</span>
                </div>
              ) : item.data ? (
                Object.entries(item.data).map(([key, value]) => (
                  <div key={key} className="mb-2">
                    <span className="font-medium">{key}: </span>
                    {renderValue(key, value)}
                  </div>
                ))
              ) : null}
            </CardContent>
          </div>
        </Card>
      ))}
    </div>
  );
};

export default FlexibleArrayDisplay;
