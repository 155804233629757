import { useState } from 'react';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '@fluency/ui/components/ui/alert-dialog';
import { Button } from '@fluency/ui/components/ui/button';
import { Input } from '@fluency/ui/components/ui/input';

interface CancelSubscriptionButtonProps {
  onCancel: () => void;
  loading?: boolean;
}

const CancelSubscriptionButton = ({
  onCancel,
  loading = false,
}: CancelSubscriptionButtonProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [confirmText, setConfirmText] = useState('');

  const handleConfirmCancel = () => {
    if (confirmText === 'DELETE') {
      onCancel();
      setIsOpen(false);
      setConfirmText('');
    }
  };

  return (
    <>
      <Button
        variant="destructive"
        onClick={() => setIsOpen(true)}
        className="w-full sm:w-auto"
        disabled={loading}
      >
        {loading ? 'Cancelling...' : 'Cancel Subscription'}
      </Button>

      <AlertDialog open={isOpen} onOpenChange={setIsOpen}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you absolutely sure?</AlertDialogTitle>
            <AlertDialogDescription className="space-y-4">
              <p>
                This action cannot be undone. This will cancel your subscription
                at the end of the current billing period.
              </p>
              <div className="space-y-2">
                <p>
                  Please type <span className="font-medium">DELETE</span> to
                  confirm.
                </p>
                <Input
                  type="text"
                  value={confirmText}
                  onChange={(e) => setConfirmText(e.target.value)}
                  placeholder="Type DELETE to confirm"
                  className="max-w-sm"
                />
              </div>
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel onClick={() => setConfirmText('')}>
              Cancel
            </AlertDialogCancel>
            <AlertDialogAction
              onClick={handleConfirmCancel}
              disabled={confirmText !== 'DELETE'}
              className="bg-destructive text-destructive-foreground hover:bg-destructive/90"
            >
              Cancel Subscription
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default CancelSubscriptionButton;
