import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@fluency/ui/components/ui/dialog';
import { Button } from '@fluency/ui/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@fluency/ui/components/ui/form';
import { Textarea } from '@fluency/ui/components/ui/textarea';
import { Calendar } from '@fluency/ui/components/ui/calendar';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@fluency/ui/components/ui/popover';
import { cn } from '@fluency/ui/lib/utils';
import moment from 'moment';
import { Calendar as CalendarIcon, Loader2, Search, Users } from 'lucide-react';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from '@fluency/ui/components/ui/command';
import { Badge } from '@fluency/ui/components/ui/badge';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { capitalise } from '@fluency/ui/lib/capitalise';
import useGetOrgUsers from '@fluency/ui/hooks/organisation/useGetOrgUsers';
import { useResourceRequests } from '../hooks/useResourceRequests';

interface CreateRequestDialogProps {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
  documentId: string;
}

const formSchema = z.object({
  recipientIds: z.array(z.string()),
  message: z.string().optional(),
  dueDate: z
    .date({
      required_error: 'Due date is required',
    })
    .min(new Date(), 'Due date must be in the future'),
});

type FormValues = z.infer<typeof formSchema>;

export const CreateRequestDialog: React.FC<CreateRequestDialogProps> = ({
  isOpen,
  onOpenChange,
  documentId,
}) => {
  const [commandOpen, setCommandOpen] = React.useState(false);
  const [selectedUsers, setSelectedUsers] = React.useState<
    {
      id: string;
      name: string;
      email: string;
      avatar?: string;
    }[]
  >([]);

  const { data: orgUsersData } = useGetOrgUsers();

  const {
    createRequest,
    isCreating,
    data: existingRequests,
  } = useResourceRequests(documentId);

  // Get list of users who already have active requests
  const activeRequestedUserIds = React.useMemo(() => {
    if (!existingRequests) return new Set<string>();
    return new Set(
      existingRequests
        .flatMap((req) => req.assignments)
        .filter((assignment) => assignment.status !== 'COMPLETED')
        .map((assignment) => assignment.recipientId)
    );
  }, [existingRequests]);

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      recipientIds: [],
      message: '',
      dueDate: new Date(),
    },
  });

  const onSubmit = async (values: FormValues) => {
    if (selectedUsers.length === 0) {
      form.setError('recipientIds', {
        type: 'manual',
        message: 'Select at least one recipient',
      });
      return;
    }

    try {
      // Create different requests for each selected user
      for (const user of selectedUsers) {
        await createRequest({
          recipientIds: [user.id],
          message: values.message,
          dueDate: values.dueDate,
        });
      }
      // Reset form and close dialog
      setSelectedUsers([]);
      form.reset({
        recipientIds: [],
        message: '',
        dueDate: new Date(),
      });
      onOpenChange(false);
    } catch (error) {
      // Error is handled by the mutation
    }
  };

  // Reset form when dialog is closed
  React.useEffect(() => {
    if (!isOpen) {
      setSelectedUsers([]);
      form.reset({
        recipientIds: [],
        message: '',
        dueDate: new Date(),
      });
    }
  }, [isOpen, form]);

  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[500px]">
        <DialogHeader>
          <DialogTitle>Request Document Review</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
            <FormField
              control={form.control}
              name="recipientIds"
              render={() => (
                <FormItem>
                  <FormLabel>Recipients</FormLabel>
                  <Popover open={commandOpen} onOpenChange={setCommandOpen}>
                    <PopoverTrigger asChild>
                      <FormControl>
                        <Button
                          variant="outline"
                          role="combobox"
                          aria-expanded={commandOpen}
                          className={cn(
                            'w-full justify-between',
                            !selectedUsers.length && 'text-muted-foreground'
                          )}
                        >
                          <div className="flex gap-2 items-center">
                            <Users className="h-4 w-4" />
                            {selectedUsers.length > 0
                              ? `${selectedUsers.length} selected`
                              : 'Select recipients...'}
                          </div>
                          <Search className="h-4 w-4 shrink-0 opacity-50" />
                        </Button>
                      </FormControl>
                    </PopoverTrigger>
                    <PopoverContent className="w-[400px] p-0" align="start">
                      <Command>
                        <CommandInput placeholder="Search users..." />
                        <CommandEmpty>No users found.</CommandEmpty>
                        <CommandGroup className="max-h-[200px] overflow-auto">
                          {orgUsersData?.memberships.map((member) => {
                            const isRequested = activeRequestedUserIds.has(
                              member.userId
                            );
                            return (
                              <CommandItem
                                key={member.userId}
                                disabled={isRequested}
                                onSelect={() => {
                                  const user = {
                                    id: member.userId,
                                    name: `${member.user.firstName} ${member.user.lastName}`,
                                    email: member.user.email,
                                    avatar:
                                      member.user.profilePictureUrl ??
                                      undefined,
                                  };
                                  setSelectedUsers((prev) =>
                                    prev.some((u) => u.id === user.id)
                                      ? prev.filter((u) => u.id !== user.id)
                                      : [...prev, user]
                                  );
                                }}
                              >
                                <div className="flex items-center gap-2 w-full">
                                  <input
                                    type="checkbox"
                                    checked={selectedUsers.some(
                                      (u) => u.id === member.userId
                                    )}
                                    className="h-4 w-4 rounded border-gray-300"
                                    onChange={() => {}}
                                    disabled={isRequested}
                                  />
                                  <span
                                    className={
                                      isRequested ? 'text-muted-foreground' : ''
                                    }
                                  >
                                    {member.user.firstName &&
                                    member.user.lastName
                                      ? `${capitalise(
                                          member.user.firstName
                                        )} ${capitalise(member.user.lastName)}`
                                      : member.user.email}
                                    {isRequested && (
                                      <span className="ml-2 text-xs text-muted-foreground">
                                        (Already requested)
                                      </span>
                                    )}
                                  </span>
                                </div>
                              </CommandItem>
                            );
                          })}
                        </CommandGroup>
                      </Command>
                    </PopoverContent>
                  </Popover>
                  {selectedUsers.length > 0 && (
                    <div className="flex flex-wrap gap-1 mt-1.5">
                      {selectedUsers.map((user) => (
                        <Badge
                          key={user.id}
                          variant="secondary"
                          className="flex items-center gap-1"
                        >
                          {user.name}
                          <Button
                            type="button"
                            variant="ghost"
                            size="sm"
                            className="h-4 w-4 p-0 hover:bg-transparent"
                            onClick={() => {
                              setSelectedUsers((prev) =>
                                prev.filter((u) => u.id !== user.id)
                              );
                            }}
                          >
                            ×
                          </Button>
                        </Badge>
                      ))}
                    </div>
                  )}
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="dueDate"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Due Date</FormLabel>
                  <Popover>
                    <PopoverTrigger asChild>
                      <FormControl>
                        <Button
                          variant="outline"
                          className={cn(
                            'w-full pl-3 text-left font-normal',
                            !field.value && 'text-muted-foreground'
                          )}
                        >
                          <CalendarIcon className="mr-2 h-4 w-4" />
                          {field.value ? (
                            moment(field.value).format('MMMM D, YYYY')
                          ) : (
                            <span>Pick a due date</span>
                          )}
                        </Button>
                      </FormControl>
                    </PopoverTrigger>
                    <PopoverContent className="w-auto p-0" align="start">
                      <Calendar
                        mode="single"
                        selected={field.value}
                        onSelect={field.onChange}
                        disabled={(date) =>
                          date < new Date() || date < new Date('1900-01-01')
                        }
                        initialFocus
                      />
                    </PopoverContent>
                  </Popover>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="message"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Message (Optional)</FormLabel>
                  <FormControl>
                    <Textarea
                      placeholder="Add instructions or context for the reviewers..."
                      className="resize-none h-20"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <div className="flex justify-end gap-2">
              <Button
                type="button"
                variant="outline"
                onClick={() => onOpenChange(false)}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                disabled={isCreating || selectedUsers.length === 0}
              >
                {isCreating ? (
                  <>
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    Creating...
                  </>
                ) : (
                  'Create Request'
                )}
              </Button>
            </div>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default CreateRequestDialog;
