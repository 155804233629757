import { Button } from '@fluency/ui/components/ui/button';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@fluency/ui/components/ui/tooltip';
import { SwimlaneNodeData } from '@fluency/ui/hooks/workflows/types/types';
import { PlusIcon, X } from 'lucide-react';
import { useEffect, useRef, useState } from 'react';
import { NodeProps } from 'reactflow';
import Resizer from './Resizer';
import { cn } from '@fluency/ui/lib/utils';
import { useShallow, useWorkflowStore } from '../../store';

const SwimlaneNode = ({
  data: { header = 'Swimlane', pools = [], onDelete, isViewMode },
  id,
}: NodeProps<SwimlaneNodeData>) => {
  const [isHovered, setIsHovered] = useState(false);
  const { getNode, updateNode } = useWorkflowStore(
    useShallow((state) => ({
      getNode: state.getNode,
      setNodes: state.setNodes,
      updateNode: state.updateNode,
    }))
  );

  const node = getNode(id);

  // Swimlane and Pools data updater
  const updateHeader = (newHeader: string) => {
    updateNode(id, (prevNode) => ({
      ...prevNode,
      data: { ...prevNode.data, header: newHeader },
    }));
  };

  const updatePools = (newPools: string[]) => {
    updateNode(id, (prevNode) => ({
      ...prevNode,
      data: { ...prevNode.data, pools: newPools },
    }));
  };

  // Default dimensions if node is undefined
  const width = node?.width ?? 1500;
  const height = node?.height ?? 500;

  const handleDelete = (event: React.MouseEvent) => {
    event.stopPropagation();
    onDelete?.();
  };

  // Adjust the swimlane text area height
  const swimlaneRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (swimlaneRef.current) {
      swimlaneRef.current.style.width = 'auto';
      swimlaneRef.current.style.width = `${swimlaneRef.current.scrollWidth}px`;
    }
  }, [header]);

  // Adjust the pools text area height
  const poolsRef = useRef<(HTMLTextAreaElement | null)[]>([]);
  const [hoveredPool, setHoveredPool] = useState<number | null>(null);

  useEffect(() => {
    poolsRef.current = poolsRef.current.slice(0, pools.length);
  }, [pools.length]);

  // Update the pools text area width (because the text is vertical)
  // Based on the max width of all Pool
  const updateWidths = () => {
    // Reset heights first to get actual scroll heights
    poolsRef.current.forEach((ref) => {
      if (ref) {
        ref.style.width = 'auto';
      }
    });

    // Find the maximum scroll height
    const maxWidth = Math.max(
      ...poolsRef.current.map((ref) => (ref ? ref.scrollWidth : 0))
    );

    // Apply the maximum height to all textareas
    poolsRef.current.forEach((ref) => {
      if (ref) {
        ref.style.width = `${maxWidth}px`;
      }
    });
  };

  // Listen to any change in text and update the pools text area height
  useEffect(() => {
    updateWidths();
  }, [pools]);

  return (
    <div
      onMouseEnter={() => !isViewMode && setIsHovered(true)}
      onMouseLeave={() => !isViewMode && setIsHovered(false)}
      style={{
        width: width > 0 ? `${width}px` : '200px',
        height: height > 0 ? `${height}px` : '100px',
      }}
      className="relative border-2 border-black flex items-center justify-start -z-10"
    >
      {/* Delete Icon */}
      {isHovered && !isViewMode && !node?.resizing && (
        <button
          onClick={handleDelete}
          className="absolute -top-2 -right-2 bg-red-500 text-white rounded-full p-1 hover:bg-red-600 transition-colors z-10"
        >
          <X className="w-4 h-4" />
        </button>
      )}

      {/* Node Resizer */}
      {isHovered && !isViewMode && <Resizer />}

      {/* Swimlane Header */}
      <textarea
        readOnly={isViewMode}
        ref={swimlaneRef}
        value={header}
        onChange={(e) => updateHeader(e.target.value)}
        className={cn(
          'vertical-rl text-orient-mixed rotate-180 border-l-2 border-black text-sm text-center p-2 h-full resize-none',
          isViewMode && 'focus:outline-none'
        )}
      ></textarea>

      {/* Add Pool */}
      {isHovered && !isViewMode && (
        <TooltipProvider>
          <Tooltip delayDuration={100}>
            <TooltipTrigger asChild>
              <Button
                onClick={() => {
                  updatePools([`Pool ${pools.length + 1}`, ...pools]);
                }}
                size={'icon'}
                className="rounded-full absolute bottom-2 right-2 bg-primary z-10"
              >
                <PlusIcon className="w-4 h-4" />
              </Button>
            </TooltipTrigger>
            <TooltipContent side="top">Add Pool</TooltipContent>
          </Tooltip>
        </TooltipProvider>
      )}

      {pools.length > 0 && (
        <div className="flex flex-col h-full text-center w-full">
          {pools.map((pool, index) => (
            <div
              key={index}
              onMouseEnter={() => setHoveredPool(index)}
              onMouseLeave={() => setHoveredPool(null)}
              className="flex-1 flex flex-row relative"
            >
              <div className="w-fit h-full flex flex-col border-r-2 border-black">
                {!isViewMode && hoveredPool === index && (
                  <button
                    onClick={() => {
                      updatePools(pools.filter((_, i) => i !== index));
                      setHoveredPool(null);
                    }}
                    className="absolute -top-2 -left-2 bg-red-500 text-white rounded-full p-1 hover:bg-red-600 transition-colors z-10"
                  >
                    <X className="w-4 h-4" />
                  </button>
                )}
                <textarea
                  readOnly={isViewMode}
                  ref={(el) => (poolsRef.current[index] = el)}
                  className={cn(
                    'flex-1 vertical-rl text-orient-mixed rotate-180 text-center resize-none p-2 text-sm',
                    {
                      'border-t-2 border-black': index !== pools.length - 1,
                    },
                    isViewMode && 'focus:outline-none'
                  )}
                  value={pool}
                  onChange={(e) =>
                    updatePools(
                      pools.map((p, i) => (i === index ? e.target.value : p))
                    )
                  }
                ></textarea>
              </div>
              <div
                className={cn('flex-1  h-full', {
                  'border-b-2 border-black': index !== pools.length - 1,
                })}
              ></div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SwimlaneNode;
