import {
  createFileRoute,
  useParams,
  useNavigate,
} from '@tanstack/react-router';
import { TableDocumentsPage } from '@fluency/ui/features';
import { RolePermissionHandler } from '@fluency/ui/features/AuthGuard/RolePermissionHandler';
import ManageVaults from '@fluency/ui/features/Vaults/ManageVaults';
import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import NoSubscriptionMessage from '@fluency/ui/components/NoSubscriptionMessage';

const VaultPage = () => {
  const { id } = useParams({ from: '/vault/$id' });
  const { subscription, orgId, user } = useAuth();
  const navigate = useNavigate();

  if (!user) {
    navigate({
      to: '/logout',
      replace: true,
    });
    return null;
  }

  if (orgId === null) {
    navigate({
      to: '/orgform',
      replace: true,
    });
    return null;
  }

  if (
    !subscription ||
    subscription === 'absent' ||
    !(subscription === 'active' || subscription === 'trialing')
  ) {
    return <NoSubscriptionMessage />;
  }

  if (id === 'manage') {
    return (
      <RolePermissionHandler requiredPermissions={[]}>
        <ManageVaults />
      </RolePermissionHandler>
    );
  }

  return (
    <RolePermissionHandler requiredPermissions={['documents:read']}>
      <TableDocumentsPage vaultId={id} />
    </RolePermissionHandler>
  );
};

export const Route = createFileRoute('/vault/$id')({
  component: VaultPage,
});
