import React, { Fragment, memo } from 'react';
import { stepTypeColors, getStepTypeIcon } from '../util/iconHelper';
import {
  EnhancedStep,
  ScreenshotType,
  Step,
} from '@fluency/ui/hooks/documentation/types/master';
import EditStepActions from './EditStepActions';
import EditStepDescriptionInput from './EditStepDescriptionInput';
import ScreenshotItem from './screenshot/ScreenshotItem';

interface StepContentProps {
  step: EnhancedStep;
  isLatestVersion: boolean;
  docId: string;
  editingId: string | null;
  editStepDescription: string;
  handleSaveStepDescription: (editingId: string | null) => void;
  handleEditStepDescription: ({
    stepId,
    description,
  }: {
    stepId: string | null;
    description: string;
  }) => void;
  handleUpdateStepDescription: ({
    stepId,
    description,
  }: {
    stepId: string;
    description: string;
  }) => void;
  handleDeleteStep: (stepId: string) => void;
  filteredSteps: Step[];
  isEditMode: boolean;
  isHighlighted?: boolean;
  onMouseEnter?: (e: React.MouseEvent<HTMLDivElement>) => void;
  onMouseLeave?: (e: React.MouseEvent<HTMLDivElement>) => void;
}

export const StepContent = memo(
  React.forwardRef<HTMLTableRowElement, StepContentProps>(
    (
      {
        step,
        isLatestVersion,
        docId,
        editingId,
        editStepDescription,
        handleSaveStepDescription,
        handleEditStepDescription,
        handleUpdateStepDescription,
        handleDeleteStep,
        filteredSteps,
        isEditMode,
        isHighlighted,
        onMouseEnter,
        onMouseLeave,
      },
      ref
    ) => {
      // Common props to add step-id to all relevant elements
      const commonStepIdProps = {
        'step-id': step.stepId,
        'data-step-id': step.stepId,
      };

      return (
        <Fragment>
          {/* Main content row */}
          <tr
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            ref={ref}
            className={`print:break-inside-avoid ${
              isHighlighted ? 'bg-primary/5' : ''
            } transition-colors duration-150`}
            {...commonStepIdProps}
          >
            <td className="max-w-0 px-0 py-3 align-top" {...commonStepIdProps}>
              <div
                className={`${
                  stepTypeColors[
                    step.stepType as keyof typeof stepTypeColors
                  ] || ''
                } ${
                  !step.isStepTypeStep && 'text-slate-950 p-3'
                } rounded-xl flex flex-col mt-6`}
                {...commonStepIdProps}
              >
                <div className="w-full" {...commonStepIdProps}>
                  {/* Header row with step number and actions */}
                  <div
                    className="flex items-center justify-between h-5 mb-2"
                    {...commonStepIdProps}
                  >
                    {/* Left side with step type and label */}
                    <span
                      className="font-semibold flex items-center gap-2"
                      {...commonStepIdProps}
                    >
                      {getStepTypeIcon(step.stepType)}
                      {step.isStepTypeStep && step.stepLabel}
                      <span className="dark:text-black" {...commonStepIdProps}>
                        {!step.isStepTypeStep &&
                          step.stepType.charAt(0).toUpperCase() +
                            step.stepType.slice(1).toLowerCase()}
                      </span>
                    </span>

                    {/* Right side with edit actions */}
                    {isLatestVersion && isEditMode && (
                      <div className="flex items-center" {...commonStepIdProps}>
                        <EditStepActions
                          editingId={editingId}
                          handleSaveStepDescription={handleSaveStepDescription}
                          handleEditStepDescription={handleEditStepDescription}
                          handleDeleteStep={handleDeleteStep}
                          isStepTypeStep={step.isStepTypeStep}
                          isEditButtonPressed={editingId === step.stepId}
                          stepId={step.stepId}
                          stepDescription={step.stepDescription}
                          screenshotName={step.screenshotName ?? ''}
                          stepNumber={step.stepNumber}
                          screenshotId={step.screenshotId}
                          documentId={docId}
                          hasScreenshot={Boolean(
                            step.screenshotUrl && step.screenshotName
                          )}
                        />
                      </div>
                    )}
                  </div>

                  {/* Description section */}
                  <div className="w-full" {...commonStepIdProps}>
                    <EditStepDescriptionInput
                      isStepTypeStep={false}
                      handleEditStepDescription={handleEditStepDescription}
                      handleUpdateStepDescription={handleUpdateStepDescription}
                      editStepDescription={editStepDescription}
                      editingId={editingId}
                      filteredSteps={filteredSteps}
                      stepId={step.stepId}
                      stepDescription={step.stepDescription}
                      canEdit={isLatestVersion}
                      isEditMode={isEditMode}
                    />
                  </div>
                </div>
              </div>
            </td>
          </tr>

          {/* Screenshot row */}
          {step.screenshotUrl && step.screenshotName && (
            <tr
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
              className={`border-b border-gray-100 transition-colors duration-150 ${
                isHighlighted ? 'bg-primary/5' : ''
              }`}
              {...commonStepIdProps}
            >
              <td className="" {...commonStepIdProps}>
                <div {...commonStepIdProps}>
                  <ScreenshotItem
                    docId={docId}
                    stepId={step.stepId}
                    isScreenshotLoading={false}
                    isEditMode={isEditMode}
                    screenshot={step.screenshots[0] ?? ({} as ScreenshotType)}
                  />
                </div>
              </td>
            </tr>
          )}
        </Fragment>
      );
    }
  )
);

StepContent.displayName = 'StepContent';

export default StepContent;
