import { z } from 'zod';

export const titleSchema = z.object({
  value: z
    .string()
    .min(3, 'Title must be at least 3 characters.')
    .max(60, 'Title must be less than 60 characters.')
    .regex(/^[\p{L}\p{N}\p{P}\p{Zs}]*$/u, 'Title contains invalid characters.'),
});

export const contentSchema = z.object({
  value: z
    .string()
    .max(500, 'Description must be less than 500 characters.')
    .min(3, 'Description must be at least 3 characters.')
    .regex(
      /^[\p{L}\p{N}\p{P}\p{Zs}]*$/u,
      'Description contains invalid characters.'
    ),
});
