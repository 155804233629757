import useSubscription from '@fluency/ui/hooks/subscription/useSubscription';
import { useOrganisationOperations } from '../Organisation/hooks/useOrganisationOperations';
import SubscriptionDetails from './SubscriptionDetails';
import CreateSubscriptionForm from './CreateSubscriptionForm';
import ExtendTrialButton from './ExtendTrialDialog';

interface SubscriptionDetailsProps {
  orgId: string;
}

const SubscriptionHome = ({ orgId }: SubscriptionDetailsProps) => {
  const { subscriptionStatus } = useSubscription({
    organisationId: orgId,
  });
  const { useGetOrganisation } = useOrganisationOperations();
  const { data: organisation } = useGetOrganisation({ orgId });

  const includeOwnerEmail = !organisation?.stripe?.email;
  const hasHadFreeTrial = subscriptionStatus?.hasActivatedTrial;

  return (
    <div className="w-full max-w-4xl mx-auto space-y-6">
      {/*Header */}
      <div className="flex flex-row justify-between">
        <div>
          <h2 className="text-lg font-medium mb-1">Subscription</h2>
          <p className="text-sm text-muted-foreground">Manage subscription</p>
        </div>
        <div>
          {subscriptionStatus?.status !== 'active' &&
            subscriptionStatus?.status !== 'trialing' && (
              <CreateSubscriptionForm
                orgId={orgId}
                includeOwnerEmail={includeOwnerEmail}
                hasHadFreeTrial={hasHadFreeTrial}
              />
            )}
          {subscriptionStatus?.status === 'trialing' && (
            <ExtendTrialButton
              orgId={orgId}
              currentTrialEndDate={
                new Date(subscriptionStatus.billing.nextBillingDate)
              }
            />
          )}
        </div>
      </div>

      {/* <SubscriptionPlan orgId={orgId} /> */}
      <SubscriptionDetails orgId={orgId} />
    </div>
  );
};

export default SubscriptionHome;
