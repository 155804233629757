import { useEffect, useState } from 'react';
import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';

export const useAdmin = () => {
  const [adminAccess, setAdminAccess] = useState(false);
  const { isLoading, isAuthenticated, isInitialised, orgId } = useAuth();
  const [isCheckingAccess, setIsCheckingAccess] = useState(true);

  useEffect(() => {
    setIsCheckingAccess(true);
    if (!isLoading && isInitialised && isAuthenticated && orgId) {
      setAdminAccess(orgId === import.meta.env.VITE_ADMIN_ORG_ID);
      setIsCheckingAccess(false);
    }
  }, [isLoading, isInitialised, isAuthenticated, orgId]);

  return {
    adminAccess,
    isLoading: isCheckingAccess,
  };
};
