import { useState } from 'react';
import { Button } from '@fluency/ui/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@fluency/ui/components/ui/dialog';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@fluency/ui/components/ui/form';
import { Input } from '@fluency/ui/components/ui/input';
import { useForm } from 'react-hook-form';
import { useSubscriptionOperations } from './hooks/useSubscriptionOperations';
import { toast } from '@fluency/ui/components/ui/use-toast';
import { Logger } from '../../Logger';

interface ChangeBillingTypeButtonProps {
  collectionMethod: 'send_invoice' | 'charge_automatically';
  orgId: string;
  customerId: string;
}

interface FormValues {
  daysUntilDue: number;
}

export function ChangeBillingTypeButton({
  collectionMethod,
  orgId,
  customerId,
}: ChangeBillingTypeButtonProps) {
  const { updateSubscriptionBillingType, isLoadingUpdateBillingType } =
    useSubscriptionOperations({ orgId });
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  // Hanlder
  const changeBillingType = async (daysUntilDue?: number) => {
    try {
      const newMethod =
        collectionMethod === 'send_invoice'
          ? 'charge_automatically'
          : 'send_invoice';

      await updateSubscriptionBillingType({
        customerId: customerId,
        method: newMethod,
        ...(newMethod === 'send_invoice' && { daysUntilDue }),
      });
      toast({
        title: 'Success',
        description: 'Billing type updated successfully',
      });
    } catch (error) {
      Logger.error('Failed to update billing type', error);
      toast({
        title: 'Error',
        description: 'Failed to update billing type',
        variant: 'destructive',
      });
    }
  };

  const form = useForm<FormValues>({
    defaultValues: {
      daysUntilDue: 0,
    },
  });

  const handleSubmit = async (values: FormValues) => {
    await changeBillingType(values.daysUntilDue);
    setIsDialogOpen(false);
    form.reset();
  };

  const handleButtonClick = () => {
    if (collectionMethod === 'charge_automatically') {
      setIsDialogOpen(true);
    } else {
      changeBillingType();
    }
  };

  return (
    <>
      <div className="text-right">
        <Button
          variant="outline"
          onClick={handleButtonClick}
          size="sm"
          disabled={isLoadingUpdateBillingType}
        >
          {isLoadingUpdateBillingType
            ? 'Updating...'
            : collectionMethod === 'send_invoice'
            ? 'Enable Automatic Charging'
            : 'Switch to Invoice Last'}
        </Button>
        <p className="text-sm text-gray-500 mt-2">
          {collectionMethod === 'send_invoice'
            ? 'Change to automatic charging for future payments'
            : 'Change to invoice last for future payments'}
        </p>
      </div>

      <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Switch to Invoice Last</DialogTitle>
            <DialogDescription>
              Please specify the number of days until the invoice is due.
            </DialogDescription>
          </DialogHeader>
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(handleSubmit)}
              className="space-y-6"
            >
              <FormField
                control={form.control}
                name="daysUntilDue"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Days Until Due</FormLabel>
                    <FormControl>
                      <Input
                        type="number"
                        {...field}
                        onChange={(e) => field.onChange(Number(e.target.value))}
                        min={0}
                        required
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <DialogFooter>
                <Button
                  type="button"
                  variant="outline"
                  onClick={() => {
                    setIsDialogOpen(false);
                    form.reset();
                  }}
                >
                  Cancel
                </Button>
                <Button type="submit" disabled={isLoadingUpdateBillingType}>
                  {isLoadingUpdateBillingType ? 'Updating...' : 'Confirm'}
                </Button>
              </DialogFooter>
            </form>
          </Form>
        </DialogContent>
      </Dialog>
    </>
  );
}
