import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import { CreateSubscriptionFormValues } from '../schema/createSubscriptionSchema';
import useClearCacheAdmin from '../../hooks/useClearCacheAdmin';

export type CreateSubscriptionError = {
  message: string;
  code: string;
};

type UpdateSubscriptionBillingType = {
  customerId: string;
  method: 'charge_automatically' | 'send_invoice';
  daysUntilDue?: number;
};

export const useSubscriptionOperations = (params?: { orgId?: string }) => {
  const { accessToken } = useAuth();
  const queryClient = useQueryClient();
  const apiBaseUrl = import.meta.env.VITE_SERVER_API_URL;

  const { clearSubscriptionCache } = useClearCacheAdmin({
    orgId: params?.orgId,
  });

  const getHeaders = () => {
    const headers = new Headers();
    headers.append('Authorization', `Bearer ${accessToken}`);
    headers.append('Content-Type', 'application/json');
    return headers;
  };

  const successUrl = `${window.location.origin}`;
  const cancelUrl = `${window.location.origin}`;

  const searchParams = new URLSearchParams();
  searchParams.append('successUrl', successUrl);
  searchParams.append('cancelUrl', cancelUrl);
  if (params?.orgId) {
    searchParams.append('orgId', params.orgId);
  }

  const url = `${apiBaseUrl}/subscription/manage${
    searchParams.toString() ? `?${searchParams.toString()}` : ''
  }`;

  const createSubscriptionMutation = useMutation<
    any,
    CreateSubscriptionError,
    CreateSubscriptionFormValues
  >({
    mutationFn: async (data: CreateSubscriptionFormValues) => {
      const response = await fetch(url, {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify({
          product: data.product,
          plan: data.plan,
          creatorSeats: data.creatorSeats,
          viewerSeats:
            data.product === 'enterprise'
              ? data.viewerSeats || undefined
              : undefined,
          paymentType: data.paymentType,
          daysUntilDue:
            data.paymentType === 'invoice' ? data.daysUntilDue : undefined,
          discountCode: data.discountCode || undefined,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw errorData;
      }

      return response.json();
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['subscriptionStatus'] });
      queryClient.invalidateQueries({ queryKey: ['invoices'] });
      queryClient.invalidateQueries({ queryKey: ['payments'] });
      queryClient.invalidateQueries({ queryKey: ['organisation'] });
    },
  });

  const createFreeTrial = useMutation<any, Error, CreateSubscriptionFormValues>(
    {
      mutationFn: async (data: CreateSubscriptionFormValues) => {
        const url = `${apiBaseUrl}/admin/subscription/freeTrial/${params?.orgId}`;

        const response = await fetch(url, {
          method: 'POST',
          headers: getHeaders(),
          body: JSON.stringify({
            product: data.product,
            plan: data.plan,
            action: 'start',
            trialDays: data.trialDays,
            collectionMethod:
              data.paymentType === 'invoice'
                ? 'send_invoice'
                : 'charge_automatically',
            ...(data.paymentType === 'invoice' && {
              daysUntilDue: data.daysUntilDue,
            }),
            numOfCreatorSeats: data.creatorSeats,
            ...(data.product === 'enterprise' &&
              (data.viewerSeats ?? 0) > 0 && {
                numOfViewerSeats: data.viewerSeats,
              }),
            discountCode: data.discountCode,
          }),
        });

        if (!response.ok) {
          const errorData = await response.json();
          throw errorData;
        }

        return response.json();
      },
      onSuccess: async () => {
        await clearSubscriptionCache();
        queryClient.invalidateQueries({ queryKey: ['subscriptionStatus'] });
      },
    }
  );

  const extendSubscription = useMutation<any, Error, { addedDays: number }>({
    mutationFn: async (data) => {
      const url = `${apiBaseUrl}/admin/subscription/freeTrial/${params?.orgId}`;

      const response = await fetch(url, {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify({
          action: 'extend',
          trialDays: data.addedDays,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw errorData;
      }

      return response.json();
    },
    onSuccess: async () => {
      await clearSubscriptionCache();
      queryClient.invalidateQueries({ queryKey: ['subscriptionStatus'] });
    },
  });

  const updateSubscriptionBillingType = useMutation<
    any,
    Error,
    UpdateSubscriptionBillingType
  >({
    mutationFn: async (data: UpdateSubscriptionBillingType) => {
      const response = await fetch(
        `${apiBaseUrl}/admin/subscription/billingType/${data.customerId}`,
        {
          method: 'PATCH',
          headers: getHeaders(),
          body: JSON.stringify({
            method: data.method,
            ...(data.method === 'send_invoice' && {
              daysUntilDue: data.daysUntilDue,
            }),
          }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw errorData;
      }

      return response.json();
    },
    onSuccess: async () => {
      await clearSubscriptionCache();
      queryClient.invalidateQueries({ queryKey: ['subscriptionStatus'] });
    },
  });

  const updateDueDate = useMutation<
    any,
    Error,
    { customerId: string; additionalDays: number }
  >({
    mutationFn: async (data) => {
      const response = await fetch(
        `${apiBaseUrl}/admin/subscription/dueDate/${data.customerId}`,
        {
          method: 'PATCH',
          headers: getHeaders(),
          body: JSON.stringify({
            additionalDays: data.additionalDays,
          }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw errorData;
      }

      return response.json();
    },
    onSuccess: async () => {
      await clearSubscriptionCache();
      queryClient.invalidateQueries({ queryKey: ['subscriptionStatus'] });
      queryClient.invalidateQueries({ queryKey: ['invoices'] });
      queryClient.invalidateQueries({ queryKey: ['payments'] });
    },
  });

  return {
    createSubscription: createSubscriptionMutation.mutateAsync,
    isLoading: createSubscriptionMutation.isPending,
    error: createSubscriptionMutation.error,

    createFreeTrial: createFreeTrial.mutateAsync,
    isLoadingFreeTrial: createFreeTrial.isPending,
    errorFreeTrial: createFreeTrial.error,

    updateSubscriptionBillingType: updateSubscriptionBillingType.mutateAsync,
    isLoadingUpdateBillingType: updateSubscriptionBillingType.isPending,

    updateDueDate: updateDueDate.mutateAsync,
    isLoadingUpdateDueDate: updateDueDate.isPending,

    extendTrial: extendSubscription.mutateAsync,
    isLoadingExtendTrial: extendSubscription.isPending,
  };
};
