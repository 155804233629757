import { useState } from 'react';
import { Minus, File, RefreshCw } from 'lucide-react';
import useUploadDocs from '@fluency/ui/features/Vaults/hooks/useUploadDocs';

interface ElectronAPI {
  ipcRenderer: {
    invoke(channel: string, ...args: unknown[]): Promise<unknown>;
    on(channel: string, callback: (...args: any[]) => void): void;
    removeListener(channel: string, callback: (...args: any[]) => void): void;
  };
  on(channel: string, callback: (...args: any[]) => void): void;
  removeListener(channel: string, callback: (...args: any[]) => void): void;
}

declare global {
  interface Window {
    electron?: ElectronAPI;
  }
}

export default function DocumentUploading() {
  const [isMinimized, setIsMinimized] = useState(false);
  const { docsInProgress } = useUploadDocs();

  const currentDoc = Object.values(docsInProgress)[0];
  const docName = currentDoc?.name ?? 'New Process';

  if (!currentDoc) {
    return null;
  }

  return (
    <div
      onClick={() => isMinimized && setIsMinimized(false)}
      className={`
        flex items-start border bg-white rounded-lg absolute right-4 bottom-4 shadow-sm
        transition-all duration-300 ease-in-out cursor-pointer overflow-hidden
        ${isMinimized ? 'w-[56px]' : 'w-64'}
      `}
    >
      <div
        className={`
        flex gap-2 items-start w-full p-2
      `}
      >
        <div className="relative flex-shrink-0">
          <File className="w-10 h-10 text-gray-400" strokeWidth={1.2} />
          <RefreshCw className="w-4 h-4 absolute bottom-2 right-3 animate-spin text-gray-500" />
        </div>
        <div
          className={`
          flex flex-col
          transition-all duration-300 ease-in-out origin-left
          ${
            isMinimized
              ? 'w-0 scale-x-0 opacity-0'
              : 'w-full scale-x-100 opacity-100'
          }
        `}
        >
          <span className="text-sm font-medium text-gray-700 whitespace-nowrap">
            Processing recording...
          </span>
          <span
            className="text-xs text-gray-500 truncate max-w-[280px]"
            title={docName}
          >
            {docName}
          </span>
        </div>
        <button
          onClick={(e) => {
            e.stopPropagation();
            setIsMinimized(true);
          }}
          className={`
            text-gray-500 hover:text-gray-700 transition-colors p-1 rounded hover:bg-gray-100
            ${isMinimized ? 'hidden' : 'ml-auto'}
          `}
          title="Minimize"
        >
          <Minus className="w-4 h-4" />
        </button>
      </div>
    </div>
  );
}
