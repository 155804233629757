import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { Button } from '@fluency/ui/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '@fluency/ui/components/ui/form';
import { Input } from '@fluency/ui/components/ui/input';
import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import { withPermissionGate } from '@fluency/ui/providers/permissions/PermissionGateProvider';
import { useEffect, useState } from 'react';
import useSubscription from '@fluency/ui/hooks/subscription/useSubscription';
import { useUpdateOrgName } from '../../hooks/useUpdateOrgName';
import { useOrganisationOperations } from '@fluency/ui/features/admin/Organisation/hooks/useOrganisationOperations';

const FormSchema = z.object({
  orgName: z
    .string()
    .min(5, {
      message: 'Organisation name must be at least 5 characters.',
    })
    .max(40, {
      message: 'Organisation name must not exceed 40 characters.',
    }),
});

interface ChangeOrgNameProps {
  orgId?: string;
}

function ChangeOrgName({ orgId }: ChangeOrgNameProps) {
  const { organisationName } = useAuth();
  const { useGetOrganisation } = useOrganisationOperations();
  const { data: organisation, refetch: refetchOrganisation } =
    useGetOrganisation({
      orgId: orgId ?? '',
    });
  const [isEditing, setIsEditing] = useState(false);
  const { refetchSubscription } = useSubscription({ organisationId: orgId });
  const updateOrgNameMutation = useUpdateOrgName({ organisationId: orgId });
  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      orgName: orgId
        ? organisation?.workos?.name ?? organisation?.stripe?.name
        : organisationName,
    },
  });

  useEffect(() => {
    if (organisation && orgId) {
      form.reset({
        orgName: organisation.workos?.name ?? organisation.stripe?.name,
      });
    }
  }, [organisation]);

  function onSubmit(data: z.infer<typeof FormSchema>) {
    updateOrgNameMutation.mutate(data.orgName, {
      onSuccess: () => {
        setIsEditing(false);
        refetchSubscription();
        refetchOrganisation();
      },
    });
  }

  return (
    <div className="space-y-3">
      <div>
        <h3 className="text-sm font-medium">Organisation Name</h3>
        <p className="text-sm text-muted-foreground">
          Update your organisation's display name.
        </p>
      </div>

      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-2">
          <FormField
            control={form.control}
            name="orgName"
            render={({ field }) => (
              <FormItem>
                <div className="flex items-center gap-2">
                  <FormControl>
                    <Input
                      {...field}
                      disabled={!isEditing}
                      className="max-w-md"
                    />
                  </FormControl>
                  {!isEditing ? (
                    <Button
                      type="button"
                      variant="secondary"
                      size="sm"
                      onClick={() => setIsEditing(true)}
                    >
                      Edit
                    </Button>
                  ) : (
                    <div className="flex gap-2">
                      <Button
                        type="submit"
                        size="sm"
                        disabled={updateOrgNameMutation.isPending}
                      >
                        {updateOrgNameMutation.isPending ? (
                          <>Saving...</>
                        ) : (
                          <>Save</>
                        )}
                      </Button>
                      <Button
                        variant="outline"
                        size="sm"
                        onClick={() => {
                          setIsEditing(false);
                          form.reset();
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  )}
                </div>
                <FormMessage />
              </FormItem>
            )}
          />
        </form>
      </Form>
    </div>
  );
}

export default withPermissionGate(ChangeOrgName, ['org:update']);
