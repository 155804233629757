import ReactFlow, {
  MiniMap,
  Controls,
  Background,
  Edge,
  MarkerType,
} from 'reactflow';
import { Button } from '@fluency/ui/components/ui/button';
import 'reactflow/dist/style.css';
import nodeTypes from './utils/nodeTypes';
import { edgeTypes } from './utils/edgeTypes';
import CustomConnectionLine from './components/edges/CustomConnectionLine';
import { Trash } from 'lucide-react';
import {
  TooltipContent,
  TooltipProvider,
  Tooltip,
  TooltipTrigger,
} from '@fluency/ui/components/ui/tooltip';
import { useShallow, useWorkflowStore } from './store/index';
import useReactFlowHelpers from './hooks/useReactFlowHelpers';
import useCreateElements from './hooks/useCreateElements';
import AlignmentLines from './components/helpers/AlignmentLines';
import { useEffect } from 'react';

interface EditWorkflowProps {
  reactFlowRef: React.RefObject<HTMLDivElement>;
}

const EditWorkflow = (props: EditWorkflowProps) => {
  const { reactFlowRef } = props;

  // Store
  const {
    workflow,
    nodes,
    edges,
    alignmentLines,
    removeNode,
    removeEdge,
    clearReactFlow,
    setUpdateType,
  } = useWorkflowStore(
    useShallow((state) => ({
      workflow: state.workflow,
      nodes: state.nodes,
      edges: state.edges,
      alignmentLines: state.alignmentLines,
      removeNode: state.removeNode,
      removeEdge: state.removeEdge,
      clearReactFlow: state.clearReactFlow,
      setUpdateType: state.setUpdateType,
    }))
  );

  const {
    onNodesChange,
    onEdgesChange,
    onEdgeLabelChange,
    onConnect,
    onNodeDragStart,
    onNodeDrag,
    onNodeDragStop,
    onDragOver,
  } = useReactFlowHelpers();

  const { handleDrop } = useCreateElements({
    vaultId: workflow?.vault.id ?? '',
    reactFlowWrapper: reactFlowRef,
  });

  if (!workflow) {
    return <div>Loading workflow...</div>;
  }

  const processedNodes = nodes.map((node) => ({
    ...node,
    data: {
      ...node.data,
      onDelete: () => removeNode(node.id),
    },
  }));

  const processedEdges = edges.map((edge: Edge) => ({
    ...edge,
    data: {
      ...edge.data,
      onEdgeLabelChange,
      onDelete: removeEdge,
    },
    markerEnd: {
      type: MarkerType.ArrowClosed,
      width: 30,
      height: 30,
      color: 'black',
    },
  }));

  // Reset the updateType of the workflow on the component unmount
  // This is needed to reset the updated nodes/edges if the user didn't save the changes
  useEffect(() => {
    return () => {
      setUpdateType(null);
    };
  }, []);

  return (
    <div className="flex-1 flex flex-col">
      <div
        ref={reactFlowRef}
        className="flex-1 border relative"
        onDrop={handleDrop}
        onDragOver={onDragOver}
      >
        <div className="absolute top-4 right-4 z-10">
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  variant="outline"
                  size="icon"
                  onClick={() => clearReactFlow()}
                >
                  <Trash className="h-4 w-4" />
                </Button>
              </TooltipTrigger>
              <TooltipContent>Clear workflow</TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>

        <ReactFlow
          nodes={processedNodes}
          edges={processedEdges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          nodesDraggable={true}
          nodesConnectable={true}
          elementsSelectable={true}
          edgeTypes={edgeTypes}
          fitView
          nodeTypes={nodeTypes}
          connectionLineComponent={CustomConnectionLine}
          onNodeDragStart={onNodeDragStart}
          onNodeDrag={onNodeDrag}
          onNodeDragStop={onNodeDragStop}
          defaultEdgeOptions={{
            type: 'labeled',
          }}
          proOptions={{ hideAttribution: true }}
        >
          <Background />
          <Controls showInteractive={false} />
          <MiniMap />
          <AlignmentLines alignmentLines={alignmentLines} />
        </ReactFlow>
      </div>
    </div>
  );
};

export default EditWorkflow;
