import { Camera, LoaderCircle, Replace, X } from 'lucide-react';
import { useRef, useState, useEffect } from 'react';
import { Button } from '@fluency/ui/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@fluency/ui/components/ui/dialog';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@fluency/ui/components/ui/tooltip';
import { Input } from '@fluency/ui/components/ui/input';
import { Label } from '@fluency/ui/components/ui/label';
import useReplaceScreenshot from '../../../hooks/useReplaceScreenshot';
import { convertToPng } from '../../editor/utils/convertToPng';
import { Logger } from '@fluency/ui/features/Logger';
import { Alert, AlertDescription } from '@fluency/ui/components/ui/alert';

interface ScreenshotDialogProps {
  stepId: string;
  documentId: string;
  mode?: 'add' | 'replace';
}

const ScreenshotDialog: React.FC<ScreenshotDialogProps> = ({
  stepId,
  documentId,
  mode = 'replace',
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const dropZoneRef = useRef<HTMLDivElement>(null);
  const [file, setFile] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [error, setError] = useState<string>('');
  const { mutate: replaceScreenshot, isPending } =
    useReplaceScreenshot(documentId);

  const isAddMode = mode === 'add';
  const title = isAddMode ? 'Add Screenshot' : 'Replace Screenshot';
  const tooltipText = isAddMode ? 'Add Screenshot' : 'Replace Screenshot';
  const Icon = isAddMode ? Camera : Replace;

  const handleFile = async (newFile: File) => {
    try {
      if (!newFile.type.startsWith('image/')) {
        setError('Please select an image file');
        return;
      }
      setFile(newFile);
      const url = URL.createObjectURL(newFile);
      setPreviewUrl(url);
    } catch (error) {
      Logger.error('Error processing file:', error);
      handleCancel();
    }
  };

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = async (e: React.DragEvent) => {
    // Reset error state
    setError('');
    e.preventDefault();
    setIsDragging(false);

    const droppedFile = e.dataTransfer.files[0];
    if (droppedFile) {
      await handleFile(droppedFile);
    }
  };

  const handlePaste = async (e: ClipboardEvent) => {
    const items = e.clipboardData?.items;
    if (!items) return;

    for (const item of Array.from(items)) {
      if (item.type.startsWith('image/')) {
        const file = item.getAsFile();
        if (file) {
          await handleFile(file);
          break;
        }
      }
    }
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      await handleFile(file);
    }
  };

  const handleSubmit = async (event: React.MouseEvent) => {
    event.preventDefault();

    if (!file) return;

    try {
      const pngFile = await convertToPng(file);
      replaceScreenshot(
        {
          file: pngFile,
          stepId,
          fileType: 'png',
        },
        {
          onSuccess: () => {
            handleCancel();
            setIsOpen(false);
          },
        }
      );
    } catch (error) {
      Logger.error('Error replacing screenshot:', error);
    }
  };

  const handleCancel = () => {
    setFile(null);
    if (previewUrl) {
      URL.revokeObjectURL(previewUrl);
      setPreviewUrl(null);
    }
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  // Clean up states when dialog closes
  const handleOpenChange = (open: boolean) => {
    setIsOpen(open);
    if (!open) {
      handleCancel();
      setIsDragging(false);
      setError('');
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('paste', handlePaste);
    }
    return () => {
      document.removeEventListener('paste', handlePaste);
    };
  }, [isOpen]);

  return (
    <Dialog open={isOpen} onOpenChange={handleOpenChange}>
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <DialogTrigger asChild>
              <Button variant="ghost" size="sm" className="group">
                <Icon className="h-4 w-4" />
              </Button>
            </DialogTrigger>
          </TooltipTrigger>
          <TooltipContent>{tooltipText}</TooltipContent>
        </Tooltip>
      </TooltipProvider>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
          <DialogDescription>
            <div className="mt-4 bg-fluency-50 border border-fluency-100 rounded-lg p-4">
              <h4 className="font-medium text-sm text-fluency-800 mb-2">
                Pro Tips
              </h4>
              <span className="text-sm text-fluency-700">
                You can also add/replace screenshot with the following ways:
              </span>
              <ul className="text-sm text-fluency-700 space-y-2 mt-2">
                <li className="flex items-start gap-2">
                  <span>•</span>
                  <span>Paste them (Ctrl/Cmd + V)</span>
                </li>
                <li className="flex items-start gap-2">
                  <span>•</span>
                  <span>Drag and drop them</span>
                </li>
              </ul>
              <span className="block mt-2 text-xs text-fluency-700">
                Works in: Screenshot dialog, image editor, and document editor
              </span>
            </div>
          </DialogDescription>
        </DialogHeader>

        <div className="grid gap-4">
          <div className="space-y-2">
            <Label htmlFor="screenshot">Screenshot</Label>
            <Input
              id="screenshot"
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              ref={fileInputRef}
              className="hidden"
            />

            <div
              ref={dropZoneRef}
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}
              onClick={() => fileInputRef.current?.click()}
              className={`
            relative cursor-pointer border-2 border-dashed rounded-lg p-4 
            hover:border-primary hover:bg-primary/5 transition-colors
            ${isDragging ? 'border-primary bg-primary/5' : 'border-gray-200'}
          `}
            >
              <div className="text-center text-sm text-gray-600">
                {file ? (
                  <div className="flex items-center justify-center gap-2">
                    <span className="flex-1">{file.name}</span>
                    <Button
                      variant="ghost"
                      size="icon"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleCancel();
                      }}
                      className="h-8 w-8 z-10 hover:bg-gray-300"
                    >
                      <X className="h-5 w-5" />
                    </Button>
                  </div>
                ) : (
                  'Drag and drop an image here or click to select'
                )}
              </div>
            </div>
          </div>

          {error && (
            <Alert variant="destructive" className="w-full max-w-md">
              <AlertDescription>{error}</AlertDescription>
            </Alert>
          )}

          {previewUrl && (
            <div className="mt-4 relative rounded-lg overflow-hidden">
              <img
                src={previewUrl}
                alt="Preview"
                className="max-h-48 w-full object-contain bg-slate-100"
              />
            </div>
          )}

          <div className="flex gap-2">
            <Button
              variant="outline"
              onClick={handleCancel}
              size="sm"
              disabled={isPending}
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              disabled={isPending || !file}
              className="flex-1"
              size="sm"
            >
              {isPending ? (
                <>
                  <LoaderCircle className="animate-spin w-4 h-4 mr-2" />
                  Uploading...
                </>
              ) : (
                'Upload Screenshot'
              )}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ScreenshotDialog;
