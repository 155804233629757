import {
  createFileRoute,
  useParams,
  useNavigate,
} from '@tanstack/react-router';
import { Settings } from '@fluency/ui/features';
import { RolePermissionHandler } from '@fluency/ui/features/AuthGuard/RolePermissionHandler';
import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import NoSubscriptionMessage from '@fluency/ui/components/NoSubscriptionMessage';

const SettingsPage = () => {
  const { setting } = useParams({ from: '/settings/$setting' });
  const { subscription, user, orgId } = useAuth();
  const navigate = useNavigate();

  if (!user) {
    navigate({
      to: '/logout',
      replace: true,
    });
    return null;
  }

  if (orgId === null) {
    navigate({
      to: '/orgform',
      replace: true,
    });
    return null;
  }

  if (
    !subscription ||
    subscription === 'absent' ||
    !(subscription === 'active' || subscription === 'trialing')
  ) {
    return <NoSubscriptionMessage />;
  }

  return (
    <RolePermissionHandler requiredPermissions={['user:read']}>
      <Settings setting={setting} />
    </RolePermissionHandler>
  );
};

export const Route = createFileRoute('/settings/$setting')({
  component: SettingsPage,
});
