import { RefreshCw } from 'lucide-react';
import { Card, CardContent } from '@fluency/ui/components/ui/card';

interface InProgressProps {
  fileName?: string;
}

export default function InProgress({ fileName = 'Document' }: InProgressProps) {
  return (
    <div className="flex justify-center items-center h-[90vh]">
      <Card className="w-96">
        <CardContent className="pt-6">
          <div className="flex flex-col items-center text-left space-y-4">
            <div className="space-y-2">
              <h3 className="font-semibold text-lg flex gap-2 items-center">
                <RefreshCw className="w-6 h-6 text-gray-500 animate-spin" />
                Upload in Progress
              </h3>
              <p className="text-sm text-muted-foreground">
                {fileName} is being processed. You will be notified when it's
                ready to view.
              </p>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
