import { toast } from '@fluency/ui/components/ui/use-toast';
import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import { useMutation } from '@tanstack/react-query';

interface UpdateOrgNameResponse {
  message: string;
}

async function updateOrgName(
  newName: string,
  token: string,
  orgId?: string
): Promise<UpdateOrgNameResponse> {
  const apiBaseUrl = import.meta.env.VITE_SERVER_API_URL;

  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');
  myHeaders.append('Authorization', `Bearer ${token}`);

  const urlencoded = new URLSearchParams();
  urlencoded.append('organisationName', newName);

  const searchParams = new URLSearchParams();

  if (orgId) {
    searchParams.append('orgId', orgId);
  }

  const url = `${apiBaseUrl}/organisation${
    orgId ? `?${searchParams.toString()}` : ''
  }`;

  const response = await fetch(url, {
    method: 'PUT',
    headers: myHeaders,
    body: urlencoded,
    redirect: 'follow',
  });

  if (!response.ok) {
    throw new Error('Failed to update organisation name');
  }

  const data = await response.text();
  return { message: data };
}

export function useUpdateOrgName(params?: { organisationId?: string }) {
  const { accessToken, setOrganisationName } = useAuth();

  return useMutation<UpdateOrgNameResponse, Error, string>({
    mutationFn: (newName: string) =>
      updateOrgName(newName, accessToken!, params?.organisationId),
    onError: () => {
      toast({
        variant: 'destructive',
        title: 'An error occurred',
        description: `There was a problem updating the organisation name.`,
      });
    },
    onSuccess: (_, newName) => {
      toast({
        variant: 'default',
        title: 'Success',
        description: 'Organisation name updated successfully.',
      });
      setOrganisationName(newName);
    },
  });
}
