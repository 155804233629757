import { useParams } from '@tanstack/react-router';
import DashboardShell from '../Layout/DashboardShell';
import { useOrganisationOperations } from './hooks/useOrganisationOperations';
import FlexibleArrayDisplay from '../common/components/FlexibelArrayDisplay';
import { useMemo } from 'react';
import MemberDetails from './MemberDetails';
import NavSection from '../Layout/NavSection';
import { navigationItems } from './utils/navigationItems';
import AdvancedSettings from '../../Settings/components/Advanced/AdvancedSettings';
import SubscriptionHome from '../Subscription/SubscriptionHome';
import ClearCache from '../common/components/ClearCache';
import { Separator } from '@fluency/ui/components/ui/separator';

const OrganisationDetails = () => {
  const { setting, id } = useParams({
    from: '/admin/organisation/$setting/$id',
  });
  const { useGetOrganisation } = useOrganisationOperations();
  const {
    data: organisation,
    isLoading,
    isError,
  } = useGetOrganisation({ orgId: id });

  const arrayDisplayData = useMemo(() => {
    if (isLoading) {
      return Array.from({ length: 3 }).map(() => ({
        isLoading: true,
      }));
    }

    if (isError) {
      return Array.from({ length: 3 }).map(() => ({
        error: 'There is an error in fetching data from the server',
      }));
    }

    if (organisation) {
      const { stripe, workos, ...db } = organisation;
      const dbData =
        Object.keys(db).length === 0
          ? { error: 'Data not found on database' }
          : {
              data: db,
              previewKeys: ['tenantId', 'stripeCustomerId', 'owner'],
            };
      const workosData = workos
        ? {
            data: workos,
            previewKeys: ['id', 'name', 'createdAt', 'updatedAt'],
          }
        : { error: 'Data not found on WorkOS' };
      const stripeData = stripe
        ? {
            data: stripe,
            previewKeys: ['id', 'name', 'email'],
          }
        : { error: 'Data not found on Stripe' };
      return [dbData, workosData, stripeData];
    }

    return Array.from({ length: 3 }).map(() => ({
      error: 'No data available',
    }));
  }, [organisation, isLoading, isError]);

  return (
    <DashboardShell>
      <main className="flex h-full border-t">
        <div className="relative flex-shrink-0 border-r">
          <div className="sticky top-0 w-[240px] h-[calc(100vh-65px-10px)] overflow-y-auto px-2 py-4">
            <div className="space-y-6">
              <ClearCache orgId={id} />
              <Separator className="mt-2" />
              {navigationItems.map((item, index) => (
                <NavSection
                  key={index}
                  items={item.items}
                  title={item.title}
                  id={id}
                />
              ))}
            </div>
          </div>
        </div>

        <div className="flex-1 overflow-y-auto mx-auto max-w-5xl">
          <div className="px-8 py-6 h-full">
            <div className="mx-8 space-y-6 h-full">
              {setting === 'detail' && (
                <FlexibleArrayDisplay
                  direction="vertical"
                  itemTitles={['Database', 'WorkOS', 'Stripe']}
                  items={arrayDisplayData ?? []}
                />
              )}

              {setting === 'member' && <MemberDetails orgId={id} />}

              {setting === 'advanced-settings' && (
                <AdvancedSettings orgId={id} />
              )}

              {setting === 'subscription' && <SubscriptionHome orgId={id} />}
            </div>
          </div>
        </div>
      </main>
    </DashboardShell>
  );
};

export default OrganisationDetails;
