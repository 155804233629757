import { useCallback } from 'react';
import { BPMNElement } from '../types/workflow';
import { useCreateEmptyDoc } from '@fluency/ui/hooks/documentation/useCreateEmptyDoc';
import { Logger } from '../../Logger';
import { Node, useReactFlow } from 'reactflow';
import { Documentation } from '@fluency/ui/hooks/documentation/types/GetDocs';
import { useShallow, useWorkflowStore } from '../store';
import { bpmnElements } from '../utils/bpmnElements';
import useWorkflowHelpers from './useWorkflowHelpers';

interface UseCreateElementsProps {
  vaultId: string;
  reactFlowWrapper: React.RefObject<HTMLDivElement>;
}

const useCreateElements = (props: UseCreateElementsProps) => {
  const { vaultId, reactFlowWrapper } = props;
  const { workflow, updateNode, addNode, removeNode } = useWorkflowStore(
    useShallow((state) => ({
      workflow: state.workflow,
      setNodes: state.setNodes,
      updateNode: state.updateNode,
      addNode: state.addNode,
      removeNode: state.removeNode,
    }))
  );

  const { syncDocNode } = useWorkflowHelpers({
    workflowId: workflow?.id ?? '',
  });

  const { getViewport, screenToFlowPosition } = useReactFlow();
  const { mutateAsync: createEmptyDoc } = useCreateEmptyDoc({
    disableRedirect: true,
  });

  const getCenterPosition = useCallback(() => {
    if (reactFlowWrapper.current) {
      const bounds = reactFlowWrapper.current.getBoundingClientRect();
      const { zoom, x: panX, y: panY } = getViewport();

      return {
        x: (bounds.width / 2 - panX) / zoom,
        y: (bounds.height / 2 - panY) / zoom,
      };
    }

    return { x: 0, y: 0 };
  }, [getViewport]);

  const createDocumentNode = useCallback(
    async (position?: { x: number; y: number }) => {
      const { x, y } = getCenterPosition();
      const oldId = `doc-${Date.now()}`;

      const temporaryNode = {
        id: oldId,
        type: 'documentNode',
        position: position ?? { x, y },
        data: {
          documentId: '',
          documentName: 'New Process',
          description: 'Enter description...',
          owner: '',
        },
      };

      addNode(temporaryNode);

      try {
        const payload = {
          duration: 0,
          name: 'New Process',
          description: 'Enter description...',
          createdDate: new Date().toISOString(),
          vaultId: vaultId,
        };
        const { document } = await createEmptyDoc(payload);

        const newNode: Node = {
          id: oldId,
          type: 'documentNode',
          position: position ?? { x, y },
          data: {
            documentId: document.documentationId,
            documentName: document.documentationName,
            description: document.description,
            owner: `${document.userInfo[0]?.firstName ?? ''} ${
              document.userInfo[0]?.lastName ?? ''
            }`.trim(),
          },
        };

        updateNode(oldId, syncDocNode(newNode));
      } catch (error) {
        Logger.error('Failed to create document:', error);
        removeNode(oldId);
      }
    },
    [vaultId, createEmptyDoc]
  );

  const handleDrop = useCallback(
    async (event: React.DragEvent<HTMLDivElement>) => {
      event.preventDefault();

      const nodeType = event.dataTransfer.getData('application/reactflow');
      if (!nodeType) return;

      const position = screenToFlowPosition({
        x: event.clientX,
        y: event.clientY,
      });

      if (nodeType === 'documentNode') {
        // Check if this is a new empty document or an existing one
        const documentId = event.dataTransfer.getData('application/documentId');

        if (!documentId) {
          // This is a new empty document
          await createDocumentNode(position);
        } else {
          // This is an existing document, handle normally
          const newNode: Node = {
            id: `doc-${documentId}-${Date.now()}`,
            type: 'documentNode',
            position,
            data: {
              documentId,
            },
          };

          addNode(syncDocNode(newNode));
        }
      } else {
        // Handle other node types (BPMN elements)
        const id = event.dataTransfer.getData('application/id');
        const droppedItem = bpmnElements.find((item) => item.id === id);
        if (!droppedItem) return;

        const newNode: Node = {
          id: `${id}-${Date.now()}`,
          type: nodeType,
          position,
          data: {
            label: droppedItem.title,
            onDelete: () => removeNode(`${id}-${Date.now()}`),
          },
        };

        addNode(newNode);
      }
    },
    [bpmnElements, createDocumentNode, screenToFlowPosition]
  );

  // Click handler for BPMN element nodes
  const handleElementClick = useCallback(
    (element: BPMNElement) => {
      if (reactFlowWrapper.current) {
        const { x, y } = getCenterPosition();

        // Create random id for the new node
        const id = `${element.id}-${Date.now()}`;

        const newNode: Node = {
          id: id,
          type: element.type,
          position: { x, y },
          data: { label: element.label ?? element.title },
        };

        addNode(newNode);
      }
    },
    [getCenterPosition]
  );

  // Click handler for document nodes
  const handleDocNodeClick = useCallback(
    (doc: Documentation) => {
      if (reactFlowWrapper.current) {
        const { x, y } = getCenterPosition();

        const newNode: Node = {
          id: `doc-${doc.versionInfo.originalDocumentationId}-${Date.now()}`,
          type: 'documentNode',
          position: { x, y },
          data: {
            documentId: doc.documentationId,
          },
        };

        addNode(syncDocNode(newNode));
      }
    },
    [getCenterPosition]
  );

  // Click handler for empty document node ("New Process")
  const handleEmptyDocClick = useCallback(() => {
    if (reactFlowWrapper.current) {
      const { x, y } = getCenterPosition();

      createDocumentNode({ x, y });
    }
  }, [getCenterPosition, createDocumentNode]);

  return {
    handleDrop,
    handleElementClick,
    handleDocNodeClick,
    handleEmptyDocClick,
  };
};

export default useCreateElements;
