import React from 'react';
import {
  ChevronLeft,
  ChevronRight,
  Info,
  ArrowUpDown,
  ArrowUp,
  ArrowDown,
} from 'lucide-react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@fluency/ui/components/ui/table';
import { Button } from '@fluency/ui/components/ui/button';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@fluency/ui/components/ui/select';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@fluency/ui/components/ui/tooltip';
import { PaginationMeta, SortConfig } from '../types/pagination';
import { Skeleton } from '@fluency/ui/components/ui/skeleton';
import RowActions, { ActionItem } from './RowActions';

type DataItem = Record<string, any>;

export interface HeaderConfig<T> {
  key: string;
  header: string;
  renderCell: (item: T) => React.ReactNode;
  renderNote?: (item: T) => string | null;
  width?: string;
  sortable?: boolean;
}

interface DataListProps<T> {
  data: T[];
  meta: PaginationMeta;
  headers: HeaderConfig<T>[];
  onPageChange: (page: number) => void;
  onPageSizeChange: (pageSize: number) => void;
  onSort?: (sortConfig: SortConfig) => void;
  currentSort?: SortConfig;
  isLoading?: boolean;
  actions?: ActionItem[];
}

const PAGE_SIZE_OPTIONS = [10, 20, 50, 100];

function DataList<T extends DataItem>({
  data,
  meta,
  headers,
  onPageChange,
  onPageSizeChange,
  onSort,
  currentSort,
  isLoading = false,
  actions = [],
}: DataListProps<T>) {
  // Calculate the total width for actions column if present
  const actionsWidth = actions.length > 0 ? '100px' : '0';

  const handleSort = (key: string) => {
    if (!onSort) return;

    const newDirection =
      currentSort?.key !== key
        ? 'asc'
        : currentSort.direction === 'asc'
        ? 'desc'
        : 'asc';

    onSort({
      key,
      direction: newDirection,
    });
  };

  const renderSortIcon = (header: HeaderConfig<T>) => {
    if (!header.sortable) return null;

    if (currentSort?.key !== header.key) {
      return <ArrowUpDown className="ml-2 h-4 w-4" />;
    }

    return currentSort.direction === 'asc' ? (
      <ArrowUp className="ml-2 h-4 w-4" />
    ) : (
      <ArrowDown className="ml-2 h-4 w-4" />
    );
  };

  const renderHeaderContent = (header: HeaderConfig<T>) => {
    if (!header.sortable) {
      return header.header;
    }

    return (
      <Button
        variant="ghost"
        size="sm"
        className="h-8 px-2 py-0 w-full flex justify-start"
        onClick={() => handleSort(header.key)}
      >
        <span>{header.header}</span>
        {renderSortIcon(header)}
      </Button>
    );
  };

  const renderCellWithNote = (header: HeaderConfig<T>, item: T) => {
    const cellContent = header.renderCell(item);

    if (header.renderNote) {
      const note = header.renderNote(item);

      if (note) {
        return (
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger className="flex items-center gap-1 cursor-help w-full">
                <span className="inline-flex items-center border-b border-dashed border-gray-400">
                  {cellContent}
                  <Info className="h-3 w-3 text-gray-500 ml-1" />
                </span>
              </TooltipTrigger>
              <TooltipContent>
                <p>{note}</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        );
      }
    }
    return cellContent;
  };

  const renderActions = (item: T) => {
    if (!actions?.length) return null;
    return <RowActions actions={actions} item={item} />;
  };

  const getColumnStyle = (header: HeaderConfig<T>) => {
    const width = header.width || `${100 / headers.length}%`;
    const textAlign = 'left' as const;
    return { width, textAlign };
  };

  const renderRows = (data: T[], meta: PaginationMeta, isLoading: boolean) => {
    const totalColumns = headers.length + (actions.length > 0 ? 1 : 0);

    return (
      <>
        {isLoading &&
          Array.from({ length: meta.limit }).map((_item, index) => (
            <TableRow className="h-12" key={`row-${index}`}>
              <TableCell colSpan={totalColumns} className="p-1">
                <Skeleton className="h-full absolute inset-0" />
              </TableCell>
            </TableRow>
          ))}

        {!isLoading &&
          data.map((item, index) => (
            <TableRow className="h-12" key={index}>
              {headers.map((header) => (
                <TableCell
                  key={header.key}
                  style={getColumnStyle(header)}
                  className="p-2 overflow-hidden text-ellipsis whitespace-nowrap"
                >
                  {renderCellWithNote(header, item)}
                </TableCell>
              ))}
              {actions.length > 0 && (
                <TableCell
                  className="text-right p-2"
                  style={{ width: actionsWidth }}
                >
                  {renderActions(item)}
                </TableCell>
              )}
            </TableRow>
          ))}
      </>
    );
  };

  return (
    <div className="space-y-4">
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            <TableRow>
              {headers.map((header) => (
                <TableHead
                  key={header.key}
                  style={getColumnStyle(header)}
                  className="bg-gray-100/50 p-2"
                >
                  {renderHeaderContent(header)}
                </TableHead>
              ))}
              {actions.length > 0 && (
                <TableHead
                  className="text-right bg-gray-100/50 p-2"
                  style={{ width: actionsWidth }}
                >
                  Actions
                </TableHead>
              )}
            </TableRow>
          </TableHeader>
          <TableBody>{renderRows(data, meta, isLoading)}</TableBody>
        </Table>
      </div>
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-4">
          <p className="text-sm text-gray-700">
            Showing {(meta.page - 1) * meta.limit + 1} to{' '}
            {Math.min(meta.page * meta.limit, meta.totalItems)} of{' '}
            {meta.totalItems} results
          </p>
          <div className="flex items-center space-x-2">
            <span className="text-sm text-gray-700">Show</span>
            <Select
              value={meta.limit.toString()}
              onValueChange={(value: string) =>
                onPageSizeChange(parseInt(value))
              }
            >
              <SelectTrigger className="w-20">
                <SelectValue />
              </SelectTrigger>
              <SelectContent>
                {PAGE_SIZE_OPTIONS.map((size) => (
                  <SelectItem key={size} value={size.toString()}>
                    {size}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
            <span className="text-sm text-gray-700">per page</span>
          </div>
        </div>
        <div className="flex items-center space-x-2">
          <Button
            variant="outline"
            size="sm"
            onClick={() => onPageChange(meta.page - 1)}
            disabled={!meta.hasPreviousPage || isLoading}
          >
            <ChevronLeft className="h-4 w-4" />
            <span className="sr-only">Previous page</span>
          </Button>
          <span className="text-sm font-medium">
            Page {meta.page} of {meta.totalPages}
          </span>
          <Button
            variant="outline"
            size="sm"
            onClick={() => onPageChange(meta.page + 1)}
            disabled={!meta.hasNextPage || isLoading}
          >
            <ChevronRight className="h-4 w-4" />
            <span className="sr-only">Next page</span>
          </Button>
        </div>
      </div>
    </div>
  );
}

export default DataList;
