import React, { useState } from 'react';
import ReactFlow, {
  Background,
  BackgroundVariant,
  Controls,
  MarkerType,
} from 'reactflow';
import { Button } from '@fluency/ui/components/ui/button';
import { FolderOpen, PenSquare, Play, RefreshCw, Square } from 'lucide-react';
import nodeTypes from './utils/nodeTypes';
import { viewEdgeTypes } from './utils/edgeTypes';
import { Sheet, SheetContent } from '@fluency/ui/components/ui/sheet';

import 'reactflow/dist/style.css';
import PreviewPanel from './components/preview/PreviewPanel';
import { useShallow, useWorkflowStore } from './store';

interface ViewWorkflowProps {
  onEditClick?: () => void;
  isLocked: boolean;
  isVaultLocked: boolean;
}

interface EmptyStateProps {
  onEditClick?: () => void;
  isLocked: boolean;
  isVaultLocked: boolean;
}

const EmptyState = ({
  onEditClick,
  isLocked,
  isVaultLocked,
}: EmptyStateProps) => (
  <div className="h-full flex flex-col items-center justify-center gap-4 text-center p-4">
    <div className="rounded-full bg-gray-100 p-4">
      <FolderOpen className="w-8 h-8 text-gray-400" />
    </div>
    <div className="space-y-2">
      <h3 className="font-semibold text-gray-700">Empty process map</h3>
    </div>
    {!isLocked && !isVaultLocked ? (
      <Button onClick={onEditClick} size="sm">
        <PenSquare className="w-4 h-4 mr-2" />
        Edit
      </Button>
    ) : (
      <p className="text-sm font-medium text-gray-500">
        Cannot edit while locked
      </p>
    )}
  </div>
);

const ViewWorkflow: React.FC<ViewWorkflowProps> = ({
  onEditClick,
  isLocked,
  isVaultLocked,
}) => {
  const [showAnimation, setShowAnimation] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState<{
    label: string;
    steps: number;
    id: string;
  } | null>(null);

  const { workflow, nodes, edges } = useWorkflowStore(
    useShallow((state) => ({
      workflow: state.workflow,
      nodes: state.nodes,
      edges: state.edges,
    }))
  );

  if (!workflow) {
    return (
      <div className="h-[calc(100vh-60px)] border-t flex items-center justify-center">
        <RefreshCw className="text-gray-400 animate-spin" />
      </div>
    );
  }

  if (nodes.length === 0) {
    return (
      <div className="h-[calc(100vh-180px)] border-t">
        <EmptyState
          onEditClick={onEditClick}
          isLocked={isLocked}
          isVaultLocked={isVaultLocked}
        />
      </div>
    );
  }

  const processedNodes = nodes.map((node) => ({
    ...node,
    data: {
      ...node.data,
      isViewMode: true,
      ...(node.type === 'documentNode' && {
        isActive: selectedDocument?.id === node.data.documentId,
        onView: () =>
          setSelectedDocument({
            label: node.data.documentId,
            steps: node.data.steps,
            id: node.data.documentId,
          }),
      }),
    },
  }));

  const processedEdges = edges.map((edge) => ({
    ...edge,
    data: {
      ...edge.data,
      showAnimation,
    },
    markerEnd: {
      type: MarkerType.ArrowClosed,
      width: 18,
      height: 18,
      color: 'black',
    },
  }));

  return (
    <div className="h-[calc(100vh-180px)] border-t">
      <ReactFlow
        nodes={processedNodes}
        edges={processedEdges}
        nodeTypes={nodeTypes}
        edgeTypes={viewEdgeTypes}
        nodesDraggable={false}
        nodesConnectable={false}
        elementsSelectable={true}
        selectNodesOnDrag={false}
        fitView
        proOptions={{ hideAttribution: true }}
      >
        <Background
          variant={BackgroundVariant.Dots}
          gap={0}
          size={0}
          color="transparent"
        />
        <Controls showInteractive={false} />

        {/* Animation Toggle */}
        <div className="absolute top-4 left-4 z-10">
          <Button
            size="sm"
            variant="outline"
            onClick={() => setShowAnimation(!showAnimation)}
            className="gap-2"
          >
            {showAnimation ? (
              <Square className="h-4 w-4" />
            ) : (
              <Play className="h-4 w-4" />
            )}
          </Button>
        </div>
      </ReactFlow>

      <Sheet
        open={selectedDocument !== null}
        onOpenChange={() => setSelectedDocument(null)}
      >
        <SheetContent
          side="right"
          className="w-full min-w-[800px] max-w-[900px]"
          inverseClose={true}
        >
          {selectedDocument && (
            <>
              <div className="h-[calc(100vh-100px)]">
                <PreviewPanel
                  document={selectedDocument}
                  onClose={() => setSelectedDocument(null)}
                />
              </div>
            </>
          )}
        </SheetContent>
      </Sheet>
    </div>
  );
};

export default ViewWorkflow;
