import logo from '@fluency/ui/assets/icon-blue.svg';
import { Button } from '@fluency/ui/components/ui/button';
import { Separator } from '@fluency/ui/components/ui/separator';
import { Tabs, TabsList, TabsTrigger } from '@fluency/ui/components/ui/tabs';
import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import { useNavigate } from '@tanstack/react-router';
import {
  ArrowLeft,
  ArrowRight,
  Check,
  ExternalLink,
  Mail,
  RefreshCw,
} from 'lucide-react';
import React, { useMemo, useState } from 'react';
import { PricingCard } from './components/PricingCard';
import { SkeletonCard } from './components/SkeletonCard';
import { useCreateOrganisation } from './hooks/useCreateOrganisation';
import { OrgFormSubmittedData } from '../OrgForm';
import { toast } from '@fluency/ui/components/ui/use-toast';
import useSubscription from '@fluency/ui/hooks/subscription/useSubscription';
import {
  Product,
  useProducts,
} from '@fluency/ui/hooks/subscription/useProducts';
import { Logger } from '@fluency/ui/features/Logger';

const planConfigs: Record<
  string,
  {
    hasSeats: boolean;
    buttonIcon: JSX.Element;
  }
> = {
  'Pro Plan': {
    hasSeats: true,
    buttonIcon: <Check className="w-4 h-4 ml-1.5" />,
  },
  'Business Plan': {
    hasSeats: true,
    buttonIcon: <Check className="w-4 h-4 ml-1.5" />,
  },
  'Enterprise Plan': {
    hasSeats: false,
    buttonIcon: <Mail className="w-4 h-4 ml-1.5" />,
  },
};

const defaultConfig = {
  hasSeats: false,
  buttonIcon: null,
};

export const Subscription = () => {
  const navigate = useNavigate();
  const {
    orgFormData,
    setHasCompletedOrgForm,
    logout,
    refreshTokenWithOrgId,
    orgId,
    accessToken,
    clearTemporaryPermissions,
  } = useAuth();
  const { subscriptionStatus, createCustomerPortalSession } = useSubscription();
  const { products, isLoading: loadingProducts } = useProducts();
  const createOrganisationMutation = useCreateOrganisation();
  const [isGettingCheckoutUrl, _setIsGettingCheckoutUrl] = useState(false);
  const [proSeats, setProSeats] = useState(1);
  const [businessSeats, setBusinessSeats] = useState(1);
  const [isAnnual, setIsAnnual] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const planOrder = ['Pro Plan', 'Business Plan', 'Enterprise Plan'];

  const filteredProducts = useMemo(() => {
    if (!products) return [];
    return planOrder
      .map((planName) =>
        products.find((product: Product) => product.name === planName)
      )
      .filter(
        (product): product is NonNullable<typeof product> =>
          product !== undefined
      );
  }, [products]);

  const getButtonText = (productName: string) => {
    switch (productName) {
      case 'Pro Plan':
      case 'Business Plan':
        return 'Get Started';
      case 'Enterprise Plan':
        return 'Book a meeting with Sales';
      default:
        return undefined;
    }
  };

  const handleSubscription = async (
    productName: string,
    seats: number,
    _productId: string
  ) => {
    setIsLoading(true);

    try {
      if (productName === 'Enterprise Plan') {
        window.location.href = 'mailto:sales@usefluency.com';
      } else {
        let currentAccessToken = accessToken;

        if (!currentAccessToken) {
          throw new Error(
            'Access token is null. User may not be properly authenticated.'
          );
        }

        if (!orgId) {
          Logger.log('[Subscription] Creating organization');
          const orgResult = await createOrganisationMutation.mutateAsync(
            orgFormData as OrgFormSubmittedData
          );

          if (orgResult && orgResult.data && orgResult.data.tenantId) {
            Logger.log('[Subscription] Organization created successfully');
            Logger.log(
              '[Subscription] Refreshing access token with new org ID'
            );
            const freshTokens = await refreshTokenWithOrgId(
              orgResult.data.tenantId
            );
            currentAccessToken = freshTokens.accessToken;

            if (!currentAccessToken) {
              throw new Error(
                'Failed to obtain a valid access token after refreshing.'
              );
            }
          } else {
            throw new Error(
              'Invalid response structure from createOrganisation'
            );
          }
        }

        await createCustomerPortalSession(
          {
            seats,
            productName,
            isAnnual,
            overrideAccessToken: currentAccessToken,
          },
          {
            onSuccess: (data: { sessionUrl: string }) => {
              window.open(data.sessionUrl, '_blank');
              setHasCompletedOrgForm(true);
              clearTemporaryPermissions();
              toast({
                variant: 'default',
                description: (
                  <div className="flex flex-col gap-1.5">
                    <p className="text-gray-600 text-sm">
                      Your payment portal has opened in a new window
                    </p>
                    <button
                      onClick={() => window.open(data.sessionUrl, '_blank')}
                      className="text-fluency-500 hover:text-fluency-600 text-sm font-normal flex items-center gap-1.5 text-left"
                      aria-label="Open payment portal in new window"
                    >
                      <span className="text-fluency-500">
                        <ExternalLink className="h-3.5 w-3.5" />
                      </span>
                      Click here to try again
                    </button>
                  </div>
                ),
                duration: 10000,
              });
            },
            onError: (error: Error) => {
              Logger.error('Failed to create customer portal session:', error);
              toast({
                variant: 'destructive',
                title: 'Error',
                description: 'Failed to create subscription. Please try again.',
              });
            },
          }
        );
      }
    } catch (error) {
      Logger.error('[Subscription] Error in handleSubscription:', error);
      toast({
        variant: 'destructive',
        title: 'Error',
        description: 'An error occurred. Please try again.',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getPrice = (product: Product) => {
    const price = product.prices.find(
      (p) => p.recurring?.interval === (isAnnual ? 'year' : 'month')
    );
    return price ? price.unitAmount / 100 : null;
  };

  if (subscriptionStatus?.status === 'active') {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen w-full bg-slate-50">
        <div className="w-[480px] bg-white rounded-lg p-8">
          <img
            src={logo}
            className="h-14 w-14 mb-8 mx-auto"
            alt="Fluency Logo"
          />

          <h1 className="text-2xl font-normal text-slate-900 mb-2 text-center">
            Your subscription has been activated! 🎉
          </h1>

          <button
            onClick={() => navigate({ to: '/home' })}
            className="w-full flex items-center justify-center bg-fluency-600 hover:bg-fluency-700 text-white font-normal text-[15px] px-4 py-2.5 rounded-[6px] transition-colors mt-8"
          >
            Continue <ArrowRight className="ml-1.5 h-4 w-4" />
          </button>
        </div>

        <button
          onClick={async () => {
            await logout();
            navigate({ to: '/home', replace: true });
          }}
          className="mt-6 flex items-center text-[15px] text-slate-600 hover:text-slate-900 transition-colors"
        >
          <ArrowLeft className="w-4 h-4 mr-1.5" /> Sign in with a different
          account
        </button>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center justify-center min-h-screen w-full overflow-x-auto bg-slate-100 py-8">
      <img src={logo} className="h-14 w-14" alt="Fluency Logo" />
      {!isGettingCheckoutUrl && (
        <>
          <span className="font-semibold pb-4">
            {orgFormData
              ? `Select a plan for ${orgFormData?.organisationName} to continue`
              : 'Select a plan to continue'}
          </span>
          <Separator className="w-96 mb-4" />
        </>
      )}
      <div className="flex items-center justify-center mb-2">
        <Tabs
          value={isAnnual ? 'annual' : 'monthly'}
          className="w-full"
          onValueChange={(value) => setIsAnnual(value === 'annual')}
        >
          <TabsList className="grid w-full grid-cols-2">
            <TabsTrigger value="monthly">Monthly</TabsTrigger>
            <TabsTrigger value="annual" className="relative">
              Annual
            </TabsTrigger>
          </TabsList>
        </Tabs>
      </div>
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        {isGettingCheckoutUrl && (
          <div className="flex justify-center items-center h-24">
            <RefreshCw className="h-5 w-5 mr-2 animate-spin text-gray-600" />
            Opening in browser
          </div>
        )}

        <div className="flex justify-center w gap-8">
          {loadingProducts ? (
            <>
              <SkeletonCard />
              <SkeletonCard />
              <SkeletonCard />
            </>
          ) : (
            <>
              {filteredProducts.map((product) => {
                const price = getPrice(product);
                const features = product.features
                  ? product.features.map(
                      (feature: { name: string }) => feature.name
                    )
                  : [];
                const config = planConfigs[product.name] || defaultConfig;

                let seats: number | undefined,
                  setSeats:
                    | React.Dispatch<React.SetStateAction<number>>
                    | undefined;
                if (product.name === 'Pro Plan') {
                  seats = proSeats;
                  setSeats = setProSeats;
                } else if (product.name === 'Business Plan') {
                  seats = businessSeats;
                  setSeats = setBusinessSeats;
                }

                return (
                  <PricingCard
                    key={product.id}
                    title={product.name}
                    price={
                      product.name === 'Enterprise Plan'
                        ? 'Custom pricing'
                        : price
                        ? `$${
                            isAnnual
                              ? (price / 12).toFixed(2)
                              : price.toFixed(0)
                          }/month`
                        : 'N/A'
                    }
                    pricePerSeat={price ?? 0}
                    features={features}
                    seats={config.hasSeats ? seats : undefined}
                    setSeats={config.hasSeats ? setSeats : undefined}
                    onButtonClick={
                      product.name === 'Enterprise Plan'
                        ? () =>
                            window.open(
                              'https://meetings.hubspot.com/jwanders',
                              '_blank'
                            )
                        : () =>
                            handleSubscription(
                              product.name,
                              seats ?? 1,
                              product.id
                            )
                    }
                    isLoading={isLoading}
                    isGettingCheckoutUrl={isGettingCheckoutUrl}
                    buttonText={getButtonText(product.name)}
                    productName={product.name}
                    isAnnual={isAnnual}
                  />
                );
              })}
            </>
          )}
        </div>
        <div className="w-full items-center flex flex-col justify-center pt-6">
          <Separator className="w-96 -mt-2" />

          {orgId ? (
            <Button
              variant="ghost"
              className="mt-2"
              onClick={async () => {
                navigate({ to: '/logout', replace: true });
              }}
            >
              <ArrowLeft className="w-5 h-5 mr-1.5" /> Sign in with a different
              account
            </Button>
          ) : (
            <Button
              variant="ghost"
              className="mt-2"
              onClick={async () => {
                navigate({ to: '/orgform', replace: true });
              }}
            >
              <ArrowLeft className="w-5 h-5 mr-1.5" /> Back
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Subscription;
