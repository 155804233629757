import { useEffect, useRef, useState } from 'react';
import Annotation from './Annotation';

interface Screenshot {
  downloadUrl: string;
  x1Coordinate: number | null;
  y1Coordinate: number | null;
  x2Coordinate: number | null;
  y2Coordinate: number | null;
  isCropped: boolean;
  annotation: boolean;
  annotations?: AnnotationType[];
}

export interface AnnotationType {
  screenshotAnnotationId: string;
  shapeType: 'CIRCLE' | 'RECTANGLE' | 'LINE' | 'ARROW';
  x1Coordinate: number;
  y1Coordinate: number;
  x2Coordinate: number | null;
  y2Coordinate: number | null;
  annotationIndex?: number | null;
  color: string;
  createdAt?: string;
  updatedAt?: string;
  screenshotId?: string;
}

interface PublicScreenshotViewerProps {
  screenshot: Screenshot;
}

const PublicScreenshotViewer = ({
  screenshot,
}: PublicScreenshotViewerProps) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [naturalSize, setNaturalSize] = useState<{
    width: number;
    height: number;
  } | null>(null);
  const [image] = useState(() => new Image());
  const [imageLoadFailed, setImageLoadFailed] = useState(false);

  const cropData = {
    x1: screenshot.x1Coordinate,
    y1: screenshot.y1Coordinate,
    x2: screenshot.x2Coordinate,
    y2: screenshot.y2Coordinate,
  };

  const hasCropData =
    cropData.x1 !== null &&
    cropData.y1 !== null &&
    cropData.x2 !== null &&
    cropData.y2 !== null &&
    screenshot.isCropped;

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas || !hasCropData) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    image.src = screenshot.downloadUrl;
    image.onload = () => {
      const cropX = (cropData.x1! / 100) * image.naturalWidth;
      const cropY = (cropData.y1! / 100) * image.naturalHeight;
      const cropWidth =
        ((cropData.x2! - cropData.x1!) / 100) * image.naturalWidth;
      const cropHeight =
        ((cropData.y2! - cropData.y1!) / 100) * image.naturalHeight;

      canvas.width = cropWidth;
      canvas.height = cropHeight;
      setNaturalSize({ width: cropWidth, height: cropHeight });

      ctx.imageSmoothingEnabled = true;
      ctx.imageSmoothingQuality = 'high';

      ctx.drawImage(
        image,
        cropX,
        cropY,
        cropWidth,
        cropHeight,
        0,
        0,
        cropWidth,
        cropHeight
      );
    };

    image.onerror = () => setImageLoadFailed(true);

    return () => {
      image.onload = null;
      image.onerror = null;
    };
  }, [screenshot.downloadUrl, cropData, image, hasCropData]);

  if (imageLoadFailed) return null;

  return (
    <div className="relative flex items-start w-fit pb-6">
      <div className="flex-1 flex justify-center overflow-hidden rounded-lg print:mt-0">
        <div
          className="relative flex justify-center items-center transition-all duration-300 ease-in-out"
          ref={containerRef}
          style={{ width: '100%', height: '100%' }}
        >
          <div className="relative transition-transform duration-300 ease-in-out">
            {hasCropData ? (
              <div className="relative">
                <canvas
                  ref={canvasRef}
                  className="h-auto cursor-pointer transition-all duration-300 ease-in-out"
                  style={{
                    display: 'block',
                    maxWidth: naturalSize ? `${naturalSize.width}px` : '100%',
                    width: '100%',
                    transition: 'all 0.3s ease-in-out',
                  }}
                />
                {screenshot.annotation && screenshot.annotations && (
                  <Annotation
                    annotations={screenshot.annotations}
                    annotationVisible={screenshot.annotation}
                    cropData={cropData}
                    isCropped={screenshot.isCropped}
                  />
                )}
              </div>
            ) : (
              <>
                <img
                  className="w-full h-auto object-cover rounded"
                  src={screenshot.downloadUrl}
                  alt="Screenshot"
                  onLoad={(e) => {
                    const img = e.target as HTMLImageElement;
                    setNaturalSize({
                      width: img.naturalWidth,
                      height: img.naturalHeight,
                    });
                  }}
                  onError={() => setImageLoadFailed(true)}
                />
                {screenshot.annotation && screenshot.annotations && (
                  <Annotation
                    annotations={screenshot.annotations}
                    annotationVisible={screenshot.annotation}
                    cropData={{
                      x1: screenshot.x1Coordinate || 0,
                      x2: screenshot.x2Coordinate || 100,
                      y1: screenshot.y1Coordinate || 0,
                      y2: screenshot.y2Coordinate || 100,
                    }}
                    isCropped={screenshot.isCropped}
                  />
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PublicScreenshotViewer;
