import { useState } from 'react';
import { Button } from '@fluency/ui/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@fluency/ui/components/ui/dialog';
import { AlertTriangle } from 'lucide-react';
import { Input } from '@fluency/ui/components/ui/input';
import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import { withPermissionGate } from '@fluency/ui/providers/permissions/PermissionGateProvider';
import { toast } from '@fluency/ui/components/ui/use-toast';
import { useNavigate } from '@tanstack/react-router';
import {
  Alert,
  AlertDescription,
  AlertTitle,
} from '@fluency/ui/components/ui/alert';
import { useQueryClient } from '@tanstack/react-query';

export default withPermissionGate(DeleteOrganisation, ['org:delete']);

const apiBaseUrl = import.meta.env.VITE_SERVER_API_URL;

interface DeleteOrganisationProps {
  orgId?: string;
}

function DeleteOrganisation({ orgId }: DeleteOrganisationProps) {
  const [open, setOpen] = useState(false);
  const [confirmText, setConfirmText] = useState('');
  const [isConfirming, setIsConfirming] = useState(false);
  const navigate = useNavigate();
  const { logout, accessToken } = useAuth();
  const queryClient = useQueryClient();

  const handleDelete = async () => {
    if (!isConfirming) {
      setIsConfirming(true);
      return;
    }

    if (confirmText === 'DELETE') {
      try {
        const searchParams = new URLSearchParams();

        if (orgId) {
          searchParams.append('orgId', orgId);
        }

        const url = `${apiBaseUrl}/organisation${
          orgId ? `?${searchParams.toString()}` : ''
        }`;

        const response = await fetch(url, {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        });

        if (!response.ok)
          throw new Error(`HTTP error! status: ${response.status}`);

        if (orgId) {
          toast({
            title: 'Organisation Deleted',
            description: 'Organisation has been successfully deleted.',
          });
          queryClient.invalidateQueries({ queryKey: ['organisations'] });
        }

        if (!orgId) {
          await logout();
          navigate({ to: '/logout', replace: true });
        } else {
          navigate({ to: '/admin/organisation', replace: true });
        }
      } catch (error) {
        toast({
          title: 'Error',
          description: 'Failed to delete organisation',
          variant: 'destructive',
        });
      }
    }
  };

  return (
    <div className="space-y-3">
      <div>
        <h3 className="text-sm font-medium">Delete Organisation</h3>
        <p className="text-sm text-muted-foreground">
          Permanently delete your organisation and all associated data.
        </p>
      </div>

      <Dialog open={open} onOpenChange={setOpen}>
        <DialogTrigger asChild>
          <Button variant="destructive" size="sm">
            Delete Organisation
          </Button>
        </DialogTrigger>

        <DialogContent>
          <DialogHeader>
            <DialogTitle>Delete Organisation</DialogTitle>
            <DialogDescription>
              This action cannot be undone. All data will be permanently
              deleted.
            </DialogDescription>
          </DialogHeader>

          <div className="space-y-4 py-4">
            {isConfirming && (
              <>
                <Alert variant="destructive">
                  <AlertTriangle className="h-4 w-4" />
                  <AlertTitle>Final Warning</AlertTitle>
                  <AlertDescription>
                    Type DELETE to confirm the permanent deletion of your
                    organisation
                  </AlertDescription>
                </Alert>

                <Input
                  value={confirmText}
                  onChange={(e) => setConfirmText(e.target.value)}
                  placeholder="Type DELETE"
                  className="max-w-full"
                />
              </>
            )}
          </div>

          <DialogFooter>
            {isConfirming ? (
              <>
                <Button
                  variant="outline"
                  onClick={() => {
                    setIsConfirming(false);
                    setConfirmText('');
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="destructive"
                  onClick={handleDelete}
                  disabled={confirmText !== 'DELETE'}
                >
                  Confirm Delete
                </Button>
              </>
            ) : (
              <Button variant="destructive" onClick={handleDelete}>
                Delete Organisation
              </Button>
            )}
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
}
