import { useEffect, useState } from 'react';
import { useNavigate, useParams } from '@tanstack/react-router';
import { decryptSearchParams } from '../common/utils/secureSearchParams';
import { toast } from '@fluency/ui/components/ui/use-toast';
import { useSubscriptionOperations } from '../Subscription/hooks/useSubscriptionOperations';
import Loading2 from '@fluency/ui/components/Loading/Loading2';

const PaymentHome = () => {
  const { id } = useParams({ from: '/payment/$id' });
  const navigate = useNavigate();
  const [orgId, setOrgId] = useState<string | undefined>(undefined);
  const [params, setParams] = useState<Record<string, string>>(
    {} as Record<string, string>
  );
  const { createSubscription } = useSubscriptionOperations({ orgId });

  useEffect(() => {
    const decryptParams = async () => {
      try {
        const params = await decryptSearchParams(id);
        setParams(params);
        setOrgId(params.orgId ?? undefined);
      } catch (err) {
        toast({
          title: 'Error',
          description: 'Failed to process payment',
          variant: 'destructive',
        });
        navigate({ to: '/' });
      }
    };

    decryptParams();
  }, [id]);

  useEffect(() => {
    const processPayment = async () => {
      try {
        if (
          !params.product ||
          !params.plan ||
          !params.creatorSeats ||
          !params.paymentType
        ) {
          toast({
            title: 'Error',
            description:
              'failed to process payment. Please request new payment link',
            variant: 'destructive',
          });
          return;
        }

        const response = await createSubscription({
          product: params.product as 'enterprise' | 'pro',
          plan: params.plan as 'monthly' | 'annual',
          creatorSeats: Number(params.creatorSeats) as number,
          paymentType: params.paymentType as 'immediately' | 'invoice',
          viewerSeats: Number(params.viewerSeats ?? 0),
          discountCode: params.discountCode,
        });
        window.location.replace(response.sessionUrl);
      } catch (error) {
        toast({
          title: 'Error',
          description: 'Failed to process payment',
          variant: 'destructive',
        });
        navigate({ to: '/' });
      }
    };

    if (orgId) {
      processPayment();
    }
  }, [orgId]);

  return <Loading2 />;
};

export default PaymentHome;
