import { cn } from '@fluency/ui/lib/utils';
import { Avatar, AvatarFallback } from '../ui/avatar';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '../ui/tooltip';
import { Skeleton } from '../ui/skeleton';
import AssignmentStatus from './AssignmentStatus';
import { useState } from 'react';

type Size = 'xs' | 'sm';

interface UserAvatarProps {
  name?: string;
  className?: string;
  size?: Size;
}

export const sizeStyles = {
  xs: {
    avatar: 'h-6 w-6',
    text: 'text-xs',
    ring: 'ring-1',
    spacing: '-space-x-2',
  },
  sm: {
    avatar: 'h-10 w-10',
    text: 'text-base',
    ring: 'ring-1',
    spacing: '-space-x-[10px]',
  },
};

const UserAvatar = (props: UserAvatarProps) => {
  const { name = '', className = '', size = 'xs' } = props;
  const initials = name
    .split(' ')
    .map((n) => n[0])
    .join('')
    .toUpperCase()
    .slice(0, 2);

  return (
    <Avatar className={cn(sizeStyles[size].avatar, className)}>
      <AvatarFallback
        className={cn('bg-stone-100 text-stone-600', sizeStyles[size].text)}
      >
        {initials}
      </AvatarFallback>
    </Avatar>
  );
};

interface MultipleAvatarsProps {
  data: { name: string; status: string }[];
  showNames?: boolean;
  maxAvatars?: number;
  size?: 'xs' | 'sm';
  loading?: boolean;
  containerClassName?: string;
}

const MultipleAvatars = (props: MultipleAvatarsProps) => {
  const {
    data,
    showNames = true,
    maxAvatars = 3,
    size = 'xs',
    loading = false,
    containerClassName = '',
  } = props;

  const [isOpen, setIsOpen] = useState(false);

  if (loading) {
    return (
      <div
        className={cn(
          'flex items-center',
          sizeStyles[size].spacing,
          containerClassName
        )}
      >
        {[...Array(maxAvatars)].map((_, i) => (
          <Skeleton
            key={i}
            className={cn(
              sizeStyles[size].avatar,
              'rounded-full',
              'bg-stone-100'
            )}
          />
        ))}
      </div>
    );
  }

  if (!data.length) return null;

  const tooltipContent = (
    <div className="space-y-2">
      <div className="space-y-2 mb-4">
        <h4 className="text-sm font-medium leading-none">{'Assignments'}</h4>
        <p className="text-xs text-muted-foreground">
          {'This document is assigned to the following users'}
        </p>
      </div>
      {data.map((d, i) => (
        <div key={i} className="flex items-center flex-row gap-2">
          <UserAvatar name={d.name} size={size} />
          <span
            className={cn(sizeStyles[size].text, 'text-stone-600 truncate')}
          >
            {d.name}
          </span>
          <div className="ml-2 flex-1 flex flex-row justify-end">
            <AssignmentStatus status={d.status} />
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <TooltipProvider>
      <Tooltip open={isOpen}>
        <TooltipTrigger className="flex items-center w-full" asChild>
          <div>
            {data.length === 1 ? (
              <div className="flex items-center gap-1 w-full min-w-0">
                <span
                  onMouseEnter={() => setIsOpen(true)}
                  onMouseLeave={() => setIsOpen(false)}
                >
                  <UserAvatar name={data[0]?.name} size={size} />
                </span>
                {showNames && (
                  <span
                    className={cn(
                      sizeStyles[size].text,
                      'text-stone-600 truncate'
                    )}
                  >
                    {data[0]?.name}
                  </span>
                )}
              </div>
            ) : (
              <div
                className="flex items-center"
                onMouseEnter={() => setIsOpen(true)}
                onMouseLeave={() => setIsOpen(false)}
              >
                <div className={cn('flex', sizeStyles[size].spacing)}>
                  {data.slice(0, maxAvatars).map((user, i) => (
                    <UserAvatar
                      key={i}
                      name={user.name}
                      size={size}
                      className={cn(
                        sizeStyles[size].ring,
                        'ring-white',
                        i !== 0 && '-ml-2'
                      )}
                    />
                  ))}
                </div>
                {data.length > maxAvatars && (
                  <span
                    className={cn(
                      'ml-1',
                      sizeStyles[size].text,
                      'text-stone-600'
                    )}
                  >
                    +{data.length - maxAvatars}
                  </span>
                )}
              </div>
            )}
          </div>
        </TooltipTrigger>
        <TooltipContent
          side="bottom"
          align="start"
          className="p-4 w-fit max-w-80 min-w-0 bg-white border shadow-md"
        >
          {tooltipContent}
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

export default MultipleAvatars;
