import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import { useMutation } from '@tanstack/react-query';

const useClearCacheAdmin = (params?: { orgId?: string }) => {
  const apiBaseUrl = import.meta.env.VITE_SERVER_API_URL;
  const { accessToken } = useAuth();

  const getHeaders = () => {
    const headers = new Headers();
    headers.append('Authorization', `Bearer ${accessToken}`);
    headers.append('Content-Type', 'application/json');
    return headers;
  };

  const useClearSubscriptionCache = useMutation({
    mutationFn: async () => {
      const url = `${apiBaseUrl}/cache/clearSubscription${
        params?.orgId ? `?orgId=${params.orgId}` : ''
      }`;

      const response = await fetch(url, {
        method: 'DELETE',
        headers: getHeaders(),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(
          'Failed to clear subscription cache: ' + errorData.message
        );
      }

      return response.json();
    },
  });

  return {
    clearSubscriptionCache: useClearSubscriptionCache.mutateAsync,
    isLoading: useClearSubscriptionCache.isPending,
  };
};

export default useClearCacheAdmin;
