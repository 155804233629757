import { RecentItem } from '../types/recent-items';
import {
  formatDistanceToNow,
  format,
  isToday,
  isYesterday,
  isThisYear,
} from 'date-fns';

export const sortByDate = (items: RecentItem[]): RecentItem[] => {
  return [...items].sort((a, b) => {
    const dateA = new Date(a.type === 'document' ? a.createdDate : a.createdAt);
    const dateB = new Date(b.type === 'document' ? b.createdDate : b.createdAt);
    return dateB.getTime() - dateA.getTime();
  });
};

export function formatDate(date: string | Date): string {
  const dateObj = typeof date === 'string' ? new Date(date) : date;

  // Handle invalid dates
  if (isNaN(dateObj.getTime())) {
    return 'Invalid date';
  }

  // Today
  if (isToday(dateObj)) {
    return 'Today';
  }

  // Yesterday
  if (isYesterday(dateObj)) {
    return 'Yesterday';
  }

  // This year
  if (isThisYear(dateObj)) {
    return formatDistanceToNow(dateObj, { addSuffix: true });
  }

  // Default format for older dates
  return format(dateObj, 'MMM d, yyyy');
}
