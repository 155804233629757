import { cn } from '@fluency/ui/lib/utils';
import { useState, useCallback, useRef, useEffect } from 'react';
import React from 'react';

interface TextDimensions {
  width: number;
  height: number;
}

interface UseAutoResizeTextAreaProps {
  text: string;
  setValue: (text: string) => void;
  minHeight?: number;
  lineHeight?: number;
  onDimensionsChange?: (dimensions: TextDimensions) => void;
}

interface AutoResizeTextAreaProps {
  text: string;
  placeholder?: string;
  onChange: (text: string) => void;
  minHeight?: number;
  lineHeight?: number;
  readOnly?: boolean;
  className?: string;
  onDimensionsChange?: (dimensions: TextDimensions) => void;
}

export const useAutoResizeTextArea = ({
  text,
  setValue,
  minHeight = 24,
  lineHeight = 1.2,
  onDimensionsChange,
}: UseAutoResizeTextAreaProps) => {
  const [dimensions, setDimensions] = useState<TextDimensions>({
    width: 0,
    height: minHeight,
  });

  const textRef = useRef<HTMLTextAreaElement>(null);
  const measureRef = useRef<HTMLSpanElement>(null);

  const updateDimensions = useCallback(() => {
    setValue(text);
    if (textRef.current && measureRef.current) {
      // Reset textarea to base state
      textRef.current.style.height = '0';
      textRef.current.style.minHeight = '0';
      textRef.current.style.padding = '0';
      textRef.current.style.lineHeight = `${lineHeight}`;

      // Split text into lines
      const lines = text.split('\n');
      let maxWidth = 0;

      // Update measurement span for exact height
      measureRef.current.innerHTML = lines
        .map((line) => line || '&nbsp;')
        .join('<br>');
      const textHeight = Math.max(measureRef.current.offsetHeight, minHeight);

      // Measure each line width
      lines.forEach((line) => {
        measureRef.current!.textContent = line || ' ';
        const lineWidth = measureRef.current!.offsetWidth;
        maxWidth = Math.max(maxWidth, lineWidth);
      });

      const newDimensions = {
        width: maxWidth,
        height: textHeight,
      };

      setDimensions(newDimensions);
      onDimensionsChange?.(newDimensions);

      // Set final textarea height
      textRef.current.style.height = `${textHeight}px`;
    }
  }, [text, minHeight, lineHeight, onDimensionsChange]);

  useEffect(() => {
    updateDimensions();
  }, [text]);

  return {
    dimensions,
    textRef,
    measureRef,
  };
};

/**
 * An auto-resizing textarea component that adjusts its height based on the content.
 * @param text The text content of the textarea.
 * @param onChange The callback function to handle text changes.
 * @param minHeight The minimum height of the textarea.
 * @param lineHeight The line height of the textarea.
 * @param readOnly Whether the textarea is read-only.
 * @param className The class name of the
 * @param onDimensionsChange The callback function to handle dimensions changes.
 * - width: The new width of the textarea.
 * - height: The new height of the textarea.
 * @returns The auto-resizing textarea component.
 */
export const AutoResizeTextArea: React.FC<AutoResizeTextAreaProps> = ({
  text,
  placeholder = 'Enter text...',
  onChange,
  minHeight = 24,
  lineHeight = 1.2,
  readOnly = false,
  className,
  onDimensionsChange,
}) => {
  const [value, setValue] = useState(text);
  const { textRef, measureRef } = useAutoResizeTextArea({
    text: value,
    setValue,
    minHeight,
    lineHeight,
    onDimensionsChange,
  });

  return (
    <>
      {/* Hidden measurement span */}
      <span
        ref={measureRef}
        className={cn(`absolute invisible`, className)}
        style={{
          fontFamily: 'inherit',
          whiteSpace: 'pre',
          lineHeight,
        }}
      />

      {/* Text content */}
      <textarea
        ref={textRef}
        placeholder={placeholder}
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        readOnly={readOnly}
        className={cn(
          'inline-block whitespace-pre text-center',
          'bg-transparent outline-none resize-none',
          'overflow-hidden w-full h-full flex items-center justify-center',
          className
        )}
        style={{
          lineHeight,
        }}
      />
    </>
  );
};
