import { useNavigate } from '@tanstack/react-router';
import { ArrowLeft } from 'lucide-react';
import { Button } from '@fluency/ui/components/ui/button';
import logo from '@fluency/ui/assets/icon-blue.svg';
import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import { Logger } from '@fluency/ui/features/Logger';
import { useClearSubscriptionCache } from '@fluency/ui/hooks/cache/useClearSubscriptionCache';
import { useCallback } from 'react';

const NoSubscriptionMessage = () => {
  const navigate = useNavigate();
  const { orgId, subscription } = useAuth();
  const clearCache = useClearSubscriptionCache();

  const handleLogout = async () => {
    navigate({ to: '/logout', replace: true });
  };

  const handleRefresh = useCallback(async () => {
    try {
      if (!orgId) {
        Logger.warn('No organisation ID found for cache clearing');
        return;
      }

      await clearCache.mutateAsync(undefined, {
        onSuccess: () => {
          Logger.log('Successfully cleared subscription cache');
          navigate({ to: '/home', replace: true });
        },
        onError: (error) => {
          Logger.error('Failed to clear cache:', error);
          // Still navigate even if cache clear fails
          navigate({ to: '/home', replace: true });
        },
      });
    } catch (error) {
      Logger.error('Error during refresh:', error);
      navigate({ to: '/home', replace: true });
    }
  }, [clearCache, navigate, orgId]);

  const isSubscriptionRoute = window.location.pathname === '/subscription';

  Logger.log('OrgID: ', orgId);
  Logger.log('subscription: ', subscription);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen w-full bg-white">
      <img src={logo} className="h-14 w-14 mb-6" alt="Fluency Logo" />
      <div className="border rounded-lg p-8 max-w-md w-full">
        <h1 className="text-2xl font-semibold mb-4">
          {orgId ? 'No Subscription Found' : 'No Organisation Found'}
        </h1>
        <p className="text-gray-600 mb-6">
          {orgId
            ? 'Contact your administrator to activate your subscription. Need help? Try refreshing or signing in with a different account.'
            : 'You are not currently part of any organisation. Please contact your administrator or sign in with a different account.'}
        </p>
        <div className="space-y-4">
          {!isSubscriptionRoute && orgId && (
            <Button
              onClick={() => navigate({ to: '/subscription', replace: true })}
              className="w-full text-md"
              variant="outline"
            >
              View Subscription Options
            </Button>
          )}
          <Button
            onClick={handleRefresh}
            className="w-full text-md"
            variant="secondary"
            disabled={clearCache.isPending || !orgId}
          >
            {clearCache.isPending ? 'Refreshing...' : 'Refresh Subscription'}
          </Button>
        </div>
      </div>
      <Button
        variant="ghost"
        className="mt-6 flex items-center text-sm text-gray-600"
        onClick={handleLogout}
      >
        <ArrowLeft className="w-4 h-4 mr-1" /> Sign in with a different account
      </Button>
    </div>
  );
};

export default NoSubscriptionMessage;
