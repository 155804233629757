import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from '@fluency/ui/components/ui/card';
import {
  Alert,
  AlertDescription,
  AlertTitle,
} from '@fluency/ui/components/ui/alert';
import { AlertTriangle } from 'lucide-react';
import TransferOwnership from './TransferOwnership';
import DeleteOrganisation from './DeleteOrganisation';
import { withPermissionGate } from '@fluency/ui/providers/permissions/PermissionGateProvider';
import ChangeOrgName from './ChangeOrgName';
import { Separator } from '@fluency/ui/components/ui/separator';

export default withPermissionGate(AdvancedSettings, ['org:update']);

interface AdvancedSettingsProps {
  orgId?: string;
}

function AdvancedSettings({ orgId }: AdvancedSettingsProps) {
  return (
    <div className="space-y-6">
      <div>
        <h2 className="text-lg font-medium mb-1">Danger Zone</h2>
        <p className="text-sm text-muted-foreground">
          Manage critical organisation settings and operations.
        </p>
      </div>

      <Alert variant="destructive" className="border-red-600/50">
        <AlertTriangle className="h-4 w-4" />
        <AlertTitle className="font-medium">Caution Required</AlertTitle>
        <AlertDescription className="mt-1">
          These actions can have serious consequences and will affect all users
          within the organisation. Please proceed with careful consideration.
        </AlertDescription>
      </Alert>

      <Card className="border">
        <CardHeader className="pb-3">
          <CardTitle className="text-base">Organisation Management</CardTitle>
        </CardHeader>
        <CardContent className="space-y-6">
          <div className="space-y-6">
            <ChangeOrgName orgId={orgId} />
            <Separator />
            <TransferOwnership orgId={orgId} />
            <Separator />
            <DeleteOrganisation orgId={orgId} />
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
