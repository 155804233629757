import { Trash2, Archive, Loader2 } from 'lucide-react';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from '@fluency/ui/components/ui/dropdown-menu';
import { Button } from '@fluency/ui/components/ui/button';
import useClearCacheAdmin from '../../hooks/useClearCacheAdmin';
import { toast } from '@fluency/ui/components/ui/use-toast';

interface ClearCacheProps {
  orgId?: string;
}

const ClearCache = ({ orgId }: ClearCacheProps) => {
  const { clearSubscriptionCache, isLoading } = useClearCacheAdmin({ orgId });

  const clearSubscriptionCacheHandler = async () => {
    try {
      await clearSubscriptionCache();
      toast({
        title: 'Success',
        description: 'Cache cleared successfully',
      });
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to clear cache',
        variant: 'destructive',
      });
    } finally {
      window.location.reload();
    }
  };

  const buttonContent = isLoading ? (
    <Loader2 className="w-4 h-4 animate-spin" />
  ) : (
    <>
      <Trash2 className="w-4 h-4" />
      Clear Cache
    </>
  );

  // If orgId is provided, return just the button
  if (orgId) {
    return (
      <Button
        className="w-full inline-flex items-center gap-2 px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-colors duration-200"
        disabled={isLoading}
        onClick={clearSubscriptionCacheHandler}
      >
        {buttonContent}
      </Button>
    );
  }

  // If no orgId, return the dropdown menu
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          className="w-full inline-flex items-center gap-2 px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-colors duration-200"
          disabled={isLoading}
        >
          {buttonContent}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56">
        <DropdownMenuLabel>Choose Delete Action</DropdownMenuLabel>
        <DropdownMenuItem
          onClick={clearSubscriptionCacheHandler}
          className="cursor-pointer gap-2"
        >
          <Archive className="h-4 w-4" />
          <span>All Subscription Cache</span>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default ClearCache;
