import {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
  memo,
} from 'react';
import { useGetDocumentData } from '../hooks';
import InsertStep from './InsertStep';
import { useStepManagement } from '../hooks/useStepManagement';
import { StepContent } from './StepContent';
import { getStepDetails } from '../util/getStepDetails';
import type { Step } from '../../../hooks/documentation/types/master';
import ReplaceImageDialog from './screenshot/components/ReplaceImageDialog';
import { toast } from '@fluency/ui/components/ui/use-toast';

interface StepRecordProps {
  steps: Step[];
  isEditMode: boolean;
  stepRefs: React.MutableRefObject<{ [key: string]: HTMLElement }>;
  docId: string;
}

const StepRecord = memo(function StepRecord({
  steps,
  isEditMode,
  stepRefs,
  docId,
}: StepRecordProps) {
  const { data } = useGetDocumentData(docId);
  const isLatestVersion = data?.documentation.versionInfo.isLatestVersion;
  const [dialogState, setDialogState] = useState({
    showDialog: false,
    droppedFile: null as File | null,
    targetStep: null as Step | null,
    isAddingNewImage: false,
  });
  const [dragState, setDragState] = useState<string | null>(null);
  const hoveredState = useRef<string | null>(null);

  // Create a Map for faster lookups of step elements
  const stepElementsMap = useRef(new Map<HTMLElement, string>());

  // Function to get stepId from any element within a step
  const getStepIdFromElement = useCallback(
    (element: HTMLElement | null): string | null => {
      while (element) {
        // Check if this element has a step-id attribute
        const stepId = element.getAttribute('step-id');
        if (stepId) return stepId;

        // Check if this element is in our Map
        const mappedStepId = stepElementsMap.current.get(element);
        if (mappedStepId) return mappedStepId;

        // Move up to parent element
        element = element.parentElement;
      }
      return null;
    },
    []
  );

  // Update step elements map when steps change
  useEffect(() => {
    stepElementsMap.current.clear();
    Object.entries(stepRefs.current).forEach(([stepId, element]) => {
      if (element) {
        stepElementsMap.current.set(element, stepId);
      }
    });
  }, [steps]);

  const {
    editStepDescription,
    editingId,
    filteredSteps,
    handleEditStepDescription,
    handleUpdateStepDescription,
    handleSaveStepDescription,
    handleDeleteStep,
  } = useStepManagement(docId, steps);

  // Set up intersection observer
  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '-20% 0px -60% 0px',
      threshold: 0,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const stepId = entry.target.getAttribute('data-step-id');
          if (stepId) {
            window.dispatchEvent(
              new CustomEvent('stepInView', { detail: { stepId } })
            );
          }
        }
      });
    }, options);

    Object.entries(stepRefs.current).forEach(([stepId, element]) => {
      if (element) {
        element.setAttribute('data-step-id', stepId);
        observer.observe(element);
      }
    });

    return () => observer.disconnect();
  }, []);

  // Listen to the paste event and detect which step the user is pasting into
  useEffect(() => {
    const handlePaste = (e: ClipboardEvent) => {
      if (!isEditMode) return;

      // Check if any dialog is currently open by looking for [data-state="open"]
      const hasOpenDialog = document.querySelector(
        '[role="dialog"][data-state="open"]'
      );
      if (hasOpenDialog) return;

      const file = e.clipboardData?.files[0];
      if (file) {
        if (!file.type.startsWith('image/')) {
          toast({
            title: 'Invalid file type',
            description: 'Please paste an image file.',
            variant: 'destructive',
          });
          return;
        }
        const targetStep = steps.find(
          (step) => step.stepId === hoveredState.current
        );
        const hasExistingImage = targetStep?.screenshots?.length ?? 0 > 0;
        if (file && targetStep) {
          setDialogState({
            showDialog: true,
            droppedFile: file,
            targetStep: targetStep,
            isAddingNewImage: !hasExistingImage,
          });
        }
      }
    };
    window.addEventListener('paste', handlePaste);
    return () => window.removeEventListener('paste', handlePaste);
  }, [isEditMode, steps]);

  const handleDragOver = useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      if (!isEditMode) return;
      e.preventDefault();

      const stepId = getStepIdFromElement(e.target as HTMLElement);
      if (stepId && dragState !== stepId) {
        requestAnimationFrame(() => {
          setDragState(stepId);
        });
      }
    },
    [isEditMode, dragState, getStepIdFromElement]
  );

  const handleDragLeave = useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      if (!isEditMode) return;
      e.preventDefault();

      const relatedTarget = e.relatedTarget as HTMLElement;
      const currentStepId = getStepIdFromElement(relatedTarget);

      if (!currentStepId || currentStepId !== dragState) {
        requestAnimationFrame(() => {
          setDragState(null);
        });
      }
    },
    [isEditMode, dragState, getStepIdFromElement]
  );

  const handleMouseEnter = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      if (!isEditMode) return;
      e.preventDefault();

      const stepId = getStepIdFromElement(e.target as HTMLElement);
      if (stepId && hoveredState.current !== stepId) {
        requestAnimationFrame(() => {
          hoveredState.current = stepId;
        });
      }
    },
    [isEditMode, getStepIdFromElement]
  );

  const handleMouseLeave = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      if (!isEditMode) return;
      e.preventDefault();

      const relatedTarget = e.relatedTarget as HTMLElement;
      const currentStepId = getStepIdFromElement(relatedTarget);

      if (!currentStepId || currentStepId !== hoveredState.current) {
        requestAnimationFrame(() => {
          hoveredState.current = null;
        });
      }
    },
    [isEditMode, getStepIdFromElement]
  );

  const handleDrop = useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      if (!isEditMode) return;
      e.preventDefault();

      const stepId = getStepIdFromElement(e.target as HTMLElement);
      if (!stepId) return;

      const file = e.dataTransfer.files[0];
      if (file) {
        if (!file.type.startsWith('image/')) {
          toast({
            title: 'Invalid file type',
            description: 'Please drop an image file.',
            variant: 'destructive',
          });
          setDragState(null);
          return;
        }

        const targetStep = steps.find((step) => step.stepId === stepId);
        const hasExistingImage = targetStep?.screenshots?.length ?? 0 > 0;

        if (file && targetStep) {
          setDialogState({
            showDialog: true,
            droppedFile: file,
            targetStep: targetStep,
            isAddingNewImage: !hasExistingImage,
          });
        }
      }

      setDragState(null);
    },
    [isEditMode, steps]
  );

  let stepNumberCounter = 0;

  return (
    <div
      className="pb-12 w-full"
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <table className="w-full whitespace-nowrap text-left text-sm leading-6">
        <tbody>
          {isLatestVersion && isEditMode && (
            <tr className="relative">
              <td colSpan={100} className="text-center">
                <div className="h-10"></div>
                <InsertStep docId={docId} stepBeforeId={null} />
              </td>
            </tr>
          )}
          {filteredSteps.map((step) => {
            if (step.stepType === 'STEP') {
              stepNumberCounter++;
            }
            const enhancedStep = getStepDetails({
              step,
              stepNumberCounter,
            });
            return (
              <Fragment key={step.stepId}>
                <StepContent
                  ref={(el) => {
                    if (el) {
                      stepRefs.current[step.stepId] = el;
                      stepElementsMap.current.set(el, step.stepId);
                    }
                  }}
                  step={enhancedStep}
                  isLatestVersion={isLatestVersion || false}
                  docId={docId}
                  editingId={editingId}
                  editStepDescription={editStepDescription}
                  handleSaveStepDescription={handleSaveStepDescription}
                  handleEditStepDescription={handleEditStepDescription}
                  handleUpdateStepDescription={handleUpdateStepDescription}
                  handleDeleteStep={handleDeleteStep}
                  filteredSteps={filteredSteps}
                  isEditMode={isEditMode}
                  isHighlighted={dragState === step.stepId}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                />
                {isLatestVersion && isEditMode && (
                  <tr className="relative">
                    <td colSpan={100} className="text-center">
                      <InsertStep docId={docId} stepBeforeId={step.stepId} />
                    </td>
                  </tr>
                )}
              </Fragment>
            );
          })}
        </tbody>
      </table>

      <ReplaceImageDialog
        isOpen={dialogState.showDialog}
        onClose={() => {
          setDialogState({
            showDialog: false,
            droppedFile: null,
            targetStep: null,
            isAddingNewImage: false,
          });
        }}
        file={dialogState.droppedFile}
        step={dialogState.targetStep}
        documentId={docId}
        mode={dialogState.isAddingNewImage ? 'add' : 'replace'}
      />
    </div>
  );
});

export default StepRecord;
