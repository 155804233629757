// contexts/RecordingContext.tsx
import React, { createContext, useContext, ReactNode, useState } from 'react';

type RecordingType = 'insert' | 'overwrite' | undefined;

interface RecordingContextType {
  isRecording: boolean;
  setIsRecording: (value: boolean) => void;
  isPaused: boolean;
  setIsPaused: (value: boolean) => void;
  isLoading: boolean;
  setIsLoading: (value: boolean) => void;
  isStartingRecord: boolean;
  setIsStartingRecord: (value: boolean) => void;
  currentDocumentId: string | null;
  setCurrentDocumentId: (value: string | null) => void;
  recordingType: RecordingType;
  setRecordingType: (value: RecordingType) => void;
  stepBeforeId: string | null;
  setStepBeforeId: (value: string | null) => void;
  error: boolean;
  setError: (value: boolean) => void;
}

const RecordingContext = createContext<RecordingContextType | undefined>(
  undefined
);

interface RecordingProviderProps {
  children: ReactNode;
}

export const RecordingProvider: React.FC<RecordingProviderProps> = ({
  children,
}) => {
  const [isRecording, setIsRecording] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isStartingRecord, setIsStartingRecord] = useState(false);
  const [currentDocumentId, setCurrentDocumentId] = useState<string | null>(
    null
  );
  const [recordingType, setRecordingType] = useState<RecordingType>(undefined);
  const [stepBeforeId, setStepBeforeId] = useState<string | null>(null);
  const [error, setError] = useState(false);

  return (
    <RecordingContext.Provider
      value={{
        isRecording,
        setIsRecording,
        isPaused,
        setIsPaused,
        isLoading,
        setIsLoading,
        isStartingRecord,
        setIsStartingRecord,
        currentDocumentId,
        setCurrentDocumentId,
        recordingType,
        setRecordingType,
        stepBeforeId,
        setStepBeforeId,
        error,
        setError,
      }}
    >
      {children}
    </RecordingContext.Provider>
  );
};

export const useRecording = () => {
  const context = useContext(RecordingContext);
  if (context === undefined) {
    throw new Error('useRecording must be used within a RecordingProvider');
  }
  return context;
};
