import { capitalise } from '@fluency/ui/lib';
import { cn } from '@fluency/ui/lib/utils';
import { AlertCircle, CheckCircle2, Clock, CircleDashed } from 'lucide-react';

const AssignmentStatus = ({
  status,
  className = '',
}: {
  status?: string;
  className?: string;
}) => {
  // Normalize the status
  const normalizedStatus =
    !status || status.trim() === '' ? 'UNASSIGNED' : status;

  return (
    <span
      className={cn(
        'inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium',
        {
          'bg-fluency-50 text-fluency-600': normalizedStatus === 'COMPLETED',
          'bg-red-50 text-red-600': normalizedStatus === 'OVERDUE',
          'bg-amber-50 text-amber-600': normalizedStatus === 'PENDING',
          'bg-gray-50 text-gray-600': normalizedStatus === 'UNASSIGNED',
        },
        className
      )}
    >
      {normalizedStatus === 'COMPLETED' && (
        <CheckCircle2 className="w-3 h-3 mr-1" />
      )}
      {normalizedStatus === 'OVERDUE' && (
        <AlertCircle className="w-3 h-3 mr-1" />
      )}
      {normalizedStatus === 'PENDING' && <Clock className="w-3 h-3 mr-1" />}
      {normalizedStatus === 'UNASSIGNED' && (
        <CircleDashed className="w-3 h-3 mr-1" />
      )}
      {capitalise(normalizedStatus)}
    </span>
  );
};

export default AssignmentStatus;
