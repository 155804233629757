import { useShallow, useWorkflowStore } from '../store';
import { useCallback } from 'react';
import { useUpdateWorkflow } from '@fluency/ui/hooks/workflows/useUpdateWorkflow';
import { Node } from 'reactflow';

interface WorkflowHandlersProps {
  workflowId: string;
}

const convertToWorkflowNodes = (nodes: Node[]): Node[] => {
  return nodes.map((node) => {
    // Filter out unnecessary properties as the style property is only needed for client-side rendering
    // Swimlanes node has additional properties only for client-side rendering (resizing, style)
    const { style, resizing, ...cleanedNode } = node;

    // Only store the document Id for the document nodes
    // To make the documentNode always sync with the fetched document data
    if (cleanedNode.type === 'documentNode') {
      return {
        ...cleanedNode,
        data: {
          documentId: cleanedNode.data.documentId,
        },
      };
    }

    return {
      ...cleanedNode,
    };
  });
};

const useWorkflowHelpers = ({ workflowId }: WorkflowHandlersProps) => {
  const { getDocument, getNode } = useWorkflowStore(
    useShallow((state) => ({
      getDocument: state.getDocument,
      getNode: state.getNode,
    }))
  );

  // Mutation
  const { mutateAsync } = useUpdateWorkflow();

  // Helpers Function
  const updateWorkflow = useCallback(
    async (workflow: any) => {
      await mutateAsync({
        id: workflowId,
        ...workflow,
        ...(workflow.nodes && {
          nodes: convertToWorkflowNodes(workflow.nodes),
        }),
        ...(workflow.edges && {
          edges: workflow.edges,
        }),
      });
    },
    [workflowId, mutateAsync]
  );

  // Sync document node, with the document data
  const syncDocNode = useCallback(
    (node: Node) => {
      if (node.type !== 'documentNode') return node;

      // Sync the document nodes with the documents cache
      const matchedDoc = getDocument(node.data.documentId);
      const matchedNode = getNode(node.id);

      let updatedNode = node;

      // Syncing the current data with the old node that is still on the canvas
      if (matchedNode) {
        updatedNode = {
          ...matchedNode,
          data: {
            ...node.data,
          },
        };
      }

      // If matching doucment is found, update the node with the document data
      if (matchedDoc) {
        updatedNode = {
          ...updatedNode,
          data: {
            ...node.data,
            documentName: matchedDoc.documentationName,
            description: matchedDoc.description,
            owner: `${matchedDoc.userInfo[0]?.firstName ?? ''} ${
              matchedDoc.userInfo[0]?.lastName ?? ''
            }`.trim(),
          },
        };
      }

      return updatedNode;
    },
    [getDocument, getNode]
  );

  return {
    updateWorkflow,
    syncDocNode,
  };
};

export default useWorkflowHelpers;
