import { Input } from '@fluency/ui/components/ui/input';
import { Label } from '@fluency/ui/components/ui/label';
import ProcessOwner from './ProcessOwner';
import { UnifiedDocumentData } from '../../types/docSummaryType';
import { formatDate } from '../../util/formatData';
import useProcessOwner from '@fluency/ui/hooks/documentation/useProcessOwner';
import { ScrollArea } from '@fluency/ui/components/ui/scroll-area';

interface EditModeMetadataProps {
  documentId: string;
  data: UnifiedDocumentData | undefined;
  isLoading: {
    users?: boolean;
    owner?: boolean;
    data?: boolean;
  };
}

const EditModeMetadata = ({
  documentId,
  data,
  isLoading,
}: EditModeMetadataProps) => {
  const { removeOwner, updateOwner } = useProcessOwner({
    documentId: documentId,
  });

  const onOwnerChange = (id: string | null) => {
    if (id === null) {
      removeOwner();
    } else {
      updateOwner({ processOwner: id });
    }
  };

  return (
    <div className="w-full overflow-y-auto">
      <div className="max-h-[min(700px,70vh)]">
        <ScrollArea className="h-full">
          <div className="flex flex-col gap-4">
            <div className="grid gap-4">
              <div className="space-y-2">
                <Label htmlFor="process-owner">Assign Process Owner</Label>
                <ProcessOwner
                  initialId={data?.processOwner?.id ?? ''}
                  onChange={onOwnerChange}
                  users={data?.users ?? []}
                  isLoading={isLoading.users || isLoading.owner}
                  className="w-full"
                />
              </div>
            </div>

            <div className="grid gap-4">
              <div className="grid grid-cols-2 gap-4">
                <div className="space-y-2">
                  <Label htmlFor="doc-version">Version</Label>
                  <Input
                    id="doc-version"
                    value={data?.documentation?.versionInfo.version ?? ''}
                    disabled
                    className="bg-muted"
                  />
                </div>
                <div className="space-y-2">
                  <Label htmlFor="doc-status">Status</Label>
                  <Input
                    id="doc-status"
                    value={
                      data?.documentation?.versionInfo.isLatestVersion
                        ? 'Latest version'
                        : 'Superseded version'
                    }
                    disabled
                    className="bg-muted"
                  />
                </div>

                <div className="space-y-2">
                  <Label htmlFor="created-date">Created Date</Label>
                  <Input
                    id="created-date"
                    value={
                      data?.documentation?.createdDate
                        ? formatDate(data.documentation.createdDate)
                        : ''
                    }
                    disabled
                    className="bg-muted"
                  />
                </div>
                <div className="space-y-2">
                  <Label htmlFor="total-steps">Total Steps</Label>
                  <Input
                    id="total-steps"
                    value={data?.documentation?.totalSteps.toString() ?? '0'}
                    disabled
                    className="bg-muted"
                  />
                </div>

                <div className="space-y-2">
                  <Label htmlFor="created-by">Created By</Label>
                  <Input
                    id="created-by"
                    value={
                      data?.documentation?.userInfo[0]?.firstName &&
                      data?.documentation.userInfo[0]?.lastName
                        ? `${data.documentation.userInfo[0].firstName} ${data.documentation.userInfo[0].lastName}`
                        : 'Not specified'
                    }
                    disabled
                    className="bg-muted"
                  />
                </div>
              </div>
            </div>
          </div>
        </ScrollArea>
      </div>
    </div>
  );
};

export default EditModeMetadata;
