import { DashboardShell } from '@fluency/ui/components';
import InProgress from './components/InProgress';
import ViewDocument from './ViewDocument';
import { useGetDocumentData } from './hooks';
import { useRecording } from '@fluency/ui/providers/recording/RecordingContext';

interface DocumentViewPageProps {
  id: string;
}

const DocumentViewPage = ({ id }: DocumentViewPageProps) => {
  const { data } = useGetDocumentData(id);
  const { isRecording } = useRecording();

  return (
    <DashboardShell>
      {(data?.documentation.status === 'UPLOADING' ||
        data?.documentation.status === 'MODIFYING') &&
      !isRecording ? (
        <InProgress />
      ) : (
        <ViewDocument id={id} />
      )}
    </DashboardShell>
  );
};

export default DocumentViewPage;
