import { Badge } from '@fluency/ui/components/ui/badge';
import { cn } from '@fluency/ui/lib/utils';
import { LucideIcon } from 'lucide-react';
import React from 'react';

interface MetadataBadgeProps {
  icon: LucideIcon;
  children: React.ReactNode;
  isLoading?: boolean;
  disabled?: boolean;
}

export const MetadataBadge = ({
  icon: Icon,
  children,
  isLoading,
  disabled,
}: MetadataBadgeProps) => {
  if (isLoading) {
    return (
      <Badge
        variant="secondary"
        className="flex items-center animate-pulse w-24 bg-gray-100"
      >
        <Icon className="h-4 w-4 my-0.5 mr-2 text-gray-400" />
        <div className="h-3 w-full rounded bg-gray-200" />
      </Badge>
    );
  }

  return (
    <Badge
      variant="secondary"
      className={cn('flex items-center', disabled && 'opacity-50')}
    >
      <Icon className="h-4 w-4 my-0.5 mr-2" />
      {children}
    </Badge>
  );
};
