import React, { useState, useMemo } from 'react';
import { BPMNElement } from '../../types/workflow';
import DocumentItem from './DocumentItem';
import type { Documentation } from '@fluency/ui/hooks/documentation/types/GetDocs';
import { ScrollArea } from '@fluency/ui/components/ui/scroll-area';
import { Input } from '@fluency/ui/components/ui/input';
import { Button } from '@fluency/ui/components/ui/button';
import { ArrowUpDown } from 'lucide-react';
import {
  StartIcon,
  EndIcon,
  DecisionIcon,
  TextIcon,
  DefaultIcon,
  ActivityIcon,
  SwimlaneIcon,
  DocumentIcon,
} from './BPMNIcons';
import { bpmnElements } from '../../utils/bpmnElements';
import { useShallow, useWorkflowStore } from '../../store';
import useCreateElements from '../../hooks/useCreateElements';

interface SidebarProps {
  reactFlowRef: React.RefObject<HTMLDivElement>;
  isLoading: boolean;
  isError: boolean;
  itemsPerRow?: number;
  rowConfiguration?: number[];
}

const Sidebar = (props: SidebarProps) => {
  const {
    isLoading,
    isError,
    reactFlowRef,
    itemsPerRow = 6,
    rowConfiguration,
  } = props;

  const { documents, workflow } = useWorkflowStore(
    useShallow((state) => ({
      documents: state.documents,
      workflow: state.workflow,
    }))
  );

  const {
    handleDocNodeClick: onDocNodeClick,
    handleElementClick: onElementClick,
    handleEmptyDocClick: onEmptyDocClick,
  } = useCreateElements({
    vaultId: workflow?.vault.id ?? '',
    reactFlowWrapper: reactFlowRef,
  });

  const [searchTerm, setSearchTerm] = useState('');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');

  // Function to split elements into rows based on configuration
  const getElementRows = (elements: BPMNElement[]): BPMNElement[][] => {
    if (rowConfiguration) {
      let currentIndex = 0;
      return rowConfiguration.map((rowCount) => {
        const rowElements = elements.slice(
          currentIndex,
          currentIndex + rowCount
        );
        currentIndex += rowCount;
        return rowElements;
      });
    }

    // Default behavior: split into rows based on itemsPerRow
    const rows: BPMNElement[][] = [];
    for (let i = 0; i < elements.length; i += itemsPerRow) {
      rows.push(elements.slice(i, i + itemsPerRow));
    }
    return rows;
  };

  const elementRows = useMemo(
    () => getElementRows(bpmnElements),
    [itemsPerRow, rowConfiguration]
  );

  const renderElementItem = (element: BPMNElement) => {
    const onDragStart = (
      event: React.DragEvent<HTMLDivElement>,
      element: BPMNElement
    ) => {
      event.dataTransfer.setData('application/reactflow', element.type);
      event.dataTransfer.setData('application/title', element.title);
      event.dataTransfer.setData('application/id', element.id);
      event.dataTransfer.effectAllowed = 'move';
    };

    const getIconComponent = (title: string) => {
      const lowerTitle = title.toLowerCase();
      if (lowerTitle.includes('start')) return StartIcon;
      if (lowerTitle.includes('end')) return EndIcon;
      if (lowerTitle.includes('decision')) return DecisionIcon;
      if (lowerTitle.includes('text')) return TextIcon;
      if (lowerTitle.includes('activity')) return ActivityIcon;
      if (lowerTitle.includes('swimlane')) return SwimlaneIcon;
      if (lowerTitle.includes('document')) return DocumentIcon;
      return DefaultIcon;
    };

    const IconComponent = getIconComponent(element.title);

    const func = {
      onClick: null as any,
      onDragStart: null as any,
    };

    if (element.type === 'documentNode') {
      func.onClick = () => onEmptyDocClick();
      func.onDragStart = handleEmptyDocDragStart;
    } else {
      func.onClick = () => onElementClick(element);
      func.onDragStart = (event: React.DragEvent<HTMLDivElement>) =>
        onDragStart(event, element);
    }

    return (
      <div
        key={element.id}
        className="cursor-pointer mb-2 flex flex-col items-center gap-1 font-semibold text-xs"
        draggable
        onDragStart={func.onDragStart}
        onClick={func.onClick}
      >
        <IconComponent />
      </div>
    );
  };

  // Filter and sort documents
  const filteredAndSortedDocs = useMemo(() => {
    return documents
      .filter((doc: Documentation) =>
        doc.documentationName.toLowerCase().includes(searchTerm.toLowerCase())
      )
      .sort((a: Documentation, b: Documentation) => {
        const comparison = a.documentationName.localeCompare(
          b.documentationName
        );
        return sortOrder === 'asc' ? comparison : -comparison;
      });
  }, [documents, searchTerm, sortOrder]);

  const toggleSortOrder = () => {
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  const handleEmptyDocDragStart = (event: React.DragEvent<HTMLDivElement>) => {
    event.dataTransfer.setData('application/reactflow', 'documentNode');
    event.dataTransfer.effectAllowed = 'move';
  };

  return (
    <div className="pr-4 h-[calc(100vh-390px)]">
      <div className="flex flex-col gap-1 mb-2">
        {elementRows.map((row, rowIndex) => (
          <div key={rowIndex} className="flex justify-between gap-2">
            {row.map(renderElementItem)}
          </div>
        ))}
      </div>
      <p className="font-medium text-sm py-2">Existing Documents:</p>
      <div className="mb-4 flex gap-2">
        <Input
          type="text"
          placeholder="Search documents..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="flex-grow"
        />
        <Button onClick={toggleSortOrder} variant="outline">
          <ArrowUpDown className="mr-2 h-4 w-4" />
          {sortOrder === 'asc' ? 'A-Z' : 'Z-A'}
        </Button>
      </div>
      <ScrollArea className="h-full">
        {/* Loading */}
        {isLoading && (
          <>
            {[...Array(5)].map((_, index) => (
              <div
                key={index}
                className="mb-2 h-10 rounded-lg bg-gray-100 animate-pulse"
              />
            ))}
          </>
        )}
        {/* Error */}
        {isError && (
          <div className="flex justify-center items-center h-[40vh] text-red-500">
            An error occurred while loading documents. Please try again.
          </div>
        )}

        {/* Success */}
        {!isLoading &&
          !isError &&
          filteredAndSortedDocs.map((doc: Documentation) => (
            <div key={doc.documentationId} onClick={() => onDocNodeClick(doc)}>
              <DocumentItem
                key={doc.documentationId}
                doc={doc}
                searchTerm={searchTerm}
              />
            </div>
          ))}
      </ScrollArea>
    </div>
  );
};

export default Sidebar;
