import moment from 'moment';
import {
  FileText,
  Trash2,
  MoreVertical,
  WorkflowIcon,
  ArrowUpRight,
} from 'lucide-react';
import { ResourceType } from '@fluency/ui/hooks/fga/types/fga';
import { Badge } from '@fluency/ui/components/ui/badge';
import { Button } from '@fluency/ui/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@fluency/ui/components/ui/dropdown-menu';
import {
  DocumentationResource,
  ResourceItem,
  WorkflowResource,
} from '../../../hooks/vaults/types/types';
import { usePermissionGate } from '@fluency/ui/providers/permissions/PermissionGateProvider';

interface ResourceItemCardProps {
  item: ResourceItem;
  onView: (item: ResourceItem) => void;
  onDelete: (item: ResourceItem) => void;
  isLocked: boolean;
}

export const ResourceItemCard: React.FC<ResourceItemCardProps> = ({
  item,
  onView,
  onDelete,
  isLocked,
}) => {
  const isDocument = item.type === ResourceType.DOCUMENTATION;
  const resource = item.resource as DocumentationResource | WorkflowResource;

  const name = isDocument
    ? (resource as DocumentationResource).documentationName
    : (resource as WorkflowResource).name;

  const date = resource.updatedAt;

  const description = isDocument
    ? (resource as DocumentationResource).description
    : (resource as WorkflowResource).description;

  const totalSteps = isDocument
    ? (resource as DocumentationResource).totalSteps
    : null;

  const { hasPermissions } = usePermissionGate();
  const canDelete = hasPermissions(['documents:delete']);

  return (
    <div
      className="group relative border rounded-lg bg-background hover:bg-slate-50 transition-colors cursor-pointer px-4 py-3 w-full"
      onClick={() => onView(item)}
    >
      <div className="flex min-w-0 gap-3">
        <div className="flex-shrink-0">
          <div
            className={`w-8 h-8 rounded-lg flex items-center justify-center ${
              isDocument ? 'bg-primary/10' : 'bg-purple-500/10'
            }`}
          >
            {isDocument ? (
              <FileText className="w-4 h-4 text-primary" />
            ) : (
              <WorkflowIcon className="w-4 h-4 text-purple-500" />
            )}
          </div>
        </div>

        <div className="flex-1 min-w-0">
          <div className="flex items-start justify-between gap-2">
            <div className="flex-1 min-w-0 space-y-1">
              <h3 className="font-medium text-sm truncate">
                {name || 'Untitled'}
              </h3>

              {description && (
                <p className="text-sm text-muted-foreground line-clamp-1 max-w-[75%]">
                  {description}
                </p>
              )}

              <div className="flex flex-wrap items-center gap-2 text-xs text-muted-foreground">
                <span className="truncate">
                  Updated {moment(date).format('MMM D, YYYY')}
                </span>
                <div className="flex flex-wrap gap-1.5 items-center">
                  <Badge
                    variant="secondary"
                    className="text-[10px] px-1.5 py-0 h-4"
                  >
                    {isDocument ? 'Document' : 'Process Map'}
                  </Badge>
                  {resource.isPublic && (
                    <Badge className="text-[10px] px-1.5 py-0 h-4">
                      Public
                    </Badge>
                  )}
                  {isDocument &&
                    typeof totalSteps === 'number' &&
                    totalSteps > 0 && (
                      <Badge
                        variant="outline"
                        className="text-[10px] px-1.5 py-0 h-4"
                      >
                        {totalSteps} step{totalSteps > 1 ? 's' : ''}
                      </Badge>
                    )}
                </div>
              </div>
            </div>

            <div className="flex-shrink-0">
              <DropdownMenu>
                <DropdownMenuTrigger
                  asChild
                  onClick={(e) => e.stopPropagation()}
                >
                  <Button
                    variant="ghost"
                    size="sm"
                    className="h-8 w-8 p-0 hover:text-primary"
                  >
                    <MoreVertical className="h-4 w-4" />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end" className="w-32">
                  <DropdownMenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      onView(item);
                    }}
                  >
                    <ArrowUpRight className="mr-2 h-4 w-4 text-primary" />
                    Open
                  </DropdownMenuItem>

                  {canDelete && (
                    <DropdownMenuItem
                      className="text-destructive"
                      disabled={isLocked}
                      onClick={(e) => {
                        e.stopPropagation();
                        onDelete(item);
                      }}
                    >
                      <Trash2 className="mr-2 h-4 w-4" />
                      Delete
                    </DropdownMenuItem>
                  )}
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
