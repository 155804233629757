import moment from 'moment';

export const formatDate = (dateString: string): string => {
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  };
  return new Date(dateString).toLocaleDateString('en-GB', options);
};

export const formatDuration = (duration: number | string): string => {
  return moment(Number(duration) * 1000).format('m [min] s [sec]');
};
