import { Button } from '@fluency/ui/components/ui/button';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@fluency/ui/components/ui/dialog';
import { useDocumentExport } from '@fluency/ui/hooks/documentation-export/useExport';
import { ReloadIcon } from '@radix-ui/react-icons';
import { useNavigate } from '@tanstack/react-router';
import { ExternalLink, FileText } from 'lucide-react';
import { useState } from 'react';
import ConfluenceExport from './ConfluenceExport';
import ConfluenceIcon from '../../Settings/components/Integrations/ConfluenceIcon';
import { useConfluence } from '@fluency/ui/hooks/documentation-export/useConfluence';

interface ExportButtonProps {
  onClick: () => void;
  label: string;
  isExporting: boolean;
  disabled: boolean;
}

const ExportButton: React.FC<ExportButtonProps> = ({
  onClick,
  label,
  isExporting,
  disabled,
}) => (
  <Button
    variant="secondary"
    onClick={onClick}
    className="mb-2 justify-start gap-2"
    disabled={disabled || isExporting}
  >
    {isExporting ? (
      <>
        <ReloadIcon className="h-4 w-4 mr-2 animate-spin" />
        Exporting...
      </>
    ) : (
      <>
        <FileText className="w-5" />
        {label}
      </>
    )}
  </Button>
);

interface ExportModalProps {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
  documentId: string;
}

const ExportModal: React.FC<ExportModalProps> = ({
  isOpen,
  onOpenChange,
  documentId,
}) => {
  const [showConfluenceExport, setShowConfluenceExport] = useState(false);
  const { connectionStatus, isLoadingConnectionStatus } = useConfluence();
  const { exportDocument, isExporting } = useDocumentExport();
  const [exportingType, setExportingType] = useState<'pdf' | 'word' | null>(
    null
  );
  const navigate = useNavigate();

  const handleExport = (exportType: 'pdf' | 'word') => {
    if (isExporting) return;
    setExportingType(exportType);
    exportDocument(documentId, exportType, {
      onSuccess: (data: { url?: string }) => {
        if (data.url) {
          window.open(data.url, '_blank');
        }
        setExportingType(null);
        onOpenChange(false);
      },
      onError: () => {
        setExportingType(null);
      },
    });
  };

  const isAnyExportInProgress = isExporting || exportingType !== null;

  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle className="mb-4">Export</DialogTitle>
          <div className="flex flex-col">
            {showConfluenceExport ? (
              <ConfluenceExport documentId={documentId} />
            ) : (
              <>
                {/* <ExportButton
                  onClick={() => handleExport('pdf')}
                  label="Export to PDF"
                  isExporting={exportingType === 'pdf'}
                  disabled={isAnyExportInProgress}
                /> */}
                <ExportButton
                  onClick={() => handleExport('pdf')}
                  // label="Export to PDF"
                  label="Export to PDF (Under Maintenance)"
                  isExporting={exportingType === 'pdf'}
                  // disabled={isAnyExportInProgress}
                  disabled={true}
                />
                <ExportButton
                  onClick={() => handleExport('word')}
                  label="Export to Word (.docx)"
                  isExporting={exportingType === 'word'}
                  disabled={isAnyExportInProgress}
                />
                {isLoadingConnectionStatus ? (
                  <Button size="sm" disabled className="mb-2">
                    Loading...
                  </Button>
                ) : connectionStatus?.status === 'connected' ? (
                  <Button
                    variant="secondary"
                    onClick={() => setShowConfluenceExport(true)}
                    className="mb-2 justify-start gap-2"
                    disabled={isAnyExportInProgress}
                  >
                    <ConfluenceIcon className="h-5 w-5 text-[#0c66e4]" /> Export
                    to Confluence
                  </Button>
                ) : (
                  <Button
                    variant="outline"
                    onClick={() =>
                      navigate({
                        to: '/settings/$setting',
                        params: { setting: 'integrations' },
                        replace: true,
                      })
                    }
                    className="mb-2 gap-2 justify-start"
                    disabled={isAnyExportInProgress}
                  >
                    <ExternalLink className="w-5" />
                    Add Confluence Integration
                  </Button>
                )}
              </>
            )}
          </div>
        </DialogHeader>

        <DialogFooter className="sm:justify-end">
          {showConfluenceExport && (
            <Button
              onClick={() => setShowConfluenceExport(false)}
              type="button"
              variant="secondary"
            >
              Back
            </Button>
          )}
          <DialogClose asChild>
            <Button
              onClick={() => {
                onOpenChange(false);
                setShowConfluenceExport(false);
              }}
              type="button"
              variant="secondary"
            >
              Close
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default ExportModal;
