import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { toast } from '@fluency/ui/components/ui/use-toast';
import { Logger } from '@fluency/ui/features/Logger';
import useSubscription from '../subscription/useSubscription';

const API_BASE_URL = import.meta.env.VITE_SERVER_API_URL;

interface Invitation {
  id: string;
  email: string;
  state: string;
  acceptedAt: string | null;
  revokedAt: string | null;
  expiresAt: string;
  organizationId: string;
  inviterUserId: string;
  token: string;
  acceptInvitationUrl: string;
  createdAt: string;
  updatedAt: string;
}

interface InvitationsResponse {
  message: string;
  invitations: Invitation[];
  metadata: {
    before: string | null;
    after: string | null;
  };
}

const useOrgInvites = (params?: { organisationId?: string }) => {
  const { accessToken, orgId: organisation } = useAuth();
  const { refetchSubscription } = useSubscription({
    organisationId: params?.organisationId,
  });
  const queryClient = useQueryClient();

  const orgId = params?.organisationId || organisation;

  // Send invite
  const sendInvite = useMutation({
    mutationFn: async ({ email, role }: { email: string; role: string }) => {
      const myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');
      myHeaders.append('Authorization', `Bearer ${accessToken}`);

      const urlencoded = new URLSearchParams();
      urlencoded.append('role', role);
      urlencoded.append('email', email);

      const searchParams = new URLSearchParams();
      if (params && params.organisationId) {
        searchParams.append('orgId', params.organisationId);
      }

      const url = `${API_BASE_URL}/invitation/send${
        params?.organisationId ? `?${searchParams.toString()}` : ''
      }`;

      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow' as RequestRedirect,
      };

      const response = await fetch(url, requestOptions);
      console.log('Response Status:', response.status);
      console.log('Response OK:', response.ok);
      console.log('Response:', response);

      if (!response.ok) {
        throw new Error('Failed to send invite');
      }
      const data = await response.json();
      return data;
    },
    onMutate: async (newInvite) => {
      await queryClient.cancelQueries({ queryKey: ['orgInvites'] });
      queryClient.setQueryData<InvitationsResponse>(['orgInvites'], (old) => {
        if (!old)
          return {
            message: '',
            invitations: [],
            metadata: { before: null, after: null },
          };
        return {
          ...old,
          invitations: [
            ...old.invitations,
            {
              id: 'temp-id-' + Date.now(),
              email: newInvite.email,
              state: 'Pending',
              acceptedAt: null,
              revokedAt: null,
              expiresAt: new Date(
                Date.now() + 7 * 24 * 60 * 60 * 1000
              ).toISOString(),
              organizationId: '',
              inviterUserId: '',
              token: '',
              acceptInvitationUrl: '',
              createdAt: new Date().toISOString(),
              updatedAt: new Date().toISOString(),
            },
          ],
        };
      });
    },
    onSuccess: () => {
      toast({
        title: 'Success',
        description: 'Invite sent successfully.',
        variant: 'default',
      });
    },
    onError: (error) => {
      console.log('here is the error: ', error);
      toast({
        title: 'Error',
        description: 'Invitation failed to send.',
        variant: 'destructive',
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['orgInvites'] });
      refetchSubscription();
    },
  });

  // Get invites
  const getInvites = useQuery<InvitationsResponse, Error>({
    queryKey: ['orgInvites', orgId],
    queryFn: async () => {
      const myHeaders = new Headers();
      myHeaders.append('Authorization', `Bearer ${accessToken}`);

      const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow' as RequestRedirect,
      };

      const searchParams = new URLSearchParams();

      if (params && params.organisationId) {
        searchParams.append('orgId', params.organisationId);
      }

      const url = `${API_BASE_URL}/invitation/list${
        searchParams.toString() ? `?${searchParams.toString()}` : ''
      }`;

      const response = await fetch(url, requestOptions);
      if (!response.ok) {
        throw new Error('Failed to fetch invites');
      }
      return response.json();
    },
  });

  // Handle errors for getInvites
  if (getInvites.error) {
    Logger.error('Failed to fetch invites:', getInvites.error);
    toast({
      title: 'Error',
      description: 'Failed to fetch invites. Please try again.',
      variant: 'destructive',
    });
  }

  const revokeInvite = useMutation({
    mutationFn: async (inviteId: string) => {
      const myHeaders = new Headers();
      myHeaders.append('Authorization', `Bearer ${accessToken}`);

      const requestOptions = {
        method: 'DELETE',
        headers: myHeaders,
        redirect: 'follow' as RequestRedirect,
      };

      const response = await fetch(
        `${API_BASE_URL}/invitation/delete/${inviteId}`,
        requestOptions
      );
      if (!response.ok) {
        throw new Error('Failed to revoke invite');
      }
      return response.text();
    },
    onMutate: async (inviteId) => {
      await queryClient.cancelQueries({ queryKey: ['orgInvites'] });
      const previousInvites = queryClient.getQueryData<InvitationsResponse>([
        'orgInvites',
      ]);
      queryClient.setQueryData<InvitationsResponse>(['orgInvites'], (old) => {
        if (!old)
          return {
            message: '',
            invitations: [],
            metadata: { before: null, after: null },
          };
        return {
          ...old,
          invitations: old.invitations.filter(
            (invite) => invite.id !== inviteId
          ),
        };
      });
      return { previousInvites };
    },
    onSuccess: () => {
      toast({
        title: 'Success',
        description: 'Invite revoked successfully.',
        variant: 'default',
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['orgInvites'] });
      refetchSubscription();
    },
  });

  const transferOwnership = useMutation({
    mutationFn: async (newOwnerMembershipId: string) => {
      const myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');
      myHeaders.append('Authorization', `Bearer ${accessToken}`);

      const urlencoded = new URLSearchParams();
      urlencoded.append('newOwnerMembershipId', newOwnerMembershipId);

      const searchParams = new URLSearchParams();

      if (params && params.organisationId) {
        searchParams.append('orgId', params.organisationId);
      }

      const url = `${API_BASE_URL}/organisation/transferOwnership${
        searchParams.toString() ? `?${searchParams.toString()}` : ''
      }`;

      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow' as RequestRedirect,
      };

      const response = await fetch(url, requestOptions);

      if (!response.ok) {
        console.log('response is not ok');
        const errorData = await response.text();
        throw new Error(errorData || `HTTP error! status: ${response.status}`);
      }

      return response.text();
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['orgUsers', orgId] });
      if (!params?.organisationId) {
        // window.location.reload();
      }
    },
  });

  return {
    sendInvite: sendInvite.mutateAsync,
    sendInvitePending: sendInvite.isPending,
    revokeInvite: revokeInvite.mutate,
    revokeInvitePending: revokeInvite.isPending,
    invites: getInvites.data?.invitations || [],
    isLoadingInvites: getInvites.isLoading,
    refetchInvites: getInvites.refetch,
    transferOwnership: transferOwnership.mutateAsync,
    isTransferringOwnership: transferOwnership.isPending,
  };
};

export default useOrgInvites;
