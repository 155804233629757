// This is not really important to get exposed, because this is just to encrypt the search params in the url
// So even if they get exposed, and people can decrypt it. This is just to encrypt the search params in the url
const ENCRYPTION_KEY =
  import.meta.env.VITE_ENCRYPTION_KEY || 'secure_encryption_key';

// Function to generate IV (Initialization Vector)
const generateIV = () => {
  return crypto.getRandomValues(new Uint8Array(12));
};

// Convert string to bytes
const encoder = new TextEncoder();
const decoder = new TextDecoder();

const getKey = (key: string): Uint8Array => {
  // Hash the key to ensure it's always the right length
  const encoder = new TextEncoder();
  const data = encoder.encode(key);
  const buffer = new Uint8Array(32); // 32 bytes for AES-256

  // Use the first 32 bytes of the key, or pad with zeros if too short
  buffer.set(data.slice(0, 32));

  return buffer;
};

// Encrypt search params
export const encryptSearchParams = async (
  params: Record<string, string>
): Promise<string> => {
  try {
    // Convert params to string
    const searchParams = new URLSearchParams(params).toString();
    const plaintext = encoder.encode(searchParams);

    // Generate a random IV
    const iv = generateIV();

    const keyBuffer = getKey(ENCRYPTION_KEY);

    // Import encryption key
    const key = await crypto.subtle.importKey(
      'raw',
      keyBuffer,
      { name: 'AES-GCM', length: 256 },
      false,
      ['encrypt']
    );

    // Encrypt the data
    const ciphertext = await crypto.subtle.encrypt(
      { name: 'AES-GCM', iv },
      key,
      plaintext
    );

    // Combine IV and ciphertext
    const combined = new Uint8Array(
      iv.length + new Uint8Array(ciphertext).length
    );
    combined.set(iv);
    combined.set(new Uint8Array(ciphertext), iv.length);

    // Convert to URL-safe base64
    return btoa(String.fromCharCode(...combined))
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
      .replace(/=+$/, '');
  } catch (error) {
    console.error('Encryption failed:', error);
    throw new Error('Failed to encrypt params');
  }
};

// Decrypt search params
export const decryptSearchParams = async (
  encrypted: string
): Promise<Record<string, string>> => {
  try {
    // Restore base64 characters
    const restored = encrypted.replace(/-/g, '+').replace(/_/g, '/');
    const combined = new Uint8Array(
      atob(restored)
        .split('')
        .map((char) => char.charCodeAt(0))
    );

    // Extract IV and ciphertext
    const iv = combined.slice(0, 12);
    const ciphertext = combined.slice(12);

    const keyBuffer = getKey(ENCRYPTION_KEY);

    // Import decryption key
    const key = await crypto.subtle.importKey(
      'raw',
      keyBuffer,
      { name: 'AES-GCM', length: 256 },
      false,
      ['decrypt']
    );

    // Decrypt the data
    const decrypted = await crypto.subtle.decrypt(
      { name: 'AES-GCM', iv },
      key,
      ciphertext
    );

    // Convert back to search params
    const searchParamsString = decoder.decode(decrypted);
    const params = new URLSearchParams(searchParamsString);

    // Convert to plain object
    const result: Record<string, string> = {};
    params.forEach((value, key) => {
      result[key] = value;
    });

    return result;
  } catch (error) {
    console.error('Decryption failed:', error);
    throw new Error('Failed to decrypt params');
  }
};
