import PageLayout from '@fluency/ui/templates/PageLayout';
import DashboardShell from '../Layout/DashboardShell';

const AdminHome = () => {
  return (
    <DashboardShell>
      <PageLayout
        maxWidth="full"
        header={{
          title: 'Home',
          description: 'Admin Dashboard Home Page',
        }}
        content={{
          className: 'flex flex-col h-full relative',
          children: (
            <>
              <div className="flex-1 overflow-auto">
                <div className="space-y-4 mt-6 mb-6">Home</div>
              </div>
            </>
          ),
        }}
      />
    </DashboardShell>
  );
};

export default AdminHome;
