import { createFileRoute, useNavigate } from '@tanstack/react-router';
import { RolePermissionHandler } from '@fluency/ui/features/AuthGuard/RolePermissionHandler';
import WorkflowMapping from '@fluency/ui/features/WorkflowMapping';
import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import NoSubscriptionMessage from '@fluency/ui/components/NoSubscriptionMessage';

export const Route = createFileRoute('/workflow/$id')({
  component: () => {
    const { subscription, user, orgId } = useAuth();
    const navigate = useNavigate();

    if (!user) {
      navigate({
        to: '/logout',
        replace: true,
      });
      return null;
    }

    if (orgId === null) {
      navigate({
        to: '/orgform',
        replace: true,
      });
      return null;
    }

    if (
      !subscription ||
      subscription === 'absent' ||
      !(subscription === 'active' || subscription === 'trialing')
    ) {
      return <NoSubscriptionMessage />;
    }

    return (
      <RolePermissionHandler requiredPermissions={['documents:read']}>
        <WorkflowMapping />
      </RolePermissionHandler>
    );
  },
});
