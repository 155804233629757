import { useEffect, useMemo, useState } from 'react';
import { Membership, columns } from './columns';
import { DataTable } from './DataTable';
import { RefreshCw } from 'lucide-react';
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHeader,
  TableHead,
} from '@fluency/ui/components/ui/table';
import useGetOrgUsers from '@fluency/ui/hooks/organisation/useGetOrgUsers';

interface UserTableProps {
  orgId?: string;
}

export default function UserTable({ orgId }: UserTableProps) {
  const { data, isLoading } = useGetOrgUsers({ organisationId: orgId });
  const [memberships, setMemberships] = useState<Membership[]>([]);

  const columnsMemo = useMemo(
    () => (orgId ? columns(orgId) : columns()),
    [orgId]
  );

  useEffect(() => {
    if (data && data.success) {
      setMemberships(data.memberships);
    }
  }, [data]);

  if (isLoading) {
    return (
      <Table className="mt-8">
        <TableHeader>
          <TableRow>
            <TableHead>Members</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          <TableRow>
            <TableCell colSpan={4} className="text-center py-8">
              <RefreshCw className="h-6 w-6 animate-spin mx-auto text-gray-400" />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  }

  if (!data || !data.success || memberships.length === 0) {
    return (
      <Table className="mt-8">
        <TableHeader>
          <TableRow>
            <TableHead>Members</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          <TableRow>
            <TableCell colSpan={4} className="text-center py-8 text-gray-400">
              No user data available.
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  }

  return (
    <div className="w-full">
      <DataTable columns={columnsMemo} data={memberships} />
    </div>
  );
}
