import PageLayout from '@fluency/ui/templates/PageLayout';
import DashboardShell from '../Layout/DashboardShell';
import { useOrganisationOperations } from './hooks/useOrganisationOperations';
import DataList, { HeaderConfig } from '../common/components/DataList';
import { AdminOrganisation } from './types';
import { useEffect, useMemo, useState } from 'react';
import { Logger } from '../../Logger';
import { toast } from '@fluency/ui/components/ui/use-toast';
import moment from 'moment';
import SearchInput from '../common/components/SearchInput';
import CreateOrganisationDialog from './CreateOrganisationDialog';
import { PaginationMeta } from '../common/types/pagination';
import { ActionItem } from '../common/components/RowActions';
import { useNavigate } from '@tanstack/react-router';
import { cn } from '@fluency/ui/lib/utils';
import { capitalise } from '@fluency/ui/lib';
import { Checkbox } from '@fluency/ui/components/ui/checkbox';

const OrganisationHome = () => {
  const navigate = useNavigate();

  const [pageMeta, setPageMeta] = useState({
    page: 1,
    limit: 20,
    search: '',
    sortBy: 'createdAt',
    sortOrder: 'desc',
  });

  const { useGetOrganisations } = useOrganisationOperations();
  const {
    data: organisations,
    isLoading,
    isError,
    error,
  } = useGetOrganisations({
    page: pageMeta.page,
    limit: pageMeta.limit,
    search: pageMeta.search,
    sortBy: pageMeta.sortBy,
    sortOrder: pageMeta.sortOrder as 'asc' | 'desc',
  });

  const [data, meta] = useMemo(
    () => [organisations?.data, organisations?.meta],
    [organisations]
  );

  const headers: HeaderConfig<AdminOrganisation>[] = [
    {
      key: 'tenantId',
      header: 'WorkOs Org Id',
      renderCell: (item) => item.tenantId,
      width: '20%',
    },
    {
      key: 'stripeCustomerId',
      header: 'Stripe Customer Id',
      renderCell: (item) => item.stripeCustomerId || 'N/A',
      renderNote: (item) =>
        !item.stripeCustomerId ? 'Stripe customerId not available' : null,
      width: '12%',
    },
    // {
    //   key: 'owner',
    //   header: 'Owner Id',
    //   renderCell: (item) => item.owner || 'N/A',
    //   renderNote: (item) =>
    //     !item.owner ? 'Owner has not been assigned' : null,
    //   width: '20%',
    // },
    {
      key: 'workos',
      header: 'Organisation Name',
      renderCell: (item) => item.workos?.name || 'N/A',
      renderNote: (item) => (!item.workos ? 'WorkOS org not found' : null),
      width: '20%',
    },
    {
      key: 'stripe',
      header: 'Stripe Customer Email',
      renderCell: (item) => item.stripe?.email || 'N/A',
      renderNote: (item) => (!item.stripe ? 'Stripe customer not found' : null),
      width: '18%',
    },
    {
      key: 'status',
      header: 'Status',
      renderCell: (item) => {
        const status = item.subscriptionStatus?.status;
        return item.subscriptionStatus?.status ? (
          <div
            className={cn(
              'rounded-full h-6 w-6',
              (!status || status === 'absent' || status === 'canceled') &&
                'bg-gray-500',
              status === 'active' && 'bg-green-500',
              status === 'trialing' && 'bg-yellow-500',
              status === 'past_due' && 'bg-red-500'
            )}
          ></div>
        ) : (
          'N/A'
        );
      },
      renderNote: (item) =>
        capitalise(item.subscriptionStatus?.status || 'No subscription'),
      width: '10%',
    },
    {
      key: 'paymentMethod',
      header: 'Payment Method',
      renderCell: (item) => {
        const paymentMethod = item.subscriptionStatus?.paymentMethod;
        return <Checkbox checked={!!paymentMethod} />;
      },
      width: '10%',
    },

    {
      key: 'createdAt',
      header: 'Created At',
      renderCell: (item) => moment(item.createdAt).format('LL'),
      width: '10%',
      sortable: true,
    },
  ];

  const actions: ActionItem[] = [
    {
      label: <span className="font-normal">{'View Details'}</span>,
      onClick: (item: AdminOrganisation) =>
        navigate({ to: `/admin/organisation/detail/${item.tenantId}` }),
    },
    {
      label: (
        <span className="text-destructive font-normal hover:text-destructive">
          {'Delete'}
        </span>
      ),
      onClick: (item: AdminOrganisation) => console.log('Edit', item),
      type: 'destructive',
      disabled: true,
    },
  ];

  const handleSort = (sortConfig: {
    key: string;
    direction: 'asc' | 'desc';
  }) => {
    setPageMeta({
      ...pageMeta,
      sortBy: sortConfig.key,
      sortOrder: sortConfig.direction,
    });
  };

  const handleSearchUpdate = (value: string) => {
    setPageMeta({ ...pageMeta, search: value });
  };

  useEffect(() => {
    if (isError && error) {
      Logger.error('There is an error in fetching the data', error);
      toast({
        title: 'Error',
        description: 'Failed to fetch organisations',
        variant: 'destructive',
      });
    }
  }, [isLoading, isError, data, meta]);

  return (
    <DashboardShell>
      <PageLayout
        maxWidth="full"
        header={{
          title: 'Organisation',
          description: 'Create and manage organisations',
          headerActions: (
            <div className="flex items-center gap-4">
              <CreateOrganisationDialog />
            </div>
          ),
        }}
        content={{
          className: 'flex flex-col h-full relative',
          children: (
            <div className="flex-1 overflow-auto">
              <div className="space-y-4 mt-6 mb-6">
                <div className="flex flex-row items-center gap-2 ml-2">
                  <SearchInput
                    value={pageMeta.search}
                    performSearch={handleSearchUpdate}
                    info={
                      'You can only search by WorkOS Org Id, Stripe Customer Id, and Owner Id'
                    }
                  />
                </div>

                {isError && (
                  <div className="text-center">No data available</div>
                )}

                {!isError && (
                  <DataList
                    data={data ?? []}
                    meta={
                      meta ??
                      ({
                        page: pageMeta.page,
                        limit: pageMeta.limit,
                      } as PaginationMeta)
                    }
                    headers={headers}
                    onPageChange={(page: number) => {
                      setPageMeta({ ...pageMeta, page });
                    }}
                    onPageSizeChange={(limit: number) => {
                      setPageMeta({ ...pageMeta, limit });
                    }}
                    onSort={handleSort}
                    currentSort={{
                      key: pageMeta.sortBy,
                      direction: pageMeta.sortOrder as 'asc' | 'desc',
                    }}
                    isLoading={isLoading}
                    actions={actions}
                  />
                )}
              </div>
            </div>
          ),
        }}
      />
    </DashboardShell>
  );
};

export default OrganisationHome;
