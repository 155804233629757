import { useState } from 'react';
import { Button } from '@fluency/ui/components/ui/button';
import { Input } from '@fluency/ui/components/ui/input';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@fluency/ui/components/ui/select';
import { AlertCircle, Check, Copy, Plus } from 'lucide-react';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@fluency/ui/components/ui/form';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  createSubscriptionFormSchema,
  CreateSubscriptionFormValues,
} from './schema/createSubscriptionSchema';
import { useSubscriptionOperations } from './hooks/useSubscriptionOperations';
import { toast } from '@fluency/ui/components/ui/use-toast';
import { useBillingDetails } from '@fluency/ui/hooks/subscription/useBillingDetails';
import { encryptSearchParams } from '../common/utils/secureSearchParams';
import { Switch } from '@fluency/ui/components/ui/switch';
import { Alert, AlertDescription } from '@fluency/ui/components/ui/alert';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@fluency/ui/components/ui/dialog';

export const products = [
  {
    label: 'Pro',
    value: 'pro',
  },
  {
    label: 'Business',
    value: 'business',
  },
  {
    label: 'Enterprise',
    value: 'enterprise',
  },
] as const;

export const plans = [
  {
    label: 'Monthly',
    value: 'monthly',
  },
  {
    label: 'Annual',
    value: 'annual',
  },
] as const;

const paymentTypes = [
  {
    label: 'Invoice Last',
    value: 'invoice',
  },
  {
    label: 'Payment First',
    value: 'immediately',
  },
] as const;

interface CreateSubscriptionDialogProps {
  orgId: string;
  includeOwnerEmail?: boolean;
  hasHadFreeTrial?: boolean;
  onSuccess?: () => void;
}

const CreateSubscriptionForm = ({
  orgId,
  includeOwnerEmail = false,
  hasHadFreeTrial = false,
  onSuccess,
}: CreateSubscriptionDialogProps) => {
  const [isTrialMode, setIsTrialMode] = useState(false);
  const [open, setOpen] = useState(false);
  const [paymentLink, setPaymentLink] = useState('');
  const [copied, setCopied] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { createSubscription, createFreeTrial } = useSubscriptionOperations({
    orgId,
  });
  const { updateBillingDetails } = useBillingDetails({ orgId });

  const form = useForm<CreateSubscriptionFormValues>({
    resolver: zodResolver(
      createSubscriptionFormSchema(includeOwnerEmail, isTrialMode)
    ),
    defaultValues: {
      creatorSeats: 1,
      viewerSeats: 0,
      discountCode: '',
      paymentType: 'immediately',
      daysUntilDue: 14,
      ownerEmail: '',
      trialDays: 14,
    },
  });

  const { watch } = form;
  const paymentType = watch('paymentType');
  const product = watch('product');
  const ownerEmail = watch('ownerEmail');

  const generateViewerSeatsOptions = () => {
    // Fixed tiers for 25, 50, 75
    const fixedTiers = [
      { label: '0 seat', value: '0' },
      { label: '25 seats', value: '25' },
      { label: '50 seats', value: '50' },
      { label: '75 seats', value: '75' },
    ];

    // Generate options for 100+ seats
    const hundredPlusTiers = Array.from({ length: 10 }, (_, i) => ({
      label: `${(i + 1) * 100} seats`,
      value: ((i + 1) * 100).toString(),
    }));

    return [...fixedTiers, ...hundredPlusTiers];
  };

  const viewerSeatsOptions = generateViewerSeatsOptions();

  const handleClose = () => {
    setOpen(!open);
    form.reset();
    setPaymentLink('');
    setCopied(false);
  };

  const handleCopyLink = async () => {
    try {
      await navigator.clipboard.writeText(window.location.origin + paymentLink);
      setCopied(true);
      toast({
        title: 'Copied!',
        description: 'Payment link copied to clipboard',
      });
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      toast({
        title: 'Failed to copy',
        description: 'Please try copying manually',
        variant: 'destructive',
      });
    }
  };

  const onSubmit = async (data: CreateSubscriptionFormValues) => {
    setIsLoading(true);
    try {
      // If owner email is included, need to update billing email
      // That means the customer never had any subscription before - and it needs to add email to stripe customer details
      if (includeOwnerEmail) {
        await updateBillingDetails({ email: ownerEmail });
      }

      if (isTrialMode) {
        await createFreeTrial(data);
        handleClose();
        toast({
          title: 'Success',
          description: 'Free trial created successfully',
        });
      } else {
        if (paymentType === 'invoice') {
          await createSubscription(data);
          handleClose();
          toast({
            title: 'Success',
            description: 'Subscription created successfully',
          });
          if (onSuccess) onSuccess();
        } else if (paymentType === 'immediately') {
          const params = await encryptSearchParams({
            orgId,
            product: data.product,
            plan: data.plan,
            paymentType: 'immediately',
            creatorSeats: String(data.creatorSeats),
            ...(data.product === 'enterprise' && {
              viewerSeats: String(data.viewerSeats),
            }),
            ...(data.discountCode && {
              discountCode: String(data.discountCode),
            }),
          });
          setPaymentLink(`/payment/${params}`);
        }
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to create subscription',
        variant: 'destructive',
      });
      console.error('Error creating subscription:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={open} onOpenChange={handleClose}>
      <DialogTrigger asChild>
        <Button>
          <Plus className="mr-2 h-4 w-4" />
          New Subscription
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Create New Subscription</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
            {/* Free Trial Section */}
            <div className="space-y-4">
              {!hasHadFreeTrial ? (
                <div className="space-y-4">
                  <div className="flex items-center space-x-2">
                    <Switch
                      checked={isTrialMode}
                      onCheckedChange={setIsTrialMode}
                    />
                    <span>Enable Free Trial</span>
                  </div>
                  {isTrialMode && (
                    <FormField
                      control={form.control}
                      name="trialDays"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Trial Duration (days)</FormLabel>
                          <FormControl>
                            <Input type="number" min={1} {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  )}
                </div>
              ) : (
                <Alert variant="destructive">
                  <AlertCircle className="h-4 w-4" />
                  <AlertDescription>
                    A free trial has already been used for this organization.
                  </AlertDescription>
                </Alert>
              )}
            </div>

            <FormField
              control={form.control}
              name="product"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Product</FormLabel>
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="Select product" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {products.map((item) => (
                        <SelectItem key={item.value} value={item.value}>
                          {item.label}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="plan"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Plan</FormLabel>
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="Select plan" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {plans.map((item) => (
                        <SelectItem key={item.value} value={item.value}>
                          {item.label}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="creatorSeats"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Number of Creator Seats</FormLabel>
                  <FormControl>
                    <Input type="number" min={1} {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            {product === 'enterprise' && (
              <FormField
                control={form.control}
                name="viewerSeats"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Number of Viewer Seats</FormLabel>
                    <Select
                      onValueChange={(value) => field.onChange(parseInt(value))}
                      value={field.value?.toString()}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder="Select number of viewer seats" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {viewerSeatsOptions.map((option) => (
                          <SelectItem key={option.value} value={option.value}>
                            {option.label}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
            )}

            <FormField
              control={form.control}
              name="paymentType"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Billing Type</FormLabel>
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="Select Billing Type" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {paymentTypes.map((method) => (
                        <SelectItem key={method.value} value={method.value}>
                          {method.label}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />

            {paymentType === 'invoice' && (
              <FormField
                control={form.control}
                name="daysUntilDue"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Days Until Due</FormLabel>
                    <FormControl>
                      <Input type="number" min={1} {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            )}

            {includeOwnerEmail && (
              <FormField
                control={form.control}
                name="ownerEmail"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Owner Email</FormLabel>
                    <FormControl>
                      <Input type="text" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            )}

            <FormField
              control={form.control}
              name="discountCode"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Discount Code (Optional)</FormLabel>
                  <FormControl>
                    <Input type="text" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            {paymentLink ? (
              <div className="space-y-4">
                <FormItem>
                  <FormLabel>Payment Link</FormLabel>
                  <div className="flex space-x-2">
                    <Input
                      readOnly
                      value={window.location.origin + paymentLink}
                      className="flex-1"
                    />
                    <Button
                      type="button"
                      variant="outline"
                      size="icon"
                      onClick={handleCopyLink}
                      className="flex-shrink-0"
                    >
                      {copied ? (
                        <Check className="h-4 w-4" />
                      ) : (
                        <Copy className="h-4 w-4" />
                      )}
                    </Button>
                  </div>
                </FormItem>
                <div className="flex justify-end">
                  <Button type="button" onClick={handleClose}>
                    Done
                  </Button>
                </div>
              </div>
            ) : (
              <div className="flex justify-end">
                <Button type="submit" disabled={isLoading}>
                  {isLoading ? 'Creating...' : 'Create Subscription'}
                </Button>
              </div>
            )}
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default CreateSubscriptionForm;
