import { createFileRoute } from '@tanstack/react-router'
import OrganisationHome from '@fluency/ui/features/admin/Organisation/OrganisationHome'

export const Route = createFileRoute('/admin/organisation/')({
  component: RouteComponent,
})

function RouteComponent() {
  return <OrganisationHome />
}
