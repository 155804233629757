import { Button } from '@fluency/ui/components/ui/button';
import { Lock, PencilIcon } from 'lucide-react';
import { DocumentationResponse } from '../../../hooks/documentation/types/master';
import { FileSettings } from './FileSettings';
import { useResourcePermissions } from '@fluency/ui/hooks/fga/useResourcePermissions';
import useGetVaults from '../../Home/hooks/useGetVaults';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@fluency/ui/components/ui/tooltip';
import { usePermissionGate } from '@fluency/ui/providers/permissions/PermissionGateProvider';

interface DocumentationResponseProps {
  data?: DocumentationResponse;
  isMostRecentVersion: boolean;
  isEditMode: boolean;
  setIsEditMode: (value: boolean) => void;
  isPreview?: boolean;
}

const commonButtonStyles =
  'h-9 px-3 gap-2 flex items-center justify-center font-medium text-sm w-24';
const commonIconStyles = 'w-4 h-4';
const headerSpacing = 'px-6';

const DocumentActions: React.FC<DocumentationResponseProps> = ({
  data,
  isMostRecentVersion,
  isEditMode,
  setIsEditMode,
}) => {
  const { hasPermissions } = usePermissionGate();
  const canUpdate = hasPermissions(['documents:update']);

  const documentId = data?.documentation.documentationId as string;
  const isLocked = data?.documentation.isLocked as boolean;
  const { data: vaultsData } = useGetVaults();
  const vault = vaultsData?.vaults.find(
    (vault) => vault.id === data?.documentation.vault.id
  );
  const isVaultLocked = vault?.isLocked || false;

  if (!data || isEditMode) {
    return null;
  }

  const { data: permissions } = useResourcePermissions({
    resourceType: 'DOCUMENTATION',
    resourceId: documentId,
  });
  const role = permissions?.data.bearer.effectiveRole.role;
  const isManager = permissions?.data.bearer.effectiveRole.role === 'MANAGER';

  const renderVaultLockStatus = () => {
    if (!isVaultLocked) return null;

    return (
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              variant="outline"
              className={`${commonButtonStyles} cursor-default hover:bg-white`}
            >
              <Lock className={commonIconStyles} />
              Vault Locked
            </Button>
          </TooltipTrigger>
          <TooltipContent side="top">
            Cannot edit while vault is locked
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    );
  };

  const renderDocumentLockStatus = () => {
    if (!isLocked || isVaultLocked) return null;

    return (
      <Button
        variant="outline"
        className={`${commonButtonStyles} cursor-default hover:bg-white`}
      >
        <Lock className={commonIconStyles} />
        Document Locked
      </Button>
    );
  };

  const renderEditButton = () => {
    if (
      !isMostRecentVersion ||
      isLocked ||
      !canUpdate ||
      isVaultLocked ||
      !role ||
      role === 'VIEWER' ||
      role === 'COMMENTER'
    )
      return null;

    return (
      <>
        {/* <div className="h-9 border-l mx-3" /> */}
        <Button
          onClick={() => setIsEditMode(true)}
          variant="secondary"
          className={`${commonButtonStyles} w-24`}
          aria-label="Edit document"
        >
          <PencilIcon className={commonIconStyles} />
          Edit
        </Button>
      </>
    );
  };

  return (
    <div className={`fixed  top-0 right-0 ${headerSpacing} pt-5 pb-2`}>
      <div className="mx-auto flex items-center justify-end gap-3">
        {renderVaultLockStatus()}
        {documentId && (
          <FileSettings
            documentId={documentId}
            data={data}
            isManager={isManager}
            isVaultLocked={isVaultLocked}
          />
        )}
        {renderDocumentLockStatus()}
        {renderEditButton()}
      </div>
    </div>
  );
};

export default DocumentActions;
