import { cn } from '@fluency/ui/lib/utils';
import { ReactNode, useState, MouseEvent } from 'react';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '../ui/tooltip';
import { Button } from '../ui/button';
import { TooltipArrow } from '@radix-ui/react-tooltip';

interface CustomToolProps {
  icon: ReactNode;
  onClick?: (event: MouseEvent) => void;
  label?: string;
  className?: string;
}

interface FloatingToolbarProps {
  children: ReactNode;
  toolbarPosition?: 'top' | 'bottom' | 'left' | 'right';
  offset?: number;
  hide?: boolean;
  customTools: CustomToolProps[];
}

const FloatingToolbar = (props: FloatingToolbarProps) => {
  const {
    children,
    toolbarPosition = 'top',
    offset = 12,
    hide = false,
    customTools,
  } = props;
  const [isVisible, setIsVisible] = useState(false);

  // Position classes for the toolbar overlay
  const getToolbarPositionStyles = () => {
    const positions = {
      top: {
        top: '0',
        left: '50%',
        transform: `translate(-50%, -100%)`,
      },
      bottom: {
        bottom: '0',
        left: '50%',
        transform: `translate(-50%, 100%)`,
      },
      left: {
        left: '0',
        top: '50%',
        transform: `translate(-100%, -50%)`,
      },
      right: {
        right: '0',
        top: '50%',
        transform: `translate(100%, -50%)`,
      },
    };
    return positions[toolbarPosition];
  };

  return (
    <div
      className="group relative inline-flex"
      onMouseEnter={() => setIsVisible(true)}
      onMouseLeave={() => setIsVisible(false)}
    >
      {/* Main content */}
      {/* Use margin as a way to create the overlay helper for the hover state */}
      <div className="relative" style={{ margin: `${offset}px` }}>
        {children}
      </div>

      {/* Toolbar overlay */}
      {isVisible && !hide && (
        <div
          className="absolute z-50 flex items-center bg-white rounded-md shadow-lg border border-gray-200 p-1 gap-1"
          style={{
            ...getToolbarPositionStyles(),
          }}
        >
          {customTools.map((tool, index) => (
            <TooltipProvider key={index}>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    variant={'ghost'}
                    size={'icon'}
                    className={cn('h-7 w-7', tool.className)}
                    onClick={tool.onClick}
                  >
                    {tool.icon}
                  </Button>
                </TooltipTrigger>
                <TooltipContent className="text-xs [&>div[data-side]]:fill-white">
                  {tool.label}
                  <TooltipArrow className="fill-gray-300" />
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          ))}
        </div>
      )}
    </div>
  );
};

export default FloatingToolbar;
