import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import { useMutation } from '@tanstack/react-query';
import * as Sentry from '@sentry/react';

interface CacheResponse {
  message: string;
  removedData: Array<{ organisationId: string }>;
}

const clearSubscriptionCache = async (
  accessToken: string
): Promise<CacheResponse> => {
  const apiBaseUrl = import.meta.env.VITE_SERVER_API_URL;

  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');
  myHeaders.append('Authorization', `Bearer ${accessToken}`);

  const requestOptions: RequestInit = {
    method: 'DELETE',
    headers: myHeaders,
    redirect: 'follow',
  };

  const response = await fetch(
    `${apiBaseUrl}/cache/clearSubscription`,
    requestOptions
  );

  if (!response.ok) {
    Sentry.captureException('Failed to clear subscription cache');
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const result = await response.json();
  return result as CacheResponse;
};

export const useClearSubscriptionCache = () => {
  const { accessToken } = useAuth();

  return useMutation({
    mutationFn: () => {
      if (!accessToken) {
        throw new Error('No access token available');
      }
      return clearSubscriptionCache(accessToken);
    },
  });
};
