import { MoreHorizontal } from 'lucide-react';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@fluency/ui/components/ui/popover';
import { Button } from '@fluency/ui/components/ui/button';
import { cn } from '@fluency/ui/lib/utils';
import { ReactNode } from 'react';

export interface ActionItem {
  label: ReactNode;
  onClick: (item: any) => void;
  type?: 'destructive' | 'default';
  disabled?: boolean;
}

interface RowActionsProps {
  actions: ActionItem[];
  item: any;
}

const RowActions = ({ actions, item }: RowActionsProps) => {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant="ghost" size="sm" className="h-8 w-8 p-0">
          <MoreHorizontal className="h-4 w-4" />
          <span className="sr-only">Open menu</span>
        </Button>
      </PopoverTrigger>
      <PopoverContent align="end" className="w-fit p-1">
        <div className="grid gap-1">
          {actions.map((action, index) => (
            <Button
              key={index}
              variant="ghost"
              size="sm"
              className={cn('flex w-full justify-start items-center mr-2 ')}
              onClick={() => action.onClick(item)}
              disabled={action.disabled}
            >
              {action.label}
            </Button>
          ))}
        </div>
      </PopoverContent>
    </Popover>
  );
};

export default RowActions;
