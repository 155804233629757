import { useEffect, useState } from 'react';
import { useRecording } from '@fluency/ui/providers/recording/RecordingContext';
import { useNavigate } from '@tanstack/react-router';
import { toast } from '@fluency/ui/components/ui/use-toast';
import { useQueryClient } from '@tanstack/react-query';
import { Logger } from '@fluency/ui/features/Logger';

interface DocProgress {
  id: string;
  name: string;
  status: string;
}

interface UseProcessDocsOptions {
  documentId?: string;
  refetch?: () => void;
  isExistingDoc?: boolean;
}

const useProcessDocs = (options?: UseProcessDocsOptions) => {
  const { documentId, refetch, isExistingDoc } = options || {};
  const [docsInProgress, setDocsInProgress] = useState<{
    [key: string]: DocProgress;
  }>({});

  const {
    setIsRecording,
    setIsPaused,
    setIsLoading,
    setCurrentDocumentId,
    setError,
    setStepBeforeId,
    setRecordingType,
  } = useRecording();

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const handleDocProgressUpdate = (data: DocProgress) => {
    setDocsInProgress((prev) => {
      const updatedDocs = { ...prev };
      if (data.status === 'done' || data.status === 'error') {
        delete updatedDocs[data.id];
      } else {
        updatedDocs[data.id] = data;
      }
      return updatedDocs;
    });

    if (data.status === 'done' || data.status === 'error') {
      // Reset recording states
      setIsRecording(false);
      setIsPaused(false);
      setIsLoading(false);
      setCurrentDocumentId(null);

      // Additional resets for existing doc recording
      if (isExistingDoc) {
        setStepBeforeId(null);
        setRecordingType(undefined);
      }

      // Handle query cache and navigation
      if (isExistingDoc) {
        refetch?.();
      } else if (documentId) {
        queryClient.removeQueries({ queryKey: ['docInfo', documentId] });
        navigate({ to: '/home', replace: true });
      }

      // Show appropriate toast
      if (data.status === 'done') {
        toast({
          title: 'Process captured successfully',
          description:
            "You will receive a notification when it's ready to view.",
        });
      } else if (data.status === 'error') {
        setError?.(true);
        Logger.error('Error in process recording');
        toast({
          variant: 'destructive',
          title: 'Error processing recording',
          description: 'Please try again.',
        });
      }
    }
  };

  const handleDocTimeoutUpdate = (_data: DocProgress) => {
    // Reset recording states
    setIsRecording(false);
    setIsPaused(false);
    setIsLoading(false);
    setCurrentDocumentId(null);

    if (isExistingDoc) {
      setStepBeforeId(null);
      setRecordingType(undefined);
    }

    // Show timeout toast
    toast({
      title: 'Recording timeout',
      description:
        'The recording has ended due to inactivity. The captured steps will be processed.',
    });

    // Handle navigation
    if (!isExistingDoc && documentId) {
      navigate({ to: '/home', replace: true });
    }
  };

  useEffect(() => {
    if (window.electron) {
      window.electron.on('doc-progress-update', handleDocProgressUpdate);
      window.electron.on('doc-timeout', handleDocTimeoutUpdate);
    }
    return () => {
      if (window.electron) {
        window.electron.removeListener(
          'doc-progress-update',
          handleDocProgressUpdate
        );
        window.electron.removeListener('doc-timeout', handleDocTimeoutUpdate);
      }
    };
  }, [
    documentId,
    setIsRecording,
    setIsPaused,
    setIsLoading,
    setCurrentDocumentId,
    navigate,
    isExistingDoc,
    refetch,
  ]);

  const isProcessing = Object.keys(docsInProgress).length > 0;

  return { docsInProgress, isProcessing };
};

export default useProcessDocs;
