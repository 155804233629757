import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import * as Sentry from '@sentry/react';
import { useQuery } from '@tanstack/react-query';

interface Payment {
  id: string;
  amount: number;
  currency: string;
  status: string;
  created: string;
}

interface PaymentQueryParams {
  orgId: string;
}

const usePayments = (params: PaymentQueryParams) => {
  const apiBaseUrl = import.meta.env.VITE_SERVER_API_URL;
  const workOSContext = useAuth();

  const paymentsQuery = useQuery<Payment[], Error>({
    queryKey: ['payments', params.orgId],
    queryFn: async (): Promise<Payment[]> => {
      if (!workOSContext || !workOSContext.accessToken) {
        Sentry.captureException('Access token is missing');
        throw new Error('Access token is missing');
      }

      const queryParams = new URLSearchParams({
        orgId: params.orgId,
      });

      const response = await fetch(
        `${apiBaseUrl}/billing/payments?${queryParams}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${workOSContext.accessToken}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (!response.ok) {
        Sentry.captureException('Failed to fetch payments');
        throw new Error(`Failed to fetch payments: ${response.statusText}`);
      }

      const data: Payment[] = await response.json();
      return data;
    },
    enabled: !!workOSContext?.accessToken,
  });

  return {
    payments: paymentsQuery.data,
    isLoading: paymentsQuery.isLoading,
    error: paymentsQuery.error,
    refetch: paymentsQuery.refetch,
    isFetching: paymentsQuery.isFetching,
  };
};

export default usePayments;
