import AdminHome from '@fluency/ui/features/admin/Home/AdminHome'
import { createFileRoute } from '@tanstack/react-router'

export const Route = createFileRoute('/admin/home')({
  component: RouteComponent,
})

function RouteComponent() {
  return <AdminHome />
}
