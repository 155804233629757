import { useEffect, useRef, useState } from 'react';
import Logo from '../../assets/text-logo-blue.png';
import EmptyDocAction from './components/EmptyDocAction';
import BulkDocActions from './components/BulkDocActions';
import { ScrollArea } from '@fluency/ui/components/ui/scroll-area';
import DocumentNotFound from './components/notfound/index';
import { useGetDocumentData } from './hooks';
import useGetVaults from '@fluency/ui/features/Home/hooks/useGetVaults';
import DocumentActions from './components/DocumentActions';
import CollapsibleSidebar from './components/CollapsibleSidebar';
import { BigSummary } from './components/summary/BigSummary';
import { MiniSummary } from './components/summary/MiniSummary';
import DocSkeleton from './components/DocSkeleton';
import DocSummary from './components/docSummary/DocSummary';
import StepRecord from './components/StepRecord';
import DocumentTitle from './components/DocumentTitle';

interface DocumentViewPageProps {
  id: string;
  isPreview?: boolean;
}

const ViewDocument = ({ id, isPreview = false }: DocumentViewPageProps) => {
  const stepRefs = useRef<{ [key: string]: HTMLElement }>({});
  const { data, isLoading, error } = useGetDocumentData(id);
  const { data: vaultsData } = useGetVaults();
  const vault = vaultsData?.vaults.find(
    (vault) => vault.id === data?.documentation.vault.id
  );
  const isVaultLocked = vault?.isLocked || false;
  const [isEditMode, setIsEditMode] = useState(false);
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);

  // Reset edit mode when document is loaded or lock status changes
  useEffect(() => {
    if (data?.documentation.isLocked) {
      setIsEditMode(false);
    }
  }, [data?.documentation.isLocked]);

  // Wrap setIsEditMode to enforce lock constraint
  const handleSetEditMode = (value: boolean) => {
    if (value && data?.documentation.isLocked) {
      return; // Prevent enabling edit mode when locked
    }
    setIsEditMode(value);
  };

  const handleJumpToStep = (stepId: string) => {
    const element = stepRefs.current[stepId];
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  useEffect(() => {
    const image = new Image();
    image.src = Logo;
  }, []);

  useEffect(() => {
    if (
      stepRefs.current &&
      (stepRefs.current.length as unknown as number) > 0
    ) {
      setIsSidebarCollapsed(false);
    }
  }, []);

  if (isLoading) {
    return (
      <div className="w-full relative">
        <DocSkeleton stepNum={2} />
      </div>
    );
  }

  if (error) {
    return <DocumentNotFound />;
  }

  // Check if the document has steps
  const haveSteps =
    data?.documentation.steps && data?.documentation.steps.length > 0;

  return (
    <div className="w-full relative">
      <div className="flex flex-col">
        <div className="top-0 z-50 ">
          {/* Header */}
          {isEditMode ? (
            <BulkDocActions
              documentId={id}
              isEditMode={isEditMode}
              setIsEditMode={handleSetEditMode}
              documentName={data?.documentation.documentationName ?? ''}
              isPreview={isPreview}
            />
          ) : (
            <DocumentActions
              data={data}
              isMostRecentVersion={
                data?.documentation.versionInfo.isLatestVersion ?? false
              }
              isEditMode={isEditMode}
              setIsEditMode={setIsEditMode}
              isPreview={isPreview}
            />
          )}
        </div>

        {/* Main content wrapper */}
        <div className="flex w-full">
          {/* Left Sidebar - only visible in edit mode */}
          {isEditMode &&
            haveSteps &&
            data?.documentation.versionInfo.isLatestVersion && (
              <CollapsibleSidebar
                isCollapsed={isSidebarCollapsed}
                onToggle={() => setIsSidebarCollapsed(!isSidebarCollapsed)}
              >
                {isSidebarCollapsed ? (
                  <MiniSummary
                    documentId={id}
                    handleJumpToStep={handleJumpToStep}
                  />
                ) : (
                  <BigSummary
                    documentId={id}
                    handleJumpToStep={handleJumpToStep}
                  />
                )}
              </CollapsibleSidebar>
            )}

          {/* Main content area */}
          <ScrollArea className="h-[calc(100vh-85px)] w-full">
            <div className="flex-1 transition-all duration-300 ease-in-out w-full">
              <div className="w-full max-w-6xl mx-auto px-6">
                <div className="pt-4 pb-6">
                  <div className="col-span-2 order-2 md:order-2 lg:order-1">
                    <DocumentTitle
                      data={data}
                      id={id}
                      isMostRecentVersion={
                        data?.documentation.versionInfo.isLatestVersion ?? false
                      }
                      isEditMode={isEditMode}
                    />
                  </div>
                </div>
                <div className="">
                  <DocSummary id={id} isEditMode={isEditMode} />
                </div>
                <div className="flex w-full">
                  {Array.isArray(data?.documentation.steps) &&
                  data.documentation.steps.length > 0 ? (
                    <StepRecord
                      steps={data.documentation.steps}
                      isEditMode={isEditMode}
                      stepRefs={stepRefs}
                      docId={id}
                    />
                  ) : (
                    <EmptyDocAction
                      documentId={id}
                      setIsEditMode={setIsEditMode}
                      isVaultLocked={isVaultLocked}
                    />
                  )}
                </div>
              </div>
            </div>
          </ScrollArea>
        </div>
      </div>
    </div>
  );
};

export default ViewDocument;
