import * as React from 'react';
import { Check, ChevronsUpDown, X, User2 } from 'lucide-react';
import { Button } from '@fluency/ui/components/ui/button';
import { Avatar, AvatarFallback } from '@fluency/ui/components/ui/avatar';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from '@fluency/ui/components/ui/command';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@fluency/ui/components/ui/popover';
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from '@fluency/ui/components/ui/hover-card';
import { cn } from '@fluency/ui/lib/utils';

interface User {
  id: string;
  firstName?: string;
  lastName?: string;
  email: string;
}

interface ProcessOwnerProps {
  initialId?: string;
  onChange?: (id: string | null) => void;
  users: User[];
  isLoading?: boolean;
  className?: string;
}

export default function ProcessOwner({
  initialId,
  onChange,
  users,
  isLoading,
  className,
}: ProcessOwnerProps) {
  const [open, setOpen] = React.useState(false);
  const [search, setSearch] = React.useState('');

  const selectedUser = React.useMemo(
    () => users.find((user) => user.id === initialId),
    [users, initialId]
  );

  const filteredUsers = React.useMemo(() => {
    const searchTerm = search.toLowerCase();
    return users.filter((user) => {
      const fullName = `${user.firstName ?? ''} ${
        user.lastName ?? ''
      }`.toLowerCase();
      const email = user.email.toLowerCase();
      return fullName.includes(searchTerm) || email.includes(searchTerm);
    });
  }, [users, search]);

  const handleSelect = (id: string) => {
    setOpen(false);
    onChange?.(id);
  };

  const handleClear = (e: React.MouseEvent) => {
    e.stopPropagation();
    onChange?.(null);
  };

  const getUserInitials = (user: User) => {
    if (user.firstName && user.lastName) {
      return `${user.firstName[0]}${user.lastName[0]}`;
    }
    return user.email.toUpperCase()[0];
  };

  const getUserDisplayName = (user: User) => {
    if (user.firstName && user.lastName) {
      return `${user.firstName} ${user.lastName}`;
    }
    return user.email.split('@')[0];
  };

  return (
    <div className="space-y-2">
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            role="combobox"
            aria-expanded={open}
            className={cn(
              'w-64 justify-start border-muted-foreground/20 bg-transparent hover:bg-muted/50 gap-2',
              className
            )}
            disabled={isLoading}
          >
            {selectedUser ? (
              <HoverCard>
                <HoverCardTrigger asChild>
                  <div className="flex items-center gap-2 flex-1 min-w-0">
                    <Avatar className="h-5 w-5 shrink-0">
                      <AvatarFallback className="text-xs">
                        {getUserInitials(selectedUser)}
                      </AvatarFallback>
                    </Avatar>
                    <span className="text-sm truncate">
                      {getUserDisplayName(selectedUser)}
                    </span>
                  </div>
                </HoverCardTrigger>
                <HoverCardContent className="w-fit p-2">
                  <p className="text-xs text-muted-foreground">
                    {selectedUser.email}
                  </p>
                </HoverCardContent>
              </HoverCard>
            ) : (
              <div className="flex items-center gap-2 text-muted-foreground">
                <User2 className="h-4 w-4" />
                <span className="text-sm">Select process owner</span>
              </div>
            )}
            <div className="ml-auto flex items-center gap-1">
              {selectedUser && (
                <Button
                  variant="ghost"
                  size="icon"
                  className="h-4 w-4 hover:bg-transparent"
                  onClick={handleClear}
                >
                  <X className="h-3 w-3" />
                  <span className="sr-only">Clear selection</span>
                </Button>
              )}
              <ChevronsUpDown className="h-4 w-4 opacity-50" />
            </div>
          </Button>
        </PopoverTrigger>
        <PopoverContent
          className="w-[--radix-popover-trigger-width] p-0"
          align="start"
        >
          <Command shouldFilter={false}>
            <CommandInput
              placeholder="Search users..."
              value={search}
              onValueChange={setSearch}
            />
            <CommandList>
              <CommandEmpty>No users found.</CommandEmpty>
              <CommandGroup>
                {filteredUsers.map((user) => (
                  <CommandItem
                    key={user.id}
                    value={user.id}
                    onSelect={handleSelect}
                    className="flex items-center gap-2"
                  >
                    <Avatar className="h-5 w-5 shrink-0">
                      <AvatarFallback className="text-xs">
                        {getUserInitials(user)}
                      </AvatarFallback>
                    </Avatar>
                    <div className="flex flex-col min-w-0 flex-1">
                      <span className="text-sm truncate">
                        {getUserDisplayName(user)}
                      </span>
                      <span className="text-xs text-muted-foreground truncate">
                        {user.email}
                      </span>
                    </div>
                    {selectedUser?.id === user.id && (
                      <Check className="ml-auto h-4 w-4 shrink-0" />
                    )}
                  </CommandItem>
                ))}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    </div>
  );
}
