import { useGetDocumentData } from '../../hooks';
import useProcessOwner from '@fluency/ui/hooks/documentation/useProcessOwner';
import { useEffect, useMemo } from 'react';
import { toast } from '@fluency/ui/components/ui/use-toast';
import { ViewModeMetadata } from './ViewModeMetadata';
import EditModeMetadata from './EditModeMetadata';
import { UnifiedDocumentData } from '../../types/docSummaryType';
import { Button } from '@fluency/ui/components/ui/button';
import {
  Dialog,
  DialogHeader,
  DialogContent,
  DialogTitle,
  DialogTrigger,
} from '@fluency/ui/components/ui/dialog';
import { PencilLine } from 'lucide-react';

interface DocumentSummaryProps {
  id: string;
  isEditMode: boolean;
}

const DocumentSummary = ({ id, isEditMode }: DocumentSummaryProps) => {
  const { data: docData, isLoading: isLoadingData } = useGetDocumentData(id);
  const {
    initialOwner,
    isLoadingInitialOwner,
    isErrorInitialOwner,
    users,
    isLoadingUsers,
    isErrorUsers,
  } = useProcessOwner({ documentId: id });

  const unifiedData: UnifiedDocumentData | undefined = useMemo(() => {
    return {
      documentation: docData?.documentation,
      processOwner: initialOwner
        ? {
            id: initialOwner.processOwner,
            details: initialOwner.ownerDetails,
          }
        : undefined,
      users: users ?? [],
    };
  }, [docData, initialOwner, users]);

  useEffect(() => {
    if (isErrorInitialOwner || isErrorUsers) {
      toast({
        variant: 'destructive',
        title: 'Error loading process owner data',
        description:
          'Unable to load process owner information. Please try again later.',
      });
    }
  }, [isErrorInitialOwner, isErrorUsers]);

  if (isEditMode) {
    return (
      <>
        <div className="flex flex-row gap-2 items-start">
          <ViewModeMetadata
            data={unifiedData}
            isLoading={{
              owner: isLoadingInitialOwner,
              data: isLoadingData,
            }}
          />

          <Dialog>
            <DialogTrigger asChild>
              <Button
                className="h-7 w-7 text-gray-400"
                variant="ghost"
                size="icon"
              >
                <PencilLine className="h-4 w-4 " />
              </Button>
            </DialogTrigger>

            <DialogContent>
              <DialogHeader>
                <DialogTitle>{'Edit Document Properties'}</DialogTitle>
              </DialogHeader>

              <EditModeMetadata
                documentId={id}
                data={unifiedData}
                isLoading={{
                  users: isLoadingUsers,
                  owner: isLoadingInitialOwner,
                  data: isLoadingData,
                }}
              />
            </DialogContent>
          </Dialog>
        </div>
      </>
    );
  }

  return (
    <div className="flex flex-col w-full">
      <div className="flex items-center justify-between">
        <div className="flex-1">
          <ViewModeMetadata
            data={unifiedData}
            isLoading={{
              owner: isLoadingInitialOwner,
              data: isLoadingData,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default DocumentSummary;
