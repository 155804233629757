import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useToast } from '@fluency/ui/components/ui/use-toast';
import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import { Documentation, DocumentationResponse } from './types/master';
import { DocumentationsResponse } from '@fluency/ui/hooks/documentation/types/GetDocs';
import {
  InfiniteQueryResponse,
  ResourceItem,
  DocumentationResource,
  PageResponse,
} from '../vaults/types/types';
import { ResourceType } from '../fga/types/fga';
import {
  useShallow,
  useWorkflowStore,
} from '@fluency/ui/features/WorkflowMapping/store';

interface UpdateDocumentOptions {
  id: string;
  type: 'document' | 'node';
  invalidateQueries?: string[][];
  suppressToast?: boolean;
}

interface UpdatePayload {
  documentationName?: string;
  documentationDescription?: string;
}

interface UpdateContext {
  previousDocs?: DocumentationsResponse;
  previousDocInfo?: DocumentationResponse;
  previousVaultResources?: InfiniteQueryResponse;
  vaultId?: string;
}

const convertDocumentToResource = (
  doc: Documentation
): DocumentationResource => ({
  documentationId: doc.documentationId,
  documentationName: doc.documentationName,
  createdDate: doc.createdDate,
  duration: doc.duration,
  totalSteps: doc.totalSteps,
  description: doc.description,
  createdBy: doc.createdBy,
  shareId: doc.shareId,
  updatedAt: doc.updatedAt,
  vaultId: doc.vaultId,
  isPublic: doc.isPublic,
  isGlobal: doc.isGlobal,
  isLocked: doc.isLocked,
  vault: doc.vault,
  userInfo: doc.userInfo?.map((user) => ({ userId: user.email })) || [],
  versionInfo: doc.versionInfo,
  permissions: {
    role: doc.permissions.role,
    source: doc.permissions.source,
    details: doc.permissions.details,
  },
});

export const useUpdateDoc = ({
  id,
  type,
  invalidateQueries,
  suppressToast = false,
}: UpdateDocumentOptions) => {
  const { toast } = useToast();
  const { accessToken } = useAuth();
  const queryClient = useQueryClient();

  const { workflow } = useWorkflowStore(
    useShallow((state) => ({
      workflow: state.workflow,
    }))
  );

  const mutation = useMutation<string, Error, UpdatePayload, UpdateContext>({
    mutationFn: async (payload) => {
      if (!id) {
        throw new Error('Document ID is required');
      }

      const apiBaseUrl = import.meta.env.VITE_SERVER_API_URL;
      const endpoint = `/documents/update`;

      // Format the body of the request
      const body = {
        documentationId: id,
        ...payload,
      };

      const response = await fetch(`${apiBaseUrl}${endpoint}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(body),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(
          data.message || `HTTP error! status: ${response.status}`
        );
      }

      return data.message || 'Document updated successfully.';
    },

    onMutate: async (newValue) => {
      const previousDocs = queryClient.getQueryData<DocumentationsResponse>([
        'documentation',
        workflow?.vault.id,
      ]);

      if (previousDocs) {
        const updatedDocs = previousDocs.documentation.map((doc) => {
          if (doc.documentationId === id) {
            return {
              ...doc,
              ...(newValue.documentationName && {
                documentationName: newValue.documentationName,
              }),
              ...(newValue.documentationDescription && {
                description: newValue.documentationDescription,
              }),
            };
          }
          return doc;
        });

        queryClient.setQueryData<DocumentationsResponse>(
          ['documentation', workflow?.vault.id],
          { ...previousDocs, documentation: updatedDocs }
        );
      }

      // If type is node, return previousDocs
      if (type === 'node') {
        return {
          previousDocs: previousDocs,
        };
      }

      // Get current document data
      const previousDocInfo = queryClient.getQueryData<DocumentationResponse>([
        'docInfo',
        id,
      ]);

      if (!previousDocInfo?.documentation) {
        throw new Error('Document not found');
      }

      const vaultId = previousDocInfo.documentation.vaultId;

      // Cancel any outgoing refetches
      await queryClient.cancelQueries({ queryKey: ['docInfo', id] });
      await queryClient.cancelQueries({
        queryKey: ['vaultResources', vaultId, undefined],
      });

      // Create optimistically updated document
      const updatedDoc = {
        ...previousDocInfo.documentation,
        ...(newValue.documentationName && {
          documentationName: newValue.documentationName,
        }),
        ...(newValue.documentationDescription && {
          description: newValue.documentationDescription,
        }),
        updatedAt: new Date().toISOString(),
      };

      // Update docInfo cache
      queryClient.setQueryData<DocumentationResponse>(['docInfo', id], {
        ...previousDocInfo,
        documentation: updatedDoc,
      });

      // Get previous vault resources with correct query key
      const previousVaultResources =
        queryClient.getQueryData<InfiniteQueryResponse>([
          'vaultResources',
          vaultId,
          undefined,
        ]);

      // Update vault resources cache with correct query key
      queryClient.setQueryData<InfiniteQueryResponse>(
        ['vaultResources', vaultId, undefined],
        (old): InfiniteQueryResponse | undefined => {
          if (!old?.pages) return old;

          const newPages = old.pages.map((page: PageResponse) => ({
            ...page,
            items: page.items.map((item: ResourceItem) => {
              if (
                item.type === ResourceType.DOCUMENTATION &&
                (item.resource as DocumentationResource).documentationId === id
              ) {
                return {
                  ...item,
                  resource: convertDocumentToResource(updatedDoc),
                } as ResourceItem;
              }
              return item;
            }),
          }));

          return {
            ...old,
            pages: newPages,
          };
        }
      );

      return {
        previousDocInfo,
        previousVaultResources,
        vaultId,
        previousDocs,
      };
    },

    onError: (error, _variables, context) => {
      if (context?.previousDocs) {
        queryClient.setQueryData(
          ['documentation', workflow?.vault.id],
          context.previousDocs
        );
      }

      // Revert docInfo cache
      if (context?.previousDocInfo) {
        queryClient.setQueryData(['docInfo', id], context.previousDocInfo);
      }

      // Revert vault resources cache with correct query key
      if (context?.vaultId && context?.previousVaultResources) {
        queryClient.setQueryData(
          ['vaultResources', context.vaultId, undefined],
          context.previousVaultResources
        );
      }

      if (suppressToast) return;

      toast({
        variant: 'destructive',
        title: 'Update failed',
        description:
          error.message || 'Failed to update document. Please try again.',
      });
    },

    onSuccess: (_message, _variables, _context) => {
      if (type === 'node') {
        if (suppressToast) return;
        toast({
          title: 'Success',
          description: `Document has been updated successfully`,
        });
      } else {
        let updateType = '';
        if (_variables.documentationName) {
          updateType = 'title';
        } else if (_variables.documentationDescription) {
          updateType = 'description';
        }
        if (suppressToast) return;
        toast({
          title: 'Success',
          description: `Document ${updateType} has been updated successfully`,
        });
      }
    },

    onSettled: (_data, _error, _variables, context) => {
      // Invalidate queries with correct query key
      if (context?.vaultId) {
        queryClient.invalidateQueries({
          queryKey: ['vaultResources', context.vaultId, undefined],
        });
      }
      queryClient.invalidateQueries({ queryKey: ['docInfo', id] });
      if (invalidateQueries) {
        invalidateQueries.forEach((queryKey) => {
          queryClient.invalidateQueries({ queryKey });
        });
      }
    },
  });

  return {
    mutateAsync: mutation.mutateAsync,
    updateDoc: mutation.mutate,
    isLoading: mutation.isPending,
    isError: mutation.isError,
    error: mutation.error,
  };
};

export default useUpdateDoc;
