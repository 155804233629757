import useGetDocs from '@fluency/ui/hooks/documentation/useGetDocs';
import { useGetWorkflow } from '@fluency/ui/hooks/workflows/useGetWorkflow';
import { useShallow, useWorkflowStore } from '../store';
import { useEffect } from 'react';
import useWorkflowHelpers from './useWorkflowHelpers';

interface WorkflowHandlersProps {
  workflowId: string;
}

const useWorkflowSync = ({ workflowId }: WorkflowHandlersProps) => {
  const {
    workflow,
    documents,
    updateType,
    setWorkflow,
    setNodes,
    setEdges,
    setDocuments,
  } = useWorkflowStore(
    useShallow((state) => ({
      workflow: state.workflow,
      documents: state.documents,
      updateType: state.updateType,
      setWorkflow: state.setWorkflow,
      setNodes: state.setNodes,
      setEdges: state.setEdges,
      setDocuments: state.setDocuments,
    }))
  );

  const { syncDocNode } = useWorkflowHelpers({ workflowId });

  // Fetch data
  const {
    data: workflowData,
    isLoading: isWorkflowLoading,
    isError: isWorkflowError,
  } = useGetWorkflow(workflowId);

  const {
    data: documentsData,
    isLoading: isDocumentsLoading,
    isError: isDocumentsError,
  } = useGetDocs({
    vaultId: workflow?.vault.id ?? '',
  });

  // Sync the store data with the fetched data
  useEffect(() => {
    if (workflowData) {
      // As we have two different save button (header and for nodes)
      // We need to differentiate the update type
      if (updateType === 'header') {
        setWorkflow(workflowData);
      } else if (updateType === 'nodes') {
        setNodes(workflowData.nodes);
        setEdges(workflowData.edges);
      } else {
        setWorkflow(workflowData);
        setNodes(workflowData.nodes);
        setEdges(workflowData.edges);
      }
    }
  }, [workflowData, updateType]);

  useEffect(() => {
    if (documentsData) {
      setDocuments(documentsData.documentation);
    }
  }, [documentsData]);

  // Sync documentNode with the document data
  // This should happen everytime the document is updated or the workflow is updated
  useEffect(() => {
    setNodes((prevNodes) => {
      return prevNodes.map((node) => {
        return node.type === 'documentNode' ? syncDocNode(node) : node;
      });
    });
  }, [documents, workflowData]);

  return {
    isWorkflowError,
    isWorkflowLoading,
    isDocumentsLoading,
    isDocumentsError,
  };
};

export default useWorkflowSync;
