import React, { useState } from 'react';
import { NodeProps } from 'reactflow';
import { Card } from '@fluency/ui/components/ui/card';
import { FileText, Trash, Type, UserPlus } from 'lucide-react';
import { createNodeHandles } from '../../utils/createNodeHandles';
import FloatingToolbar from '@fluency/ui/components/common/FloatingToolbar';
import { DocumentNodeDialog } from '../helpers/DocumentNodeDialog';
import { DocumentNodeData } from '@fluency/ui/hooks/workflows/types/types';
import { usePermissionGate } from '@fluency/ui/providers/permissions/PermissionGateProvider';
import { useResourcePermissions } from '@fluency/ui/hooks/fga/useResourcePermissions';
import RequestDialog from '@fluency/ui/features/ViewDocument/components/requests/components/RequestDialog';
import { cn } from '@fluency/ui/lib/utils';
import MultipleAvatars from '@fluency/ui/components/common/MultipleAvatars';
import { useResourceRequests } from '@fluency/ui/features/ViewDocument/components/requests/hooks/useResourceRequests';
import OwnerAvatar from '../helpers/OwnerAvatar';
import AssignmentStatus from '@fluency/ui/components/common/AssignmentStatus';

const DocumentNode: React.FC<NodeProps<DocumentNodeData>> = ({ data, id }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [requestDialogOpen, setRequestDialogOpen] = useState(false);

  // Permissions to ask for request on the document
  const { hasPermissions } = usePermissionGate();
  const { data: permissions, isLoading: isResourcePermissionsLoading } =
    useResourcePermissions({
      resourceType: 'DOCUMENTATION',
      resourceId: data.documentId ?? '',
    });
  const isManager = permissions?.data.bearer.effectiveRole.role === 'MANAGER';
  const canRequest = hasPermissions(['resource-requests:create']) && isManager;

  // View the requests list
  const { data: requests = [], isLoading: isResourceRequestsLoading } =
    useResourceRequests(data.documentId ?? '');

  let assignedUsers = requests.reduce(
    (acc, req) => {
      const id = req.assignments[0]?.recipientId ?? '';
      let name =
        (
          req.assignments[0]?.recipient.firstName +
          ' ' +
          req.assignments[0]?.recipient.lastName
        ).trim() ?? '';

      // Fallback to email if user has no name
      if (!name) {
        name = req.assignments[0]?.recipient.email ?? '';
      }

      const status = req.assignments[0]?.status ?? 'PENDING';
      // Remove duplicates
      const listIds = acc.map((user) => user.id);
      if (!listIds.includes(id)) {
        acc.push({ id, name, status });
      }

      return acc;
    },
    [] as { id: string; name: string; status: string }[]
  );

  assignedUsers = [...assignedUsers];

  // Get the latest assignment status
  const sortedAssignments = requests.sort((a, b) => {
    const dateA = new Date(a.updatedAt);
    const dateB = new Date(b.updatedAt);

    return dateB.getTime() - dateA.getTime();
  });
  const latestAssignmentStatus = sortedAssignments[0]?.assignments[0]?.status;

  // Show Loading state for assigned users
  // There is a slight delay in the isLoading state, so we need to check if the cache data are loaded
  const assignedUsersIsLoading =
    isResourceRequestsLoading ||
    !requests ||
    isResourcePermissionsLoading ||
    !permissions;

  const handleDelete = (event: React.MouseEvent) => {
    event.stopPropagation();
    data.onDelete?.();
  };

  const handleClick = () => {
    if (data.isViewMode) {
      data.onView?.();
    }
  };

  const tools = [
    {
      icon: <Type className="w-4 h-4" />,
      label: 'Edit Document',
      onClick: () => {
        setDialogOpen(true);
      },
    },
    ...(canRequest
      ? [
          {
            icon: <UserPlus className="w-4 h-4" />,
            label: 'Request',
            onClick: () => setRequestDialogOpen(true),
          },
        ]
      : []),
    {
      icon: <Trash className="h-4 w-4" />,
      onClick: handleDelete,
      label: 'Remove Document',
      className: 'text-red-600 hover:text-red-700 hover:bg-red-50',
    },
  ];

  return (
    <>
      <FloatingToolbar customTools={tools} hide={data.isViewMode}>
        <Card
          className={cn(
            `relative w-fit min-w-64 max-w-80 h-[96px] px-3 py-2 rounded-md border-2 flex gap-4 items-start`,
            data.isViewMode && 'cursor-pointer hover:bg-gray-50',
            data.isActive ? 'border-fluency-500' : '',
            data.isNewDocument && 'border-dashed'
          )}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          onClick={handleClick}
        >
          <div className="flex flex-col justify-between h-full min-w-0 w-full">
            <div className="flex items-center gap-2">
              <FileText className="h-8 w-8 bg-fluency-50 p-2 rounded-md text-fluency-600 flex-shrink-0" />
              <div className="min-w-0 flex-1">
                <h3 className="text-sm font-medium truncate">
                  {data.documentName}
                </h3>
                <p className="text-xs text-muted-foreground truncate">
                  {data.description}
                </p>
              </div>
            </div>
            <div className="flex-1 w-full flex flex-row items-center justify-between">
              <div className="w-8 flex items-center justify-center">
                <OwnerAvatar name={data.owner ?? ''} />
              </div>
              <div className="flex items-center justify-start flex-1 min-w-0">
                <MultipleAvatars
                  data={assignedUsers}
                  loading={assignedUsersIsLoading}
                  showNames={false}
                  maxAvatars={3}
                />
              </div>
              {/* Status of the Assignment */}
              <div className="w-fit">
                <AssignmentStatus status={latestAssignmentStatus} />
              </div>
            </div>
          </div>

          {/* Handles */}
          {createNodeHandles({
            id,
            isViewMode: data.isViewMode,
            isHovered,
          })}
        </Card>
      </FloatingToolbar>

      {/* Empty document node at first will have no documentId - while waiting to be created
      Then after creation successful will replace the empty doc node, with proper doc created at the server */}
      {data.documentId && (dialogOpen || requestDialogOpen) && (
        <>
          {/* Name Edit Dialog */}
          <DocumentNodeDialog
            open={dialogOpen}
            setOpen={setDialogOpen}
            documentId={data.documentId}
            defaultValue={{
              title: data.documentName,
              description: data.description,
            }}
          />

          {/* Request From Dialog */}
          <RequestDialog
            isOpen={requestDialogOpen}
            onOpenChange={setRequestDialogOpen}
            documentId={data.documentId}
            isManager={isManager}
          />
        </>
      )}
    </>
  );
};

export default DocumentNode;
