import { useMutation } from '@tanstack/react-query';
import { useToast } from '@fluency/ui/components/ui/use-toast';
import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import useUpdateDoc from './useUpdateDoc';

export const useUploadVideo = ({ documentId }: { documentId: string }) => {
  const { toast } = useToast();
  const { accessToken } = useAuth();
  const { mutateAsync } = useUpdateDoc({
    id: documentId,
    type: 'document',
    suppressToast: true,
  });

  const mutation = useMutation<
    any,
    Error,
    {
      processName: string;
      processDescription: string;
      video: File;
      duration: number;
    }
  >({
    mutationFn: async (payload) => {
      const apiBaseUrl = import.meta.env.VITE_SERVER_API_URL;
      const endpoint = `/videos/process`;

      // Create FormData and append all fields
      const formData = new FormData();
      formData.append('processName', payload.processName);
      formData.append('processDescription', payload.processDescription);
      formData.append('documentId', documentId);
      formData.append('video', payload.video);
      formData.append('videoDuration', Math.floor(payload.duration).toString());

      const response = await fetch(`${apiBaseUrl}${endpoint}`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          // Don't set Content-Type - browser will set it automatically with boundary
        },
        body: formData,
      });

      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(
          errorData.message || 'Failed to upload video. Please try again later.'
        );
      }

      return response.json();
    },
    onError: (error) => {
      toast({
        variant: 'destructive',
        title: 'Upload Failed',
        description:
          error.message || 'Failed to upload video. Please try again.',
      });
    },
    onSuccess: async (_message, variables) => {
      try {
        await mutateAsync({
          documentationName: variables.processName,
          documentationDescription: variables.processDescription,
        });
        toast({
          title: 'Success',
          description: `Video has been successfully uploaded and processed into documentation.`,
        });
        window.location.reload();
      } catch (error) {
        toast({
          variant: 'destructive',
          title: 'Document Update Failed',
          description:
            'Failed to update the document name and description. Please try again.',
        });
      }
    },
  });

  return {
    mutateAsync: mutation.mutateAsync,
    isLoading: mutation.isPending,
    isError: mutation.isError,
    error: mutation.error,
  };
};
