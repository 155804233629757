import { useState } from 'react';
import { Button } from '@fluency/ui/components/ui/button';
import { Clock, Loader2 } from 'lucide-react';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from '@fluency/ui/components/ui/dialog';
import { Calendar } from '@fluency/ui/components/ui/calendar';
import { toast } from '@fluency/ui/components/ui/use-toast';
import { Alert, AlertDescription } from '@fluency/ui/components/ui/alert';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { useSubscriptionOperations } from './hooks/useSubscriptionOperations';

interface FormValues {
  selectedDate: Date | undefined;
}

interface ExtendTrialButtonProps {
  orgId: string;
  currentTrialEndDate: Date;
  onSuccess?: () => void;
}

const ExtendTrialButton = ({
  orgId,
  currentTrialEndDate,
  onSuccess,
}: ExtendTrialButtonProps) => {
  const { extendTrial, isLoadingExtendTrial } = useSubscriptionOperations({
    orgId,
  });

  const [isOpen, setIsOpen] = useState(false);

  const form = useForm<FormValues>({
    defaultValues: {
      selectedDate: undefined,
    },
  });

  const {
    formState: { errors },
    watch,
    setValue,
    handleSubmit,
  } = form;
  const selectedDate = watch('selectedDate');

  const onSubmit = async (data: FormValues) => {
    if (!data.selectedDate) {
      return;
    }

    // Calculate days difference
    const diffTime =
      data.selectedDate.getTime() - currentTrialEndDate.getTime();
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    if (diffDays <= 0) {
      form.setError('selectedDate', {
        type: 'manual',
        message: 'New date must be after current trial end date',
      });
      return;
    }

    try {
      await extendTrial({ addedDays: diffDays });
      toast({
        title: 'Success',
        description: `Successfully extended trial by ${diffDays} days`,
      });
      setIsOpen(false);
      onSuccess?.();
    } catch (error) {
      console.error('Failed to extend trial', error);
      toast({
        title: 'Error',
        description: 'Failed to extend trial period',
        variant: 'destructive',
      });
    } finally {
      setValue('selectedDate', undefined);
    }
  };

  const handleOpenChange = (open: boolean) => {
    if (!open) {
      form.reset();
    }
    setIsOpen(open);
  };

  return (
    <Dialog open={isOpen} onOpenChange={handleOpenChange}>
      <Button size="sm" onClick={() => setIsOpen(true)} className="ml-2">
        <Clock className="w-4 h-4 mr-2" />
        Extend Trial
      </Button>
      <DialogContent className="w-auto p-6">
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogHeader>
            <DialogTitle>Extend Trial Period</DialogTitle>
            <DialogDescription>
              Select a new end date to extend the trial period
            </DialogDescription>
          </DialogHeader>
          <div className="py-4">
            <div className="mb-2 flex items-center text-sm text-muted-foreground">
              <div className="flex items-center">
                <div className="h-4 w-4 rounded border-2 border-primary mr-2" />
                <span>
                  Current Trial End Date:{' '}
                  {moment(currentTrialEndDate).format('MMM D, YYYY')}
                </span>
              </div>
            </div>
            <div className="flex items-start justify-center">
              <Calendar
                mode="single"
                selected={selectedDate}
                onSelect={(date) => setValue('selectedDate', date)}
                fromDate={currentTrialEndDate}
                className="border-0"
                defaultMonth={currentTrialEndDate}
                modifiers={{
                  original: currentTrialEndDate,
                }}
                modifiersStyles={{
                  original: {
                    fontWeight: 'bold',
                    borderColor: 'hsl(var(--primary))',
                    borderWidth: '2px',
                    borderStyle: 'solid',
                    borderRadius: '4px',
                  },
                }}
                disabled={(date) => date <= currentTrialEndDate}
              />
            </div>

            {errors.selectedDate && (
              <Alert variant="destructive" className="mt-4">
                <AlertDescription>
                  {errors.selectedDate.message}
                </AlertDescription>
              </Alert>
            )}
          </div>

          <DialogFooter>
            <Button
              type="button"
              variant="outline"
              onClick={() => handleOpenChange(false)}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              disabled={isLoadingExtendTrial || !selectedDate}
            >
              {isLoadingExtendTrial ? (
                <>
                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                  Processing...
                </>
              ) : (
                'Confirm'
              )}
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default ExtendTrialButton;
