import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@fluency/ui/components/ui/dialog';
import { Button } from '@fluency/ui/components/ui/button';
import { Upload, X } from 'lucide-react';
import { Badge } from '@fluency/ui/components/ui/badge';
import { Alert, AlertDescription } from '@fluency/ui/components/ui/alert';
import { Input } from '@fluency/ui/components/ui/input';
import { Textarea } from '@fluency/ui/components/ui/textarea';
import { Label } from '@fluency/ui/components/ui/label';
import { useState, useRef } from 'react';
import { useUploadVideo } from '@fluency/ui/hooks/documentation/useUpdateVideo';
import { ScrollArea } from '@fluency/ui/components/ui/scroll-area';

interface VideoFile extends File {
  duration?: number;
}

interface UploadVideoButtonProps {
  documentId: string;
}

const UploadVideoButton: React.FC<UploadVideoButtonProps> = ({
  documentId,
}) => {
  const [error, setError] = useState<string>('');
  const [selectedVideo, setSelectedVideo] = useState<VideoFile | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isDragging, setIsDragging] = useState(false);
  const [processTitle, setProcessTitle] = useState<string>('');
  const [processDescription, setProcessDescription] = useState<string>('');
  const { mutateAsync, isLoading } = useUploadVideo({ documentId });
  const fileInputRef = useRef<HTMLInputElement>(null);

  const checkVideoDuration = async (file: File): Promise<number> => {
    return new Promise((resolve, reject) => {
      const video = document.createElement('video');
      video.preload = 'metadata';

      video.onloadedmetadata = () => {
        window.URL.revokeObjectURL(video.src);
        resolve(video.duration);
      };

      video.onerror = () => {
        reject('Error loading video');
      };

      video.src = URL.createObjectURL(file);
    });
  };

  const handleVideoFile = async (file: File) => {
    try {
      const duration = await checkVideoDuration(file);

      if (duration > 120) {
        setError('Video duration must not exceed 2 minutes');
        setSelectedVideo(null);
        if (fileInputRef.current) fileInputRef.current.value = '';
      } else {
        const videoFile: VideoFile = file;
        videoFile.duration = duration;
        setError('');
        setSelectedVideo(videoFile);
      }
    } catch (err) {
      setError('Error checking video duration');
      setSelectedVideo(null);
      if (fileInputRef.current) fileInputRef.current.value = '';
    }
  };

  const handleVideoUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      await handleVideoFile(file);
    }
  };

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = async (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(false);

    const droppedFile = e.dataTransfer.files[0];
    if (droppedFile) {
      await handleVideoFile(droppedFile);
    }
  };

  const handleUpload = async () => {
    if (!selectedVideo || !processTitle.trim() || !processDescription.trim()) {
      setError('Please fill in all required fields');
      return;
    }

    try {
      await mutateAsync({
        processName: processTitle.trim(),
        processDescription: processDescription.trim(),
        video: selectedVideo,
        duration: selectedVideo.duration || 0,
      });
    } catch (err) {
      setError('Failed to upload video. Please try again later.');
    } finally {
      handleCancel();
      setIsOpen(false);
    }
  };

  const handleCancel = () => {
    setSelectedVideo(null);
    setProcessTitle('');
    setProcessDescription('');
    setError('');
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  return (
    <Dialog
      open={isOpen}
      onOpenChange={(open) => {
        setIsOpen(open);
        if (!open) handleCancel();
      }}
    >
      <DialogTrigger asChild>
        <Button
          variant="outline"
          className="relative flex flex-col items-center justify-center w-32 h-32 p-2 hover:scale-105 transition-transform"
        >
          <div className="flex-1 flex items-end justify-center">
            <Upload className="h-[80%] w-[80%]" strokeWidth={2} />
          </div>
          <div>Upload Video</div>
          <Badge
            variant="outline"
            className="absolute top-2 right-2 text-xs bg-fluency-500 text-white"
          >
            BETA
          </Badge>
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Upload Video Recording</DialogTitle>
        </DialogHeader>
        <div className="max-h-[min(70vh,500px)]">
          <ScrollArea className="h-full">
            <div className="grid gap-4 px-1">
              <div className="space-y-2">
                <Label htmlFor="processTitle">
                  Process Title <span className="text-red-500">*</span>
                </Label>
                <Input
                  id="processTitle"
                  value={processTitle}
                  onChange={(e) => setProcessTitle(e.target.value)}
                  placeholder="Enter process title"
                />
              </div>

              <div className="space-y-2">
                <Label htmlFor="processDescription">
                  Process Description <span className="text-red-500">*</span>
                </Label>
                <Textarea
                  id="processDescription"
                  value={processDescription}
                  onChange={(e) => setProcessDescription(e.target.value)}
                  placeholder="Enter process description"
                  className="min-h-[100px]"
                />
              </div>

              <div className="space-y-2">
                <Label htmlFor="video">
                  Video <span className="text-red-500">*</span>
                </Label>
                <div
                  onDragOver={handleDragOver}
                  onDragLeave={handleDragLeave}
                  onDrop={handleDrop}
                  onClick={() => fileInputRef.current?.click()}
                  className={`
                relative cursor-pointer border-2 border-dashed rounded-lg p-4 
                hover:border-primary hover:bg-primary/5 transition-colors
                ${
                  isDragging ? 'border-primary bg-primary/5' : 'border-gray-200'
                }
              `}
                >
                  <Input
                    id="video"
                    ref={fileInputRef}
                    type="file"
                    accept="video/mp4,video/webm"
                    onChange={handleVideoUpload}
                    className="hidden"
                  />
                  <div className="text-center text-sm text-gray-600">
                    {selectedVideo ? (
                      <div className="flex flex-col w-full gap-2">
                        <div className="flex items-start justify-between gap-2">
                          <div className="flex-1 min-w-0 text-left break-all pr-2">
                            <p className="line-clamp-2">{selectedVideo.name}</p>
                          </div>
                          <Button
                            variant="ghost"
                            size="icon"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              handleCancel();
                            }}
                            className="h-8 w-8 flex-shrink-0"
                          >
                            <X className="h-4 w-4" />
                          </Button>
                        </div>
                        <div className="text-xs text-slate-500">
                          Duration: {selectedVideo.duration?.toFixed(1)}s
                        </div>
                      </div>
                    ) : (
                      'Drag and drop a video here or click to select'
                    )}
                  </div>
                </div>
              </div>

              {error && (
                <Alert variant="destructive">
                  <AlertDescription>{error}</AlertDescription>
                </Alert>
              )}

              <div className="text-xs text-slate-500 text-center">
                Maximum duration: 2 minutes video
              </div>

              <div className="flex gap-2">
                <Button
                  variant="outline"
                  onClick={handleCancel}
                  size="sm"
                  disabled={isLoading}
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleUpload}
                  disabled={
                    !selectedVideo ||
                    !processTitle.trim() ||
                    !processDescription.trim() ||
                    isLoading
                  }
                  className="flex-1"
                  size="sm"
                >
                  {isLoading ? 'Uploading...' : 'Upload Video'}
                </Button>
              </div>
            </div>
          </ScrollArea>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default UploadVideoButton;
