import React, { useState } from 'react';
import { NodeProps, Position } from 'reactflow';
import { X } from 'lucide-react';
import { createNodeHandles } from '../../utils/createNodeHandles';
import Resizer from './Resizer';
import { useShallow, useWorkflowStore } from '../../store';
import { StartNodeData } from '@fluency/ui/hooks/workflows/types/types';

const StartNode: React.FC<NodeProps<StartNodeData>> = ({ id, data }) => {
  const [isHovered, setIsHovered] = useState(false);
  const { getNode } = useWorkflowStore(
    useShallow((state) => ({
      getNode: state.getNode,
    }))
  );

  const node = getNode(id);
  const width = node?.width ?? 64;
  const height = node?.height ?? 64;

  // Choose the bigger between width and height to set the const size
  const size = width > height ? width : height;

  const handleDelete = (event: React.MouseEvent) => {
    event.stopPropagation();
    data.onDelete?.();
  };

  return (
    <div
      className="relative"
      onMouseEnter={() => !data.isViewMode && setIsHovered(true)}
      onMouseLeave={() => !data.isViewMode && setIsHovered(false)}
      style={{
        width: size,
        height: size,
      }}
    >
      {isHovered && !data.isViewMode && (
        <button
          onClick={handleDelete}
          className="absolute -top-4 -right-4 bg-red-500 text-white rounded-full p-1 hover:bg-red-600 transition-colors z-10"
        >
          <X size={16} />
        </button>
      )}
      {/* Node Resizer */}
      {isHovered && !data.isViewMode && (
        <Resizer className="bottom-1 right-1" />
      )}
      <div className="w-full h-full rounded-full border-4 border-black flex items-center justify-center font-semibold" />
      {createNodeHandles({
        id,
        isHovered: isHovered,
        isViewMode: data.isViewMode,
        hideTargetHandles: [
          Position.Top,
          Position.Bottom,
          Position.Left,
          Position.Right,
        ],
      })}
    </div>
  );
};

export default StartNode;
