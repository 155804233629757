import { useState } from 'react';
import { Card, CardContent } from '@fluency/ui/components/ui/card';
import { Button } from '@fluency/ui/components/ui/button';
import { FileQuestion, Lock } from 'lucide-react';
import RecordButton from './RecordButton';
import WriteManuallyButton from './WriteManuallyButton';
import ModifiedWelcome from './RecordButtonWA';
import { useElectron } from '@fluency/ui/providers/electron/ElectronContext';
import { usePermissionGate } from '@fluency/ui/providers/permissions/PermissionGateProvider';
import RequestDialog from './requests/components/RequestDialog';
import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import UploadVideoButton from './UploadVideoButton';

interface EmptyDocActionProps {
  documentId: string;
  isVaultLocked: boolean;
  setIsEditMode: (value: boolean) => void;
}

export default function EmptyDocAction({
  documentId,
  setIsEditMode,
  isVaultLocked,
}: EmptyDocActionProps) {
  const { hasPermissions } = usePermissionGate();
  const canRequestResource = hasPermissions(['resource-requests:create']);
  const canUpdate = hasPermissions(['documents:update']);
  const [activeTip, setActiveTip] = useState('');
  const [showRequestDialog, setShowRequestDialog] = useState(false);
  const { isElectron } = useElectron();
  const { isFluencyAccount } = useAuth();

  if (!canRequestResource && !canUpdate) {
    return (
      <Card className="w-full h-96">
        <CardContent className="flex flex-col items-center justify-center h-full">
          <h2 className="text-lg font-semibold text-gray-700">
            No Permission to Create or Edit Documents
          </h2>
          <p className="mt-2 text-gray-600">
            Contact your administrator for access.
          </p>
        </CardContent>
      </Card>
    );
  }

  if (isVaultLocked) {
    return (
      <Card className="w-full h-96">
        <CardContent className="flex flex-col items-center justify-center h-full">
          <Lock className="h-8 w-8 text-gray-400 mb-2" />
          <h2 className="font-semibold text-gray-700">
            Document is Currently Locked
          </h2>
          <p className="mt-2 text-gray-600 text-sm text-center">
            This document is empty and cannot be edited while the vault is
            locked.
            <br />
            Unlock the vault to begin adding content.
          </p>
        </CardContent>
      </Card>
    );
  }

  return (
    <>
      <Card className="w-full h-96">
        <CardContent className="flex flex-col items-center justify-center h-full">
          <h2 className="mb-4 font-semibold">
            Choose how to create your documentation
          </h2>
          <div className="flex gap-4 items-center mb-4">
            {canRequestResource && (
              <Button
                variant="secondary"
                className="flex flex-col items-center justify-center w-28 h-28 p-2 hover:scale-105 transition-transform text-gray-500 hover:text-primary"
                onClick={() => setShowRequestDialog(true)}
                onMouseEnter={() =>
                  setActiveTip('Request a recording from a team member')
                }
                onMouseLeave={() => setActiveTip('')}
              >
                <FileQuestion className="h-10 w-10 mb-2" />
                <span className="text-xs text-center font-medium">
                  Request
                  <br />
                  Recording
                </span>
              </Button>
            )}

            {isElectron && canUpdate && (
              <>
                <RecordButton
                  documentId={documentId}
                  className="flex flex-col items-center justify-center w-32 h-32 p-2 hover:scale-105 transition-transform"
                  iconClassName="h-14 w-14 -mt-1"
                  labelClassName="text-sm text-center font-medium"
                  onMouseEnter={() =>
                    setActiveTip('Record your screen to capture a process')
                  }
                  onMouseLeave={() => setActiveTip('')}
                />
              </>
            )}

            {!isElectron && canUpdate && <ModifiedWelcome />}
            {isFluencyAccount && <UploadVideoButton documentId={documentId} />}

            {canUpdate && (
              <WriteManuallyButton
                onMouseEnter={() =>
                  setActiveTip('Write your documentation manually')
                }
                onMouseLeave={() => setActiveTip('')}
                docId={documentId}
                setIsEditMode={setIsEditMode}
              />
            )}
          </div>
          <div
            className={`h-6 text-sm text-gray-600 transition-all duration-300 ease-in-out ${
              activeTip
                ? 'opacity-100 transform scale-100'
                : 'opacity-0 transform scale-95'
            }`}
          >
            {activeTip}
          </div>
        </CardContent>
      </Card>

      {canRequestResource && (
        <RequestDialog
          isOpen={showRequestDialog}
          onOpenChange={setShowRequestDialog}
          documentId={documentId}
          isManager={true}
        />
      )}
    </>
  );
}
