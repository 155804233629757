import { Checkbox } from '@fluency/ui/components/ui/checkbox';
import { Step, stepTypeColors } from '../types/index';
import { getStepSummaryTypeIcon } from '../../../util/iconHelper';

interface StepItemProps {
  step: Step;
  isActive: boolean;
  isSelected: boolean;
  isDragged: boolean;
  hasSelectedSteps: boolean;
  onSelect: (stepId: string, e: React.MouseEvent) => void;
  onDragStart: (e: React.DragEvent<HTMLDivElement>, step: Step) => void;
  onDragEnd: (e: React.DragEvent<HTMLDivElement>) => void;
  onDrop: (e: React.DragEvent<HTMLDivElement>) => void;
  onClick: () => void;
  showDragIndicator?: boolean;
  position?: 'top' | 'bottom';
}

export function StepItem({
  step,
  isActive,
  isSelected,
  isDragged,
  hasSelectedSteps,
  onSelect,
  onDragStart,
  onDragEnd,
  onDrop,
  onClick,
  showDragIndicator,
  position,
}: StepItemProps) {
  const handleContentClick = (e: React.MouseEvent<HTMLDivElement>) => {
    // If the click is on the checkbox area, let it handle its own click
    if ((e.target as HTMLElement).closest('.checkbox-wrapper')) {
      return;
    }

    if (hasSelectedSteps) {
      onSelect(step.stepId, e);
    } else {
      onClick();
    }
  };

  return (
    <div
      data-step-id={step.stepId}
      draggable
      className="relative group px-1 cursor-grab active:cursor-grabbing"
      onDragStart={(e) => onDragStart(e, step)}
      onDragEnd={onDragEnd}
      onDrop={onDrop}
      onClick={handleContentClick}
    >
      {showDragIndicator && position === 'top' && (
        <div className="absolute -top-1 left-0 right-0 h-0.5 bg-blue-500 rounded-full" />
      )}
      <div
        className={`
          relative flex items-center
          transition-all duration-200 ease-in-out rounded-lg
          ${stepTypeColors[step.stepType] || 'bg-white'}
          ${isSelected ? 'ring-2 ring-blue-500' : ''}
          ${isDragged ? 'opacity-50' : 'opacity-100'}
          ${isActive ? '!bg-gray-200 !ring-1 !ring-blue-500/50' : ''}
          hover:bg-gray-50 p-2
        `}
      >
        <div className="flex flex-col w-full gap-1">
          <div className="flex items-center justify-between">
            <div className="flex items-center text-xs">
              <span className="min-w-[12px] flex items-center">
                {step.stepType === 'STEP'
                  ? `${step.stepNumber}.`
                  : getStepSummaryTypeIcon(step.stepType)}
              </span>
              <span
                className="overflow-hidden whitespace-nowrap text-ellipsis max-w-[160px] ml-0.5"
                title={step.stepDescription}
              >
                {step.stepDescription}
              </span>
            </div>
            <div className="checkbox-wrapper relative bg-white/90 rounded-md p-0.5 shadow-sm flex z-10">
              <Checkbox
                checked={isSelected}
                onCheckedChange={() => {
                  onSelect(step.stepId, {
                    stopPropagation: () => {},
                  } as React.MouseEvent);
                }}
                className="h-4 w-4 data-[state=checked]:bg-blue-600 cursor-pointer"
              />
            </div>
          </div>

          {step.screenshots?.[0] && (
            <div className="relative">
              <img
                src={step.screenshots[0].downloadUrl}
                alt={`${step.stepNumber}`}
                className="w-full object-cover rounded"
              />
            </div>
          )}
        </div>
      </div>
      {showDragIndicator && position === 'bottom' && (
        <div className="absolute -bottom-1 left-0 right-0 h-0.5 bg-blue-500 rounded-full" />
      )}
    </div>
  );
}
