import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@fluency/ui/components/ui/card';
import { Button } from '@fluency/ui/components/ui/button';
import { Badge } from '@fluency/ui/components/ui/badge';
import { CreditCard, RefreshCw } from 'lucide-react';
import useSubscription from '@fluency/ui/hooks/subscription/useSubscription';
import { useOrganisationOperations } from '../Organisation/hooks/useOrganisationOperations';
import useInvoices from '@fluency/ui/hooks/subscription/useGetInvoices';
import { capitalise } from '@fluency/ui/lib';
import CancelSubscriptionButton from './CancelSubscriptionButton';
import usePayments from '@fluency/ui/hooks/subscription/usePayments';
import { Logger } from '../../Logger';
import { toast } from '@fluency/ui/components/ui/use-toast';
import { useState } from 'react';
import { ChangeBillingTypeButton } from './ChangeBillingTypeButton';
import AdjustDueDateButton from './AdjustDueDateButton';
import useClearCacheAdmin from '../hooks/useClearCacheAdmin';
import moment from 'moment';

const formatCurrency = (amount: number, currency: string) => {
  try {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency,
    }).format(amount);
  } catch (error) {
    return amount.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }
};

const formatDate = (dateString: string) => {
  const date = moment(dateString);
  if (!date.isValid()) {
    return dateString;
  }

  return date.format('MMMM D, YYYY');
};

interface TableProps {
  headers: string[];
  rows: (string | number | React.ReactNode)[][];
  className?: string;
}

const Table = ({ headers, rows, className = '' }: TableProps) => {
  return (
    <div className={`relative overflow-x-auto ${className}`}>
      <table className="w-full text-sm text-left">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50">
          <tr>
            {headers.map((header, index) => (
              <th key={index} scope="col" className="px-6 py-3">
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((row, rowIndex) => (
            <tr key={rowIndex} className="bg-white border-b hover:bg-gray-50">
              {row.map((cell, cellIndex) => (
                <td key={cellIndex} className="px-6 py-4">
                  {cell}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const StatusBadge = ({ status }: { status: string }) => {
  const statusColors = {
    active: 'bg-green-500 hover:bg-green-500',
    trialing: 'bg-yellow-500 hover:bg-yellow-500',
    past_due: 'bg-red-500 hover:bg-red-500',
    canceled: 'bg-gray-500 hover:bg-gray-500',
    paid: 'bg-green-500 hover:bg-green-500',
    pending: 'bg-yellow-500 hover:bg-yellow-500',
    failed: 'bg-red-500 hover:bg-red-500',
    succeeded: 'bg-green-500 hover:bg-green-500',
    'Payment Method Needed': 'bg-yellow-500 hover:bg-yellow-500',
  };

  const getStatusColor = (status: string) => {
    return (
      statusColors[status.toLowerCase() as keyof typeof statusColors] ||
      'bg-gray-500 hover:bg-gray-500'
    );
  };

  return (
    <Badge className={`${getStatusColor(status)} text-white`}>
      {status.toUpperCase()}
    </Badge>
  );
};

export const ViewButton = ({ href }: { href: string }) => (
  <Button variant="outline" size="sm" asChild className="h-10">
    <a target="_blank" href={href}>
      Stripe
    </a>
  </Button>
);

const RefreshButton = ({
  onClick,
  isLoading,
}: {
  onClick: () => void;
  isLoading: boolean;
}) => (
  <Button variant="outline" size="icon" onClick={onClick} disabled={isLoading}>
    <RefreshCw className={`h-4 w-4 ${isLoading ? 'animate-spin' : ''}`} />
  </Button>
);

interface SubscriptionDetailsProps {
  orgId: string;
}

const SubscriptionDetails = ({ orgId }: SubscriptionDetailsProps) => {
  const {
    subscriptionStatus,
    isLoadingSubscription,
    cancelSubscription,
    refetchSubscription,
    isFetchingSubscription,
  } = useSubscription({
    organisationId: orgId,
  });
  const { useGetOrganisation } = useOrganisationOperations();
  const { data: organisation, isLoading: isLoadingOrganisation } =
    useGetOrganisation({ orgId });
  const {
    invoices,
    refetch: refetchInvoices,
    isLoading: isLoadingInvoices,
    isFetcing: isFetchingInvoices,
    sendInvoice,
    sendInvoiceLoading,
  } = useInvoices({ orgId });

  // Download pdf link
  const downloadInvoice = async (pdfLink: string) => {
    window.open(pdfLink, '_blank');
  };

  const {
    payments,
    isLoading: isLoadingPayments,
    isFetching: isFetchingPayments,
    refetch: refetchPayments,
  } = usePayments({ orgId });
  const { clearSubscriptionCache, isLoading: isLoadingClearSubscriptionCache } =
    useClearCacheAdmin({ orgId });

  const [sendInvoiceId, setSendInvoiceId] = useState<string | null>(null);

  if (subscriptionStatus?.status === 'absent') {
    return (
      <div className="max-w-6xl mx-auto space-y-6">
        <Card>
          <CardHeader>
            <CardTitle>Subscription Details</CardTitle>
          </CardHeader>
          <CardContent>
            <p className="text-sm text-gray-500">
              This organisation does not have a subscription.
            </p>
          </CardContent>
        </Card>
      </div>
    );
  }

  const subscriptionData = {
    name: organisation?.workos?.name ?? 'N/A',
    email: organisation?.stripe?.email ?? 'N/A',
    subscription: {
      status: subscriptionStatus?.status ?? 'absent',
      plan: subscriptionStatus?.subscription.plan ?? 'N/A',
      trialEnd: subscriptionStatus?.subscription.trialEnd ?? 'N/A',
      nextBillingDate:
        subscriptionStatus?.status !== 'canceled'
          ? subscriptionStatus?.billing.nextBillingDate ?? 'N/A'
          : '--',
      collectionMethod:
        subscriptionStatus?.subscription.collectionMethod ?? 'N/A',
      amount: (subscriptionStatus?.billing.nextBillingAmount ?? 0) / 100,
      currency: subscriptionStatus?.billing.currency ?? 'N/A',
      interval:
        subscriptionStatus?.subscription.products[0]?.prices[0]?.recurring
          .interval ?? 'N/A',
      dueDate: subscriptionStatus?.subscription.dueDate ?? 'N/A',
    },
    usage: {
      subscribedSeats: subscriptionStatus?.usage?.subscribedSeats ?? {
        creator: 0,
        viewer: 0,
      },
      occupiedSeats: subscriptionStatus?.usage?.occupiedSeats ?? {
        creator: 0,
        viewer: 0,
      },
      availableSeats: subscriptionStatus?.usage?.availableSeats ?? {
        creator: 0,
        viewer: 0,
      },
    },
    paymentMethod: subscriptionStatus?.paymentMethod?.card
      ? {
          brand: subscriptionStatus?.paymentMethod?.card?.brand ?? 'N/A',
          last4: subscriptionStatus?.paymentMethod?.card?.last4 ?? 'N/A',
          expMonth: subscriptionStatus?.paymentMethod?.card?.expMonth ?? 0,
          expYear: subscriptionStatus?.paymentMethod?.card?.expYear ?? 0,
        }
      : undefined,
    invoices: invoices?.map((invoice) => ({
      ...invoice,
      amountDue: invoice.amountDue / 100,
      amountPaid: invoice.amountPaid / 100,
    })),
    payments: payments?.map((payment) => ({
      ...payment,
      amount: payment.amount / 100,
    })),
  };

  // Handlers
  const sendInvoiceHandler = async (invoiceId: string) => {
    setSendInvoiceId(invoiceId);
    try {
      await sendInvoice(invoiceId);
      toast({
        title: 'Success',
        description: 'Invoice sent successfully',
      });
    } catch (error) {
      Logger.error('Failed to send invoice', error);
      toast({
        title: 'Error',
        description: 'Failed to send invoice',
        variant: 'destructive',
      });
    } finally {
      setSendInvoiceId(null);
    }
  };

  const invoiceHeaders = ['Date', 'Amount', 'Status', 'Actions'];
  const invoiceRows =
    subscriptionData.invoices?.map((invoice) => [
      formatDate(invoice.created),
      formatCurrency(invoice.amountDue, subscriptionData.subscription.currency),
      <StatusBadge status={invoice.status} />,
      <div className="flex flex-row space-x-2">
        <ViewButton
          href={`https://dashboard.stripe.com${
            window.location.origin.includes('localhost') ? '/test' : ''
          }/invoices/${invoice.id}`}
        />
        <Button
          variant={'outline'}
          onClick={() => downloadInvoice(invoice.pdfLink)}
          className="hover:cursor-pointer"
          asChild
        >
          <span className="text-sm">Download</span>
        </Button>
        {subscriptionData.subscription.collectionMethod === 'send_invoice' && (
          <Button
            variant={'outline'}
            disabled={sendInvoiceLoading}
            onClick={() => sendInvoiceHandler(invoice.id)}
            className="hover:cursor-pointer"
            asChild
          >
            {sendInvoiceLoading && sendInvoiceId === invoice.id ? (
              <span>{'Loading...'}</span>
            ) : (
              <span className="text-sm flex flex-col">
                <span>{'Send Invoice'}</span>
                <span>{'to Owner'}</span>
              </span>
            )}
          </Button>
        )}
      </div>,
    ]) || [];

  const paymentHeaders = ['Date', 'Amount', 'Status', 'Actions'];
  const paymentRows =
    subscriptionData.payments?.map((payment) => [
      formatDate(payment.created),
      formatCurrency(payment.amount, payment.currency),
      <StatusBadge status={payment.status} />,
      <ViewButton
        href={`https://dashboard.stripe.com${
          window.location.origin.includes('localhost') ? '/test' : ''
        }/payments/${payment.id}`}
      />,
    ]) || [];

  if (
    isLoadingSubscription ||
    isLoadingOrganisation ||
    isLoadingInvoices ||
    isLoadingPayments
  ) {
    return <div>Loading...</div>;
  }

  return (
    <div className="max-w-6xl mx-auto space-y-6">
      {/* Customer Overview */}
      <Card>
        <CardHeader className="flex flex-row items-center justify-between">
          <div>
            <CardTitle>Customer Overview</CardTitle>
            <CardDescription>{subscriptionData.email}</CardDescription>
          </div>
          <RefreshButton
            onClick={async () => {
              await refetchSubscription();
              await clearSubscriptionCache();
            }}
            isLoading={
              isFetchingSubscription || isLoadingClearSubscriptionCache
            }
          />
        </CardHeader>
        <CardContent>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            <div className="space-y-2">
              <p className="text-sm text-gray-500">Subscription Status</p>
              <StatusBadge status={subscriptionData.subscription.status} />
            </div>
            <div className="space-y-2">
              <p className="text-sm text-gray-500">Current Plan</p>
              <p className="font-medium">
                {capitalise(subscriptionData.subscription.plan)}
              </p>
            </div>
            <div className="space-y-2">
              <p className="text-sm text-gray-500">Billing Type</p>
              <p className="font-medium">
                {subscriptionData.subscription.collectionMethod ===
                'charge_automatically'
                  ? 'Payment First'
                  : 'Invoice Last'}
              </p>
            </div>
          </div>
        </CardContent>
      </Card>

      {/* Subscription Details */}
      <Card>
        <CardHeader className="flex flex-row items-center justify-between">
          <CardTitle>Subscription Details</CardTitle>
          <RefreshButton
            onClick={async () => {
              await clearSubscriptionCache();
              await refetchSubscription();
            }}
            isLoading={
              isLoadingClearSubscriptionCache || isFetchingSubscription
            }
          />
        </CardHeader>
        <CardContent>
          <div className="space-y-6">
            <div className="grid grid-cols-1 xl:grid-cols-2 gap-4">
              <div className="rounded-lg border bg-card text-card-foreground shadow-sm p-6">
                <p className="text-sm text-gray-500">Plan Amount</p>
                <p className="font-medium">
                  {formatCurrency(
                    subscriptionData.subscription.amount,
                    subscriptionData.subscription.currency
                  )}
                  /{subscriptionData.subscription.interval}
                </p>
              </div>

              <div className="rounded-lg border bg-card text-card-foreground shadow-sm p-6">
                <p className="text-sm text-gray-500">Next Billing Date</p>
                <p className="font-medium">
                  {formatDate(subscriptionData.subscription.nextBillingDate)}
                </p>
              </div>
            </div>

            {/* Adjust Due Date Button */}
            {subscriptionData?.subscription.collectionMethod ===
              'send_invoice' &&
              subscriptionData.subscription.status !== 'trialing' &&
              subscriptionData.subscription.status !== 'canceled' && (
                <div className="rounded-lg border bg-card text-card-foreground shadow-sm">
                  <div className="p-6 space-y-4">
                    <div className="flex items-center justify-between">
                      <div>
                        <p className="text-sm text-gray-500">
                          Current Due Date
                        </p>
                        <p className="font-medium">
                          {formatDate(
                            subscriptionData.subscription.dueDate.toString()
                          )}
                        </p>
                      </div>
                      <AdjustDueDateButton
                        customerId={organisation?.stripeCustomerId ?? ''}
                        currentDueDate={
                          new Date(subscriptionData.subscription.dueDate)
                        }
                        onSuccess={() => {}}
                      />
                    </div>
                    <div className="pt-2 border-t">
                      <p className="text-xs text-muted-foreground">
                        Adjust due date to extend the current payment period
                      </p>
                    </div>
                  </div>
                </div>
              )}

            <div>
              <h3 className="text-base font-medium mb-3">Seat Management</h3>
              {Object.entries(subscriptionData.usage.subscribedSeats).map(
                ([role, seats]) => (
                  <div key={role} className="mb-6 last:mb-0">
                    <h4 className="text-sm font-medium capitalize mb-3">
                      {role} Seats
                    </h4>
                    <div className="grid grid-cols-3 gap-4">
                      <div className="p-4 rounded-lg border border-gray-200">
                        <p className="text-sm text-gray-500 mb-1">Subscribed</p>
                        <p className="text-2xl font-semibold">{seats}</p>
                      </div>
                      <div className="p-4 rounded-lg border border-gray-200">
                        <p className="text-sm text-gray-500 mb-1">Occupied</p>
                        <p className="text-2xl font-semibold">
                          {subscriptionData.usage.occupiedSeats[
                            role as 'creator' | 'viewer'
                          ] || 0}
                        </p>
                      </div>
                      <div className="p-4 rounded-lg border border-gray-200">
                        <p className="text-sm text-gray-500 mb-1">Available</p>
                        <p className="text-2xl font-semibold">
                          {subscriptionData.usage.availableSeats[
                            role as 'creator' | 'viewer'
                          ] || 0}
                        </p>
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>

            {subscriptionData.subscription.status !== 'canceled' && (
              <div className="flex gap-2">
                <CancelSubscriptionButton onCancel={cancelSubscription} />
              </div>
            )}
          </div>
        </CardContent>
      </Card>

      {/* Payment Methods */}
      <Card>
        <CardHeader className="flex flex-row items-center justify-between">
          <CardTitle>Payment Methods</CardTitle>
          <Button
            variant="outline"
            size="icon"
            onClick={async () => {
              await clearSubscriptionCache();
              await refetchSubscription();
            }}
            disabled={isFetchingSubscription || isLoadingClearSubscriptionCache}
          >
            <RefreshCw
              className={`h-4 w-4 ${
                isLoadingClearSubscriptionCache || isFetchingSubscription
                  ? 'animate-spin'
                  : ''
              }`}
            />
          </Button>
        </CardHeader>
        <CardContent>
          <div className="space-y-4">
            {subscriptionData.paymentMethod ? (
              <div className="flex items-center justify-between p-4 border rounded-lg">
                <div className="flex items-center space-x-4">
                  <CreditCard className="w-6 h-6" />
                  <div>
                    <p className="font-medium">
                      {subscriptionData.paymentMethod.brand.toUpperCase()}
                      •••• {subscriptionData.paymentMethod.last4}
                    </p>
                    <p className="text-sm text-gray-500">
                      Expires {subscriptionData.paymentMethod.expMonth}/
                      {subscriptionData.paymentMethod.expYear}
                    </p>
                  </div>
                </div>
                <ChangeBillingTypeButton
                  collectionMethod={
                    subscriptionData.subscription.collectionMethod as
                      | 'charge_automatically'
                      | 'send_invoice'
                  }
                  orgId={orgId}
                  customerId={organisation?.stripeCustomerId ?? ''}
                />
              </div>
            ) : (
              <div className="flex items-center gap-2">
                <CreditCard className="w-6 h-6" />
                <p className="text-sm text-gray-500">
                  No payment method on file
                </p>
              </div>
            )}
          </div>
        </CardContent>
      </Card>

      {/* Invoices */}
      <Card>
        <CardHeader className="flex flex-row items-center justify-between">
          <CardTitle>Invoices</CardTitle>
          <Button
            variant="outline"
            size="icon"
            onClick={() => refetchInvoices()}
            disabled={isFetchingInvoices}
          >
            <RefreshCw
              className={`h-4 w-4 ${isFetchingInvoices ? 'animate-spin' : ''}`}
            />
          </Button>
        </CardHeader>
        <CardContent>
          <Table headers={invoiceHeaders} rows={invoiceRows} />
        </CardContent>
      </Card>

      {/* Payments */}
      <Card>
        <CardHeader className="flex flex-row items-center justify-between">
          <CardTitle>Payments</CardTitle>
          <Button
            variant="outline"
            size="icon"
            onClick={() => refetchPayments()}
            disabled={isFetchingPayments}
          >
            <RefreshCw
              className={`h-4 w-4 ${isFetchingPayments ? 'animate-spin' : ''}`}
            />
          </Button>
        </CardHeader>
        <CardContent>
          <Table headers={paymentHeaders} rows={paymentRows} />
        </CardContent>
      </Card>
    </div>
  );
};

export default SubscriptionDetails;
