import { Building, Home, UserCog } from 'lucide-react';
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarGroup,
  SidebarGroupContent,
  SidebarHeader,
  SidebarInset,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarTrigger,
} from '@fluency/ui/components/ui/sidebar';
import { Link, useLocation, useNavigate } from '@tanstack/react-router';
import logo from '@fluency/ui/assets/icon-blue.svg';
import { ReactNode } from 'react';
import DynamicBreadcrumbs from '@fluency/ui/components/DashboardShell/breadcrumb/Breadcrumb';
import { Separator } from '@fluency/ui/components/ui/separator';
import UserDropdown from './UserDropdown';
import useClearCache from '../hooks/useClearCacheAdmin';
import ClearCache from '../common/components/ClearCache';

const AppSidebar = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const {} = useClearCache();

  // Helper function to check if a path is active
  const isPathActive = (path: string) => {
    return location.pathname.startsWith(path);
  };

  return (
    <>
      <Sidebar variant="inset" className="z-0">
        <SidebarHeader>
          <SidebarMenu>
            <SidebarMenuItem>
              <div
                className="hover:cursor-pointer flex items-center gap-1 pb-1"
                onClick={() => {
                  navigate({
                    to: '/admin/home',
                    replace: true,
                  });
                }}
              >
                <img src={logo} className="h-8 w-8" alt="Fluency Logo" />
                <div className="grid flex-1 text-left text-xl">
                  <span className="truncate font-semibold tracking-tight">
                    Fluency Admin
                  </span>
                </div>
              </div>
            </SidebarMenuItem>

            <SidebarMenuItem>
              <SidebarMenuButton asChild>
                <ClearCache />
              </SidebarMenuButton>
            </SidebarMenuItem>
            <Separator className="mt-2" />
          </SidebarMenu>
        </SidebarHeader>

        <SidebarContent>
          <SidebarGroup>
            <SidebarMenu>
              <SidebarMenuItem>
                <SidebarMenuButton
                  asChild
                  isActive={isPathActive('/admin/home')}
                >
                  <div
                    onClick={() => {
                      navigate({
                        to: '/admin/home',
                        replace: true,
                      });
                    }}
                  >
                    <Home className="h-5 w-5" />
                    <span>Home</span>
                  </div>
                </SidebarMenuButton>
              </SidebarMenuItem>

              <SidebarMenuItem>
                <SidebarMenuButton
                  asChild
                  isActive={isPathActive('/admin/organisation')}
                >
                  <div
                    onClick={() => {
                      navigate({
                        to: '/admin/organisation',
                        replace: true,
                      });
                    }}
                  >
                    <Building className="h-5 w-5" />
                    <span>Organisation</span>
                  </div>
                </SidebarMenuButton>
              </SidebarMenuItem>
            </SidebarMenu>
          </SidebarGroup>

          <SidebarGroup className="mt-auto">
            <SidebarGroupContent>
              <SidebarMenu>
                <Separator />
                <SidebarMenuItem>
                  <SidebarMenuButton asChild>
                    <Link
                      to={'/'}
                      className="flex items-center gap-3 text-xs rounded-lg py-2 transition-all text-muted-foreground hover:text-primary"
                    >
                      <UserCog className="w-4" /> Go to Fluency Home
                    </Link>
                  </SidebarMenuButton>
                </SidebarMenuItem>
              </SidebarMenu>
            </SidebarGroupContent>
          </SidebarGroup>
        </SidebarContent>

        <SidebarFooter>
          <SidebarMenu>
            <SidebarMenuItem>
              <UserDropdown />
            </SidebarMenuItem>
          </SidebarMenu>
        </SidebarFooter>
      </Sidebar>

      <SidebarInset className="!m-0">
        <header className="sticky top-0 z-20 flex h-10 my-2 shrink-0 items-center gap-2 justify-between px-6 bg-background">
          <div className="flex items-center gap-2">
            <SidebarTrigger className="-ml-1 text-gray-500" />
            <DynamicBreadcrumbs />
          </div>
        </header>
        <main className="h-[calc(100vh-56px-18px)] overflow-auto">
          {children}
        </main>
      </SidebarInset>
    </>
  );
};

export default AppSidebar;
