import { z } from 'zod';

export const isValidViewerSeatsValue = (seats: number) => {
  if (seats === 0) return true;
  if (seats <= 25) return seats === 25;
  if (seats <= 50) return seats === 50;
  if (seats <= 75) return seats === 75;
  // For seats > 75, must be a multiple of 100
  return seats % 100 === 0;
};

export const createSubscriptionFormSchema = (
  requireOwnerEmail: boolean = false,
  isTrialMode: boolean = false
) => {
  const baseSchema = z
    .object({
      product: z.enum(['pro', 'business', 'enterprise'], {
        required_error: 'Please select a product type',
      }),
      plan: z.enum(['monthly', 'annual'], {
        required_error: 'Please select a plan',
      }),
      creatorSeats: z.coerce
        .number()
        .min(1, 'Must have at least 1 creator seat')
        .int('Must be a whole number'),
      viewerSeats: z.coerce
        .number()
        .min(0, 'Viewer seats must be at least 0')
        .refine(
          isValidViewerSeatsValue,
          'Invalid number of viewer seats. Must be 0, 25, 50, 75, or a multiple of 100 for larger quantities'
        )
        .optional(),
      paymentType: z.enum(['invoice', 'immediately'], {
        required_error: 'Please select an invoice method',
      }),
      daysUntilDue: z.coerce
        .number()
        .min(1, 'Days until due must be at least 1')
        .int('Must be a whole number')
        .optional(),
      discountCode: z.string().optional(),
      trialDays: z.coerce
        .number()
        .min(1, 'Trial days must be at least 1')
        .int('Must be a whole number')
        .optional(),
      ownerEmail: z
        .union([z.string().email('Please enter valid email'), z.literal('')])
        .optional(),
    })
    .superRefine((data, ctx) => {
      if (data.paymentType === 'invoice' && !data.daysUntilDue) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Days until due is required for invoice method',
          path: ['daysUntilDue'],
        });
      }
      if (requireOwnerEmail && (!data.ownerEmail || data.ownerEmail === '')) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Owner email is required',
          path: ['ownerEmail'],
        });
      }
      if (isTrialMode && !data.trialDays) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Trial days is required for trial mode',
        });
      }
    });
  return baseSchema;
};

// Type can still be inferred from the base schema
export type CreateSubscriptionFormValues = z.infer<
  ReturnType<typeof createSubscriptionFormSchema>
>;
