import { useCallback, useRef } from 'react';
import {
  Node,
  NodeChange,
  EdgeChange,
  OnNodesChange,
  applyNodeChanges,
  OnEdgesChange,
  applyEdgeChanges,
  addEdge,
  OnConnect,
  Connection,
  XYPosition,
  MarkerType,
  Edge,
} from 'reactflow';
import { useShallow, useWorkflowStore } from '../store/index.js';
import { AlignmentLine } from '../components/helpers/AlignmentLines.js';

// AlignmentLine Options
const threshold = 5;

const useReactFlowHelpers = () => {
  const { nodes, edges, setNodes, setEdges, setAlignmentLines } =
    useWorkflowStore(
      useShallow((state) => ({
        nodes: state.nodes,
        edges: state.edges,
        setNodes: state.setNodes,
        setEdges: state.setEdges,
        setAlignmentLines: state.setAlignmentLines,
      }))
    );

  const draggedNodeRef = useRef<string | null>(null);

  const onNodesChange: OnNodesChange = useCallback(
    (changes: NodeChange[]) => {
      setNodes(applyNodeChanges(changes, nodes));
    },
    [nodes, setNodes]
  );

  const onEdgesChange: OnEdgesChange = useCallback(
    (changes: EdgeChange[]) => {
      setEdges(applyEdgeChanges(changes, edges));
    },
    [edges, setEdges]
  );

  const onConnect: OnConnect = useCallback(
    (connection: Connection) => {
      if (connection.source && connection.target) {
        const newEdge: Edge = {
          id: `edge-${Date.now()}`,
          source: connection.source,
          target: connection.target,
          type: 'labeled',
          sourceHandle: connection.sourceHandle ?? null,
          targetHandle: connection.targetHandle ?? null,
          data: { label: '' },
          markerEnd: {
            type: MarkerType.ArrowClosed,
            color: '000000',
          },
          style: {
            strokeWidth: 2,
            stroke: '#000000',
          },
        };
        setEdges(addEdge(newEdge, edges));
      }
    },
    [edges, setEdges]
  );

  const findAlignments = useCallback(
    (nodeId: string, dragPosition: XYPosition): XYPosition => {
      const draggedNode = nodes.find((n) => n.id === nodeId);
      if (!draggedNode) return dragPosition;

      const otherNodes = nodes.filter((n) => n.id !== nodeId);
      let newPosition = { ...dragPosition };
      const newAlignmentLines: AlignmentLine[] = [];

      // Get draggedNode dimensions
      const draggedWidth = draggedNode.width || 0;
      const draggedHeight = draggedNode.height || 0;
      const draggedCenterX = dragPosition.x + draggedWidth / 2;
      const draggedCenterY = dragPosition.y + draggedHeight / 2;

      // Track if we found any alignments
      let foundVerticalAlignment = false;
      let foundHorizontalAlignment = false;

      otherNodes.forEach((node) => {
        const nodeWidth = node.width || 0;
        const nodeHeight = node.height || 0;
        const nodeCenterX = node.position.x + nodeWidth / 2;
        const nodeCenterY = node.position.y + nodeHeight / 2;

        // Check vertical alignment (x-axis)
        if (
          !foundVerticalAlignment &&
          Math.abs(draggedCenterX - nodeCenterX) < threshold
        ) {
          newPosition.x = node.position.x + (nodeWidth - draggedWidth) / 2;
          foundVerticalAlignment = true;
          newAlignmentLines.push({
            id: `vertical-${node.id}`,
            type: 'vertical',
            position: nodeCenterX,
          });
        }

        // Check horizontal alignment (y-axis)
        if (
          !foundHorizontalAlignment &&
          Math.abs(draggedCenterY - nodeCenterY) < threshold
        ) {
          newPosition.y = node.position.y + (nodeHeight - draggedHeight) / 2;
          foundHorizontalAlignment = true;
          newAlignmentLines.push({
            id: `horizontal-${node.id}`,
            type: 'horizontal',
            position: nodeCenterY,
          });
        }
      });

      setAlignmentLines(newAlignmentLines);

      return newPosition;
    },
    [nodes, setAlignmentLines]
  );

  const onNodeDragStart = useCallback(
    (_event: React.MouseEvent, node: Node) => {
      draggedNodeRef.current = node.id;
    },
    []
  );

  const onNodeDrag = useCallback(
    (_event: React.MouseEvent, node: Node) => {
      if (draggedNodeRef.current === node.id) {
        const snappedPosition = findAlignments(node.id, node.position);

        // Update node position if it changed due to snapping
        if (
          snappedPosition.x !== node.position.x ||
          snappedPosition.y !== node.position.y
        ) {
          setNodes((prevNodes) =>
            prevNodes.map((n) => {
              if (n.id === node.id) {
                return {
                  ...n,
                  position: snappedPosition,
                };
              }
              return n;
            })
          );
        }
      }
    },
    [findAlignments, setNodes]
  );

  const onNodeDragStop = useCallback(() => {
    draggedNodeRef.current = null;
    setAlignmentLines([]);
  }, [setAlignmentLines]);

  const onEdgeLabelChange = useCallback(
    (edgeId: string, newLabel: string) => {
      setEdges((prevEdges) =>
        prevEdges.map((edge) =>
          edge.id === edgeId
            ? { ...edge, data: { ...edge.data, label: newLabel } }
            : edge
        )
      );
    },
    [setEdges]
  );

  const onDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  };

  return {
    onNodesChange,
    onEdgesChange,
    onConnect,
    onNodeDragStart,
    onNodeDrag,
    onNodeDragStop,
    onEdgeLabelChange,
    onDragOver,
  };
};

export default useReactFlowHelpers;
