import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import { AdminOrganisation } from '../types';
import {
  PaginatedResponse,
  PaginationParams,
} from '../../common/types/pagination';
import {
  CreateOrganisationData,
  CreateOrganisationError,
} from '../types/hooks';

export const useOrganisationOperations = () => {
  const { accessToken } = useAuth();
  const queryClient = useQueryClient();
  const apiBaseUrl = import.meta.env.VITE_SERVER_API_URL;

  const getHeaders = () => {
    const headers = new Headers();
    headers.append('Authorization', `Bearer ${accessToken}`);
    headers.append('Content-Type', 'application/json');
    return headers;
  };

  const useGetOrganisations = (params: PaginationParams = {}) => {
    const {
      page = 1,
      limit = 10,
      sortBy = 'createdAt',
      sortOrder = 'desc',
      search,
    } = params;

    return useQuery<PaginatedResponse<AdminOrganisation>>({
      queryKey: ['organisations', { page, limit, sortBy, sortOrder, search }],
      queryFn: async () => {
        const queryParams = new URLSearchParams();

        // Add pagination params
        queryParams.append('page', page.toString());
        queryParams.append('limit', limit.toString());

        // Add optional params
        if (sortBy) {
          queryParams.append('sortBy', sortBy);
          queryParams.append('sortOrder', sortOrder);
        }

        if (search) {
          queryParams.append('search', search);
        }

        const response = await fetch(
          `${apiBaseUrl}/admin/organisations?${queryParams.toString()}`,
          {
            headers: getHeaders(),
          }
        );

        const result = await response.json();

        if (!response.ok) {
          throw new Error(result.message || 'Failed to fetch organisations');
        }

        return result;
      },
    });
  };

  const useGetOrganisation = (params: { orgId: string }) => {
    const { orgId } = params;

    return useQuery<AdminOrganisation>({
      queryKey: ['organisation', orgId],
      queryFn: async () => {
        const response = await fetch(
          `${apiBaseUrl}/admin/organisations/${orgId}`,
          {
            headers: getHeaders(),
          }
        );

        const result = await response.json();

        if (!response.ok) {
          throw new Error(
            result.message || `Failed to fetch organisation ${orgId}`
          );
        }

        return result;
      },
    });
  };

  const useCreateOrganisation = () =>
    useMutation<
      AdminOrganisation,
      CreateOrganisationError,
      CreateOrganisationData
    >({
      mutationFn: async (data: CreateOrganisationData) => {
        const response = await fetch(`${apiBaseUrl}/admin/organisations`, {
          method: 'POST',
          headers: getHeaders(),
          body: JSON.stringify(data),
        });
        if (!response.ok) {
          const errorData = await response.json();
          throw errorData;
        }
        return response.json();
      },
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['organisations'] });
      },
    });

  return {
    useGetOrganisations,
    useGetOrganisation,
    useCreateOrganisation,
  };
};
