import { useEffect, useRef } from 'react';
import {
  useInfiniteQuery,
  useQuery,
  UseQueryResult,
  useQueryClient,
} from '@tanstack/react-query';
import { useNavigate } from '@tanstack/react-router';
import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import { fetchNotifications } from '../api/fetchNotifications';
import {
  NotificationQueryParams,
  NotificationResponse,
  NotificationItem,
  NotificationItemType,
} from '../types/notifications.type';
import { Logger } from '@fluency/ui/features/Logger';
import { toast } from '@fluency/ui/components/ui/use-toast';
import { showDocumentToast } from '../components/DocumentCreateToast';
import { useRecording } from '@fluency/ui/providers/recording/RecordingContext';

export const NOTIFICATIONS_KEY = ['notifications'] as const;

export function useNotifications(params: NotificationQueryParams = {}) {
  const { accessToken } = useAuth();
  const { isRecording } = useRecording();

  return useInfiniteQuery({
    queryKey: ['infiniteNotifications', params],
    queryFn: async ({ pageParam = 1 }) => {
      if (!accessToken) throw new Error('No access token available');
      return fetchNotifications(accessToken, { ...params, page: pageParam });
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage) => {
      if (lastPage.metadata.page < lastPage.metadata.totalPages) {
        return lastPage.metadata.page + 1;
      }
      return undefined;
    },
    enabled: !!accessToken && !isRecording,
    gcTime: 30 * 60 * 1000,
    staleTime: 5 * 60 * 1000,
  });
}

export function usePopupNotifications(): UseQueryResult<
  NotificationResponse,
  Error
> {
  const { accessToken } = useAuth();
  const { isRecording } = useRecording();

  return useQuery({
    queryKey: ['popupNotifications'],
    queryFn: async () => {
      if (!accessToken) throw new Error('No access token available');
      const response = await fetchNotifications(accessToken, {
        limit: 30,
        page: 1,
      });

      return {
        ...response,
        data: response.data.slice(0, 5),
      };
    },
    enabled: !!accessToken && !isRecording,
    staleTime: 5 * 60 * 1000,
  });
}

const POLLING_INTERVAL = 5000; // 5 seconds
const TOAST_THRESHOLD = 20; // seconds
const IMPORTANT_NOTIFICATION_TYPES: NotificationItemType[] = [
  NotificationItemType.DOCUMENT_CREATION_COMPLETE,
];

export function useUnreadNotificationsCount(): number {
  const { accessToken } = useAuth();
  const { isRecording } = useRecording();
  const previousCount = useRef<number>(0);
  const shownNotificationIds = useRef<Set<string>>(new Set());
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { data: unreadCountData } = useQuery<number, Error>({
    queryKey: ['notifications', 'unread-count'],
    queryFn: async () => {
      if (!accessToken) return 0;
      try {
        const result = await fetchNotifications(accessToken, { limit: 1 });
        return result.metadata.unreadCount;
      } catch (error) {
        Logger.error('Failed to fetch unread count:', error);
        return 0;
      }
    },
    enabled: !!accessToken && !isRecording,
    gcTime: 30 * 60 * 1000,
    staleTime: 5 * 60 * 1000,
    refetchInterval: isRecording ? false : POLLING_INTERVAL,
    refetchIntervalInBackground: !isRecording,
  });

  // Poll for notifications continuously
  const { data: notificationsData } = useQuery({
    queryKey: ['notifications', 'latest'],
    queryFn: async () => {
      if (!accessToken) return null;
      return fetchNotifications(accessToken, { limit: 5, page: 1 });
    },
    enabled: !!accessToken && !isRecording,
    refetchInterval: isRecording ? false : POLLING_INTERVAL,
    refetchIntervalInBackground: !isRecording,
  });

  useEffect(() => {
    if (!isRecording && notificationsData?.data) {
      const now = new Date();

      // Check for new important notifications
      const newNotifications = (notificationsData.data as NotificationItem[])
        .filter((notification) => !notification.read)
        .filter((notification) =>
          IMPORTANT_NOTIFICATION_TYPES.includes(
            notification.type as NotificationItemType
          )
        )
        .filter(
          (notification) => !shownNotificationIds.current.has(notification.id)
        )
        .filter((notification) => {
          const createdAt = new Date(notification.createdAt);
          const secondsAgo = (now.getTime() - createdAt.getTime()) / 1000;
          return secondsAgo <= TOAST_THRESHOLD;
        });

      newNotifications.forEach((notification) => {
        if (
          notification.type ===
            NotificationItemType.DOCUMENT_CREATION_COMPLETE &&
          !isRecording
        ) {
          showDocumentToast({
            message: notification.message,
            link: notification.link,
            docId: notification.resource.id,
            toast,
            queryClient,
            navigate,
          });
        }
        shownNotificationIds.current.add(notification.id);
      });
    }
    previousCount.current = unreadCountData ?? 0;
  }, [unreadCountData, notificationsData, navigate, queryClient, isRecording]);

  return unreadCountData ?? 0;
}
