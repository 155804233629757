import { User } from '@fluency/ui/features/Settings/types/types';
import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { toast } from '@fluency/ui/components/ui/use-toast';

export interface ProcessOwnerResponse {
  documentId: string;
  processOwner: string | null;
  ownerDetails?: { firstName: string; lastName: string; email: string };
}

interface MutationContext {
  previousOwner: ProcessOwnerResponse | undefined;
}

const useProcessOwner = ({ documentId }: { documentId: string }) => {
  const { accessToken, orgId } = useAuth();
  const queryClient = useQueryClient();
  const apiBaseUrl = import.meta.env.VITE_SERVER_API_URL;

  const headers = new Headers({
    'Content-Type': 'application/json',
    Authorization: `Bearer ${accessToken}`,
  });

  // Queries
  const getProcessOwner = useQuery<ProcessOwnerResponse, Error>({
    queryKey: ['processOwner', documentId],
    queryFn: async () => {
      const response = await fetch(
        `${apiBaseUrl}/documents/processOwner/${documentId}`,
        {
          method: 'GET',
          headers,
        }
      );
      if (!response.ok) {
        throw new Error(`Failed to fetch process owner ${response.text}`);
      }
      return response.json();
    },
  });

  const getListOfUsers = useQuery<User[], Error>({
    queryKey: ['listOrganisationUser', orgId],
    queryFn: async () => {
      const response = await fetch(`${apiBaseUrl}/user/listOrganisationUsers`, {
        method: 'GET',
        headers,
      });
      if (!response.ok) {
        throw new Error(`Failed to fetch users ${response.text}`);
      }
      return response.json();
    },
  });

  // Mutations
  const mutateProcessOwner = useMutation<
    ProcessOwnerResponse,
    Error,
    { processOwner: string },
    MutationContext
  >({
    mutationFn: async ({ processOwner }) => {
      const response = await fetch(
        `${apiBaseUrl}/documents/updateProcessOwner/${documentId}`,
        {
          method: 'PUT',
          headers,
          body: JSON.stringify({ processOwner }),
        }
      );
      if (!response.ok) {
        throw new Error(`Failed to update process owner ${response.text}`);
      }
      return response.json();
    },
    onMutate: async ({ processOwner }) => {
      // Cancel outgoing refetches
      await queryClient.cancelQueries({
        queryKey: ['processOwner', documentId],
      });

      // Get the current owner
      const previousOwner = queryClient.getQueryData<ProcessOwnerResponse>([
        'processOwner',
        documentId,
      ]);

      // Find the new owner details from users list
      const users = queryClient.getQueryData<User[]>([
        'listOrganisationUser',
        orgId,
      ]);
      const newOwnerDetails = users?.find((user) => user.id === processOwner);

      // Optimistically update the owner
      queryClient.setQueryData<ProcessOwnerResponse>(
        ['processOwner', documentId],
        {
          documentId,
          processOwner,
          ownerDetails: newOwnerDetails
            ? {
                firstName: newOwnerDetails.firstName,
                lastName: newOwnerDetails.lastName,
                email: newOwnerDetails.email,
              }
            : undefined,
        }
      );

      return { previousOwner };
    },
    onError: (_err, _variables, context) => {
      // Revert the optimistic update
      if (context?.previousOwner) {
        queryClient.setQueryData(
          ['processOwner', documentId],
          context.previousOwner
        );
      }
      toast({
        variant: 'destructive',
        title: 'Error',
        description: 'Failed to update process owner',
      });
    },
    onSuccess: () => {
      toast({
        title: 'Process owner updated',
        description: 'The process owner has been successfully updated.',
      });
    },
  });

  const removeProcessOwner = useMutation<any, Error, void, MutationContext>({
    mutationFn: async () => {
      const response = await fetch(
        `${apiBaseUrl}/documents/removeProcessOwner/${documentId}`,
        {
          method: 'DELETE',
          headers,
        }
      );
      if (!response.ok) {
        throw new Error(`Failed to remove process owner ${response.text}`);
      }
      return response.json();
    },
    onMutate: async () => {
      await queryClient.cancelQueries({
        queryKey: ['processOwner', documentId],
      });

      const previousOwner = queryClient.getQueryData<ProcessOwnerResponse>([
        'processOwner',
        documentId,
      ]);

      queryClient.setQueryData<ProcessOwnerResponse>(
        ['processOwner', documentId],
        {
          documentId,
          processOwner: null,
          ownerDetails: undefined,
        }
      );

      return { previousOwner };
    },
    onError: (_err, _variables, context) => {
      if (context?.previousOwner) {
        queryClient.setQueryData(
          ['processOwner', documentId],
          context.previousOwner
        );
      }
      toast({
        variant: 'destructive',
        title: 'Error',
        description: 'Failed to remove process owner',
      });
    },
    onSuccess: () => {
      toast({
        title: 'Process owner removed',
        description: 'The process owner has been successfully removed.',
      });
    },
  });

  return {
    initialOwner: getProcessOwner.data,
    isLoadingInitialOwner: getProcessOwner.isLoading,
    isErrorInitialOwner: getProcessOwner.isError,

    users: getListOfUsers.data,
    isLoadingUsers: getListOfUsers.isLoading,
    isErrorUsers: getListOfUsers.isError,

    updateOwner: mutateProcessOwner.mutate,
    isLoadingUpdateOwner: mutateProcessOwner.isPending,

    removeOwner: removeProcessOwner.mutate,
    isLoadingRemoveOwner: removeProcessOwner.isPending,
  };
};

export default useProcessOwner;
