import {
  CalendarDays,
  Check,
  Clock,
  FileText,
  History,
  Lock,
  User2,
} from 'lucide-react';
import { MetadataBadge } from './MetadataBadge';
import { formatDate, formatDuration } from '../../util/formatData';
import { UnifiedDocumentData } from '../../types/docSummaryType';
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from '@fluency/ui/components/ui/hover-card';

interface ViewModeMetadataProps {
  data: UnifiedDocumentData | undefined;
  isLoading: {
    owner?: boolean;
    data?: boolean;
  };
}

export const ViewModeMetadata = ({
  data,
  isLoading,
}: ViewModeMetadataProps) => {
  let createdBy =
    (data?.documentation?.userInfo[0]?.firstName ?? '') +
    ' ' +
    (data?.documentation?.userInfo[0]?.lastName?.[0] ?? '');
  if (createdBy.trim() === '') {
    createdBy = data?.documentation?.userInfo[0]?.email.split('@')[0] ?? '';
  }

  let ownerName =
    (data?.processOwner?.details?.firstName ?? '') +
    ' ' +
    (data?.processOwner?.details?.lastName?.[0] ?? '');

  if (ownerName.trim() === '') {
    ownerName = data?.processOwner?.details?.email.split('@')[0] ?? '';
  }

  return (
    <div className="flex flex-wrap gap-x-2 gap-y-3 mb-6 max-w-full">
      <MetadataBadge icon={FileText} isLoading={isLoading.data}>
        {data?.documentation?.totalSteps || 0} Steps
      </MetadataBadge>
      {createdBy && (
        <MetadataBadge icon={User2} isLoading={isLoading.data}>
          {createdBy}
        </MetadataBadge>
      )}
      <MetadataBadge icon={Clock} isLoading={isLoading.data}>
        {data?.documentation?.duration
          ? formatDuration(data.documentation.duration)
          : '0'}
      </MetadataBadge>
      <MetadataBadge icon={CalendarDays} isLoading={isLoading.data}>
        {data?.documentation?.createdDate && (
          <>
            <p className="mr-1">Created on</p>
            <time
              dateTime={new Date(data.documentation.createdDate).toISOString()}
            >
              {formatDate(data.documentation.createdDate)}
            </time>
          </>
        )}
      </MetadataBadge>
      <MetadataBadge icon={History} isLoading={isLoading.data}>
        <>
          {'Version ' + data?.documentation?.versionInfo.version}
          <div className="mx-2 opacity-20">|</div>
          {data?.documentation?.versionInfo.isLatestVersion ? (
            <>
              <Check className="h-4 w-4 my-0.5 mr-2" />
              Latest version
            </>
          ) : (
            <>
              <Lock className="h-4 w-4 my-0.5 mr-2" />
              Superseded version
            </>
          )}
        </>
      </MetadataBadge>
      <HoverCard>
        <HoverCardTrigger>
          <MetadataBadge icon={User2} isLoading={isLoading.owner}>
            {isLoading.owner ? (
              'Process Owner: Loading...'
            ) : data?.processOwner?.details ? (
              <>Process Owner: {ownerName}</>
            ) : (
              'No Process Owner'
            )}
          </MetadataBadge>
        </HoverCardTrigger>
        <HoverCardContent className="h-fit w-fit p-1">
          <span className="text-xs">{data?.processOwner?.details?.email}</span>
        </HoverCardContent>
      </HoverCard>
    </div>
  );
};
