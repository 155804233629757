import React, { useEffect } from 'react';
import { DashboardShell } from '@fluency/ui/components';
import WorkflowHeader from './components/WorkflowHeader';
import { useParams } from '@tanstack/react-router';
import { RefreshCw } from 'lucide-react';
import { ReactFlowProvider } from '@reactflow/core';
import useGetVaults from '../Home/hooks/useGetVaults';
import { WorkflowModeType } from './types/workflow';
import Sidebar from './components/sidebar/Sidebar';
import { useShallow, useWorkflowStore } from './store';
import ViewOnlyWorkflow from './ViewWorkflow';
import EditWorkflow from './EditWorkflow';
import useWorkflowSync from './hooks/useWorkflowSync';

const WorkflowMapping: React.FC = () => {
  const { id } = useParams({ from: '/workflow/$id' });
  const workflowId = id;
  const reactFlowRef = React.useRef<HTMLDivElement>(null);

  // Store
  const { workflow, mode, setMode } = useWorkflowStore(
    useShallow((state) => ({
      workflow: state.workflow,
      mode: state.mode,
      setMode: state.setMode,
    }))
  );

  // Handle workflow and document data syncing
  const {
    isWorkflowLoading,
    isWorkflowError,
    isDocumentsLoading,
    isDocumentsError,
  } = useWorkflowSync({ workflowId });

  // Permission-related for workflow access
  const { data: vaultsData } = useGetVaults();
  const vault = vaultsData?.vaults.find(
    (vault) => vault.id === workflow?.vault.id
  );
  const isVaultLocked = vault?.isLocked || false;
  const isWorkflowLocked = workflow?.isLocked || false;

  // Set mode to view when they arrive at the page
  useEffect(() => {
    setMode('View');
  }, [setMode]);

  // effect to push back to view mode if either vault or workflow becomes locked
  useEffect(() => {
    if (mode !== 'View' && (isVaultLocked || isWorkflowLocked)) {
      setMode('View');
    }
  }, [isVaultLocked, isWorkflowLocked, mode]);

  const handleSetMode = (newMode: WorkflowModeType) => {
    if (newMode !== 'View' && (isVaultLocked || isWorkflowLocked)) {
      return; // Don't allow changing to Edit/Collaborate if locked
    }
    setMode(newMode);
  };

  const renderWorkflow = () => {
    if (!workflow) return null;

    return (
      <>
        {mode === 'Edit' && (
          <div className="flex h-[calc(100vh-200px)] px-6">
            <Sidebar
              isError={isDocumentsError}
              isLoading={isDocumentsLoading}
              reactFlowRef={reactFlowRef}
              itemsPerRow={6}
            />
            <EditWorkflow reactFlowRef={reactFlowRef} />
          </div>
        )}
        {mode === 'View' && (
          <ViewOnlyWorkflow
            onEditClick={() => setMode('Edit')}
            isLocked={isWorkflowLocked}
            isVaultLocked={isVaultLocked}
          />
        )}
      </>
    );
  };

  return (
    <DashboardShell>
      <WorkflowHeader
        mode={mode}
        setMode={handleSetMode}
        isVaultLocked={isVaultLocked}
        isLoading={isWorkflowLoading}
        isError={isWorkflowError}
      />
      {isWorkflowLoading || isDocumentsLoading ? (
        <div className="h-[calc(100vh-600px)] border-t flex items-center justify-center gap-2 font-medium text-sm text-gray-400">
          <RefreshCw className="text-gray-400 animate-spin" /> Loading Process
          Map
        </div>
      ) : !workflow || isWorkflowError ? (
        <div className="flex items-center justify-center h-full">
          No process map data found.
        </div>
      ) : (
        <ReactFlowProvider>
          <div className="relative">{renderWorkflow()}</div>
        </ReactFlowProvider>
      )}
    </DashboardShell>
  );
};

export default WorkflowMapping;
