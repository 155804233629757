import { Input } from '@fluency/ui/components/ui/input';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@fluency/ui/components/ui/tooltip';
import { Info } from 'lucide-react';
import { useMemo, useState } from 'react';
import debounce from 'lodash.debounce';

interface SearchInputProps {
  value: string;
  performSearch: (value: string) => void;
  info?: string;
}

const SearchInput = ({ value, performSearch, info }: SearchInputProps) => {
  const [searchValue, setSearchValue] = useState(value);

  const debouncedSearch = useMemo(() => debounce(performSearch, 500), []);

  const handleSearch = (newValue: string) => {
    setSearchValue(newValue);
    debouncedSearch(newValue);
  };

  return (
    <div className="flex flex-row items-center gap-2">
      <Input
        className="w-96"
        placeholder="Search"
        value={searchValue}
        onChange={(e) => handleSearch(e.target.value)}
      />
      {info && (
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger className="flex items-center gap-1 cursor-help">
              <Info className="h-5 w-5 text-gray-500 ml-1" />
            </TooltipTrigger>
            <TooltipContent>
              <p>{info}</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      )}
    </div>
  );
};

export default SearchInput;
