// components/RecordDropdown.tsx
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
} from '../../../components/ui/dropdown-menu';
import {
  DocumentMinusIcon,
  DocumentPlusIcon,
  VideoCameraIcon,
} from '@heroicons/react/24/outline';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
  DialogDescription,
} from '../../../components/ui/dialog';
import { Button } from '../../../components/ui/button';
import { toast } from '@fluency/ui/components/ui/use-toast';
import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import { useGetDocumentData } from '../hooks/index';
import { Logger } from '@fluency/ui/features/Logger';
import { useRecording } from '@fluency/ui/providers/recording/RecordingContext';

type RecordDropdownProps = {
  stepBeforeId: string | null;
  documentationId: string;
};

const RecordDropdown = ({
  stepBeforeId,
  documentationId,
}: RecordDropdownProps) => {
  const { refetch, data } = useGetDocumentData(documentationId);
  const { accessToken } = useAuth();
  const {
    isRecording,
    setIsRecording,
    isLoading,
    setIsLoading,
    currentDocumentId,
    setCurrentDocumentId,
    recordingType,
    setRecordingType,
    setStepBeforeId,
    error,
    setError,
  } = useRecording();

  // Check if this specific instance is active
  const isThisInstanceRecording =
    isRecording &&
    currentDocumentId === documentationId &&
    stepBeforeId === useRecording().stepBeforeId;
  const isThisInstanceLoading =
    isLoading &&
    currentDocumentId === documentationId &&
    stepBeforeId === useRecording().stepBeforeId;

  const startRecord = async (type: 'insert' | 'overwrite') => {
    if (!isRecording) {
      if (window.electron) {
        try {
          await window.electron.ipcRenderer.invoke('start-record', {
            documentId: documentationId,
            token: accessToken,
            isExistingDoc: true,
          });
          setIsRecording(true);
          setRecordingType(type);
          setCurrentDocumentId(documentationId);
          setStepBeforeId(stepBeforeId);
        } catch (error) {
          toast({
            variant: 'destructive',
            title: 'Error starting recording',
            description: 'Please try again.',
          });
          Logger.error('Error starting recording:', error);
        }
      }
    }
  };

  const stopRecord = async () => {
    // Only stop if this instance is recording
    if (!isThisInstanceRecording) return;

    setIsRecording(false);
    setIsLoading(true);
    if (window.electron) {
      try {
        await window.electron.ipcRenderer.invoke('run-steps-existing', {
          documentationId,
          documentationName:
            data?.documentation.documentationName || 'Existing Document',
          documentationDescription:
            data?.documentation.description || 'Existing Document',
          stepBeforeId,
          token: accessToken,
          insertType: recordingType,
        });
        toast({
          title: 'New steps captured successfully',
          description:
            "You will receive a notification when it's ready to view.",
        });
        refetch();
      } catch (error) {
        Logger.error('Error processing recording:', error);
        setError(true);
        toast({
          variant: 'destructive',
          title: 'Error processing recording',
          description: 'Please try again.',
        });
      } finally {
        setIsLoading(false);
        setCurrentDocumentId(null);
        setStepBeforeId(null);
        setRecordingType(undefined);
      }
    }
  };

  const closeErrorDialog = () => {
    if (!isThisInstanceRecording) return;
    setError(false);
    setIsLoading(false);
  };

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <div
            title="Record from Here"
            className={`flex items-center justify-center cursor-pointer rounded-full p-1 w-6 h-6 ${
              isThisInstanceRecording ? 'bg-red-100' : 'bg-slate-100'
            }`}
          >
            <VideoCameraIcon
              className={`w-6 h-6 ${
                isThisInstanceRecording ? 'text-red-500' : 'text-slate-500'
              }`}
            />
          </div>
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          <DropdownMenuLabel className="cursor-default">
            Record from Here
          </DropdownMenuLabel>
          <DropdownMenuSeparator />
          {!isRecording && (
            <>
              <DropdownMenuItem
                onSelect={() => startRecord('insert')}
                className="cursor-pointer"
              >
                <div className="inline-flex items-center text-center justify-center">
                  <DocumentPlusIcon className="w-4 h-4 mr-1" />
                  <span>Insert between existing steps</span>
                </div>
              </DropdownMenuItem>
              <DropdownMenuItem
                onSelect={() => startRecord('overwrite')}
                className="cursor-pointer"
              >
                <div className="inline-flex items-center text-center justify-center">
                  <DocumentMinusIcon className="w-4 h-4 mr-1" />
                  <span>Overwrite following steps</span>
                </div>
              </DropdownMenuItem>
            </>
          )}
        </DropdownMenuContent>
      </DropdownMenu>
      <Dialog open={isThisInstanceRecording}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Recording in Progress</DialogTitle>
            <DialogDescription>
              Click the Stop Recording button to stop the current recording.
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button onClick={stopRecord}>Stop Recording</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      <Dialog open={isThisInstanceLoading}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>
              {error ? 'Error' : 'Processing new steps'}
            </DialogTitle>
            <DialogDescription>
              {error
                ? 'No steps were recorded. Please try again.'
                : 'Please wait while the recording is being processed.'}
            </DialogDescription>
          </DialogHeader>
          {error && (
            <DialogFooter>
              <Button onClick={closeErrorDialog}>Close</Button>
            </DialogFooter>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default RecordDropdown;
