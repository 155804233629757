import { create, StateCreator } from 'zustand';
import { devtools } from 'zustand/middleware';
import type {} from '@redux-devtools/extension';

export { useShallow } from 'zustand/shallow';

import { Node, Edge } from 'reactflow';
import { Documentation } from '@fluency/ui/hooks/documentation/types/GetDocs';
import { Workflow } from '@fluency/ui/hooks/workflows/types/types';
import { AlignmentLine } from '../components/helpers/AlignmentLines';
import { WorkflowModeType } from '../types/workflow';

interface WorkflowState {
  // Store state
  workflow: Workflow | null;
  nodes: Node[];
  edges: Edge[];
  alignmentLines: AlignmentLine[];
  documents: Documentation[];
  mode: WorkflowModeType;
  updateType: 'nodes' | 'header' | null;

  // Basic Setters
  setWorkflow: (workflow: Workflow) => void;
  setNodes: (nodesOrUpdater: Node[] | ((prev: Node[]) => Node[])) => void;
  setEdges: (edgesOrUpdater: Edge[] | ((prev: Edge[]) => Edge[])) => void;
  setAlignmentLines: (alignmentLines: AlignmentLine[]) => void;
  setDocuments: (documents: Documentation[]) => void;
  setMode: (mode: WorkflowModeType) => void;
  setUpdateType: (updateType: 'nodes' | 'header' | null) => void;

  // Additional utility actions
  addNode: (node: Node) => void;
  addAlignmentLine: (line: AlignmentLine) => void;
  removeNode: (nodeId: string) => void;
  removeEdge: (edgeId: string) => void;
  updateNode: (
    nodeId: string,
    update: Partial<Node> | ((prevNode: Node) => Partial<Node> | Node)
  ) => void;
  getNode: (nodeId: string) => Node | undefined;
  updateDocument: (
    documentId: string,
    update:
      | Partial<Documentation>
      | ((
          prevDocument: Documentation
        ) => Partial<Documentation> | Documentation)
  ) => void;
  getDocument: (documentId: string) => Documentation | undefined;
  clearReactFlow: () => void;
}

const createWorkflowStore = (withDevTools = false) => {
  const storeBase: StateCreator<WorkflowState> = (set, get): WorkflowState => ({
    // Initial state
    nodes: [],
    edges: [],
    alignmentLines: [],
    documents: [],
    workflow: null,
    mode: 'View',
    updateType: null,

    // Basic setters
    setWorkflow: (workflow: Workflow) => set({ workflow }),

    setNodes: (nodesOrUpdater: Node[] | ((prev: Node[]) => Node[])) =>
      set((state: WorkflowState) => {
        const newNodes =
          typeof nodesOrUpdater === 'function'
            ? nodesOrUpdater(state.nodes)
            : nodesOrUpdater;

        return {
          nodes: newNodes.map((node) => ({
            ...node,
            ...(node.type === 'swimlaneNode' && { style: { zIndex: -10 } }),
          })),
        };
      }),

    setEdges: (edgesOrUpdater: Edge[] | ((prev: Edge[]) => Edge[])) =>
      set((state: WorkflowState) => ({
        edges:
          typeof edgesOrUpdater === 'function'
            ? edgesOrUpdater(state.edges)
            : edgesOrUpdater,
      })),

    setAlignmentLines: (alignmentLines: AlignmentLine[]) =>
      set({ alignmentLines }),
    setDocuments: (documents: Documentation[]) => set({ documents }),
    setMode: (mode: WorkflowModeType) => set({ mode }),
    setUpdateType: (updateType: 'nodes' | 'header' | null) =>
      set({ updateType }),

    // Utility actions
    addNode: (node: Node) => {
      set({
        nodes: [
          ...get().nodes,
          {
            ...node,
            ...(node.type === 'swimlaneNode' && { style: { zIndex: -10 } }),
          },
        ],
      });
    },

    addAlignmentLine: (line: AlignmentLine) => {
      set({
        alignmentLines: [...get().alignmentLines, line],
      });
    },

    removeNode: (nodeId: string) => {
      set({
        nodes: get().nodes.filter((node) => node.id !== nodeId),
        // Also remove connected edges
        edges: get().edges.filter(
          (edge) => edge.source !== nodeId && edge.target !== nodeId
        ),
      });
    },

    removeEdge: (edgeId: string) => {
      set({
        edges: get().edges.filter((edge) => edge.id !== edgeId),
      });
    },

    updateNode: (nodeId, update) => {
      const { nodes } = get();

      set({
        nodes: nodes.map((node) => {
          if (node.id !== nodeId) return node;

          // If update is a function, call it with the previous node
          const updatedValues =
            typeof update === 'function' ? update(node) : update;

          return {
            ...node,
            ...updatedValues,
          };
        }),
      });
    },

    getNode: (nodeId: string) => {
      return get().nodes.find((node) => node.id === nodeId);
    },

    updateDocument: (documentId, update) => {
      const { documents } = get();

      set({
        documents: documents.map((doc) => {
          if (doc.documentationId !== documentId) return doc;

          // If update is a function, call it with the previous document
          const updatedValues =
            typeof update === 'function' ? update(doc) : update;

          return {
            ...doc,
            ...updatedValues,
          };
        }),
      });
    },

    getDocument: (documentId: string) => {
      return get().documents.find((doc) => doc.documentationId === documentId);
    },

    clearReactFlow: () => {
      set({
        nodes: [],
        edges: [],
        alignmentLines: [],
      });
    },
  });

  // Conditionally apply devtools
  if (withDevTools) {
    return create<WorkflowState>()(
      devtools(storeBase, {
        name: 'Workflow Store',
        enabled: true,
      })
    );
  }

  return create<WorkflowState>()(storeBase);
};

// Create store instances based on environment
const isDevelopment = import.meta.env.NODE_ENV === 'development';
export const useWorkflowStore = createWorkflowStore(isDevelopment);
