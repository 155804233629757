import React from 'react';
import { useResourceRequests } from '../hooks/useResourceRequests';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@fluency/ui/components/ui/table';
import { Button } from '@fluency/ui/components/ui/button';
import {
  Ban,
  CheckCircle2,
  AlertCircle,
  Clock,
  Loader2,
  Users,
  Check,
} from 'lucide-react';
import moment from 'moment';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from '@fluency/ui/components/ui/dialog';
import { cn } from '@fluency/ui/lib/utils';
import { useInView } from 'react-intersection-observer';
import { Alert, AlertDescription } from '@fluency/ui/components/ui/alert';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@fluency/ui/components/ui/tooltip';
import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import { ResourceRequest, ResourceRequestAssignment } from '../types/types';
import { Skeleton } from '@fluency/ui/components/ui/skeleton';

interface RequestsListProps {
  documentId: string;
  isManager: boolean;
}

const TableRowSkeleton = () => (
  <TableRow>
    <TableCell>
      <div className="space-y-2">
        <Skeleton className="h-4 w-32" />
        <Skeleton className="h-3 w-48" />
      </div>
    </TableCell>
    <TableCell>
      <Skeleton className="h-4 w-24" />
    </TableCell>
    <TableCell>
      <Skeleton className="h-4 w-20" />
    </TableCell>
    <TableCell>
      <Skeleton className="h-6 w-24 rounded-full" />
    </TableCell>
    <TableCell>
      <div className="flex items-center gap-2">
        <Skeleton className="h-8 w-8 rounded" />
        <Skeleton className="h-8 w-8 rounded" />
      </div>
    </TableCell>
  </TableRow>
);

export const RequestsList: React.FC<RequestsListProps> = ({
  documentId,
  isManager,
}) => {
  const { user } = useAuth();
  const [confirmationDialog, setConfirmationDialog] = React.useState<{
    isOpen: boolean;
    request: ResourceRequest | null;
    assignment: ResourceRequestAssignment | null;
    action: 'complete' | 'cancel' | null;
  }>({
    isOpen: false,
    request: null,
    assignment: null,
    action: null,
  });

  const {
    data: allRequests = [],
    isLoading,
    isError,
    error,
    cancelRequest,
    completeRequest,
    isCancelling,
    isCompleting,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useResourceRequests(documentId);

  // Filter requests based on isManager and user.id
  const requests = React.useMemo(() => {
    if (!allRequests) return [];

    if (isManager) {
      return allRequests;
    }

    // For non-managers, filter requests to only show their own assignments
    return allRequests
      .map((request) => ({
        ...request,
        assignments: request.assignments.filter(
          (assignment) => assignment.recipientId === user?.id
        ),
      }))
      .filter((request) => request.assignments.length > 0);
  }, [allRequests, isManager, user?.id]);

  const { ref, inView } = useInView();

  React.useEffect(() => {
    if (inView && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, isFetchingNextPage, fetchNextPage]);

  const handleAction = (
    request: ResourceRequest,
    assignment: ResourceRequestAssignment,
    action: 'complete' | 'cancel'
  ) => {
    setConfirmationDialog({
      isOpen: true,
      request,
      assignment,
      action,
    });
  };

  const confirmAction = async () => {
    if (
      !confirmationDialog.request ||
      !confirmationDialog.action ||
      !confirmationDialog.assignment
    )
      return;

    try {
      if (confirmationDialog.action === 'complete') {
        completeRequest(confirmationDialog.request.id);
      } else {
        cancelRequest(confirmationDialog.request.id);
      }
    } catch (error) {
      // Error handling is managed by the mutation
    }
    setConfirmationDialog({
      isOpen: false,
      request: null,
      assignment: null,
      action: null,
    });
  };

  if (isLoading) {
    return (
      <Table>
        <TableHeader className="bg-background sticky top-0">
          <TableRow>
            <TableHead>Recipient</TableHead>
            <TableHead>Requester</TableHead>
            <TableHead>Due Date</TableHead>
            <TableHead>Status</TableHead>
            <TableHead className="w-[120px]">Actions</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {[...Array(3)].map((_, i) => (
            <TableRowSkeleton key={i} />
          ))}
        </TableBody>
      </Table>
    );
  }

  if (isError) {
    return (
      <Alert variant="destructive">
        <AlertDescription>
          {error instanceof Error ? error.message : 'Failed to load documents'}
        </AlertDescription>
      </Alert>
    );
  }

  if (!requests?.length) {
    return (
      <div className="flex flex-col items-center justify-center py-12 text-center">
        <Users className="h-12 w-12 text-muted-foreground mb-4" />
        <h3 className="text-lg font-semibold">No Active Requests</h3>
        <p className="text-sm text-muted-foreground">
          {isManager
            ? 'Create a new document request using the button above.'
            : 'No document requests have been assigned yet.'}
        </p>
      </div>
    );
  }

  return (
    <div className="relative">
      <Table>
        <TableHeader className="bg-background sticky top-0">
          <TableRow>
            <TableHead>Recipient</TableHead>
            <TableHead>Requester</TableHead>
            <TableHead>Due Date</TableHead>
            <TableHead>Status</TableHead>
            <TableHead className="w-[120px]">Actions</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {requests.map((request: ResourceRequest) =>
            request.assignments.map((assignment: ResourceRequestAssignment) => {
              const isAssignedToUser = assignment.recipientId === user?.id;
              const canComplete =
                isAssignedToUser && assignment.status !== 'COMPLETED';
              const canCancel = isManager && assignment.status !== 'COMPLETED';
              const dueDate = new Date(request.dueDate);
              const isOverdue = assignment.status === 'OVERDUE';

              return (
                <TableRow
                  key={assignment.id}
                  className={cn(
                    isAssignedToUser &&
                      assignment.status !== 'COMPLETED' &&
                      'bg-primary/5'
                  )}
                >
                  <TableCell className="flex items-center gap-2">
                    <div>
                      <div className="font-medium">
                        {`${assignment.recipient.firstName} ${assignment.recipient.lastName}`}
                        {isAssignedToUser && (
                          <span className="ml-2 text-xs bg-fluency-600 text-white text-primary px-2 py-0.5 rounded-full">
                            You
                          </span>
                        )}
                      </div>
                      <div className="text-sm text-muted-foreground">
                        {assignment.recipient.email}
                      </div>
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className="flex items-center gap-2">
                      <span className="text-sm">
                        {`${request.requester.firstName} ${request.requester.lastName}`}
                      </span>
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className="font-base">
                      {moment(dueDate).format('DD/MM/YYYY')}
                    </div>
                    {isOverdue && (
                      <div className="text-destructive text-xs font-medium mt-0.5">
                        Overdue
                      </div>
                    )}
                  </TableCell>
                  <TableCell>
                    <span
                      className={cn(
                        'inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium',
                        {
                          'bg-fluency-50 text-fluency-600':
                            assignment.status === 'COMPLETED',
                          'bg-red-50 text-red-600':
                            assignment.status === 'OVERDUE',
                          'bg-amber-50 text-amber-600':
                            assignment.status === 'PENDING',
                        }
                      )}
                    >
                      {assignment.status === 'COMPLETED' && (
                        <CheckCircle2 className="w-3 h-3 mr-1" />
                      )}
                      {assignment.status === 'OVERDUE' && (
                        <AlertCircle className="w-3 h-3 mr-1" />
                      )}
                      {assignment.status === 'PENDING' && (
                        <Clock className="w-3 h-3 mr-1" />
                      )}
                      {assignment.status}
                    </span>
                  </TableCell>
                  <TableCell>
                    <div className="flex items-center gap-2">
                      {canComplete && (
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <Button
                              variant="outline"
                              size="sm"
                              onClick={() =>
                                handleAction(request, assignment, 'complete')
                              }
                              disabled={isCompleting}
                              className="hover:bg-fluency-50 hover:text-fluency-600"
                            >
                              <Check className="w-5 h-5" />
                            </Button>
                          </TooltipTrigger>
                          <TooltipContent>
                            Mark document as completed
                          </TooltipContent>
                        </Tooltip>
                      )}
                      {canCancel && (
                        <Tooltip>
                          <TooltipTrigger asChild>
                            <Button
                              variant="outline"
                              size="sm"
                              onClick={() =>
                                handleAction(request, assignment, 'cancel')
                              }
                              disabled={isCancelling}
                              className="hover:bg-red-50 hover:text-red-600"
                            >
                              <Ban className="w-4 h-4" />
                            </Button>
                          </TooltipTrigger>
                          <TooltipContent>
                            Cancel this document request
                          </TooltipContent>
                        </Tooltip>
                      )}
                    </div>
                  </TableCell>
                </TableRow>
              );
            })
          )}
        </TableBody>
      </Table>

      {hasNextPage && (
        <div ref={ref} className="flex justify-center p-4">
          {isFetchingNextPage ? (
            <div className="flex items-center gap-2">
              <Loader2 className="h-4 w-4 animate-spin" />
              <span className="text-sm text-muted-foreground">
                Loading more...
              </span>
            </div>
          ) : (
            <div className="h-8" />
          )}
        </div>
      )}

      <Dialog
        open={confirmationDialog.isOpen}
        onOpenChange={(isOpen) =>
          setConfirmationDialog((prev) => ({ ...prev, isOpen }))
        }
      >
        <DialogContent>
          <DialogHeader>
            <DialogTitle>
              {confirmationDialog.action === 'complete'
                ? 'Complete Document'
                : 'Cancel Document Request'}
            </DialogTitle>
            <DialogDescription>
              {confirmationDialog.action === 'complete'
                ? 'Are you sure you want to mark this document as completed? This action cannot be undone.'
                : 'Are you sure you want to cancel this document request? This action cannot be undone.'}
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button
              variant="outline"
              onClick={() =>
                setConfirmationDialog({
                  isOpen: false,
                  request: null,
                  assignment: null,
                  action: null,
                })
              }
            >
              Cancel
            </Button>
            <Button
              variant={
                confirmationDialog.action === 'complete'
                  ? 'default'
                  : 'destructive'
              }
              onClick={confirmAction}
              disabled={isCompleting || isCancelling}
            >
              {isCompleting || isCancelling ? (
                <>
                  <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                  {confirmationDialog.action === 'complete'
                    ? 'Completing...'
                    : 'Cancelling...'}
                </>
              ) : confirmationDialog.action === 'complete' ? (
                'Complete Document'
              ) : (
                'Cancel Request'
              )}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default RequestsList;
