import { useReactFlow } from 'reactflow';

export interface AlignmentLine {
  id: string;
  type: 'horizontal' | 'vertical';
  position: number;
}

const AlignmentLines: React.FC<{ alignmentLines: AlignmentLine[] }> = ({
  alignmentLines,
}) => {
  const { getViewport } = useReactFlow();
  const viewport = getViewport();

  return (
    <>
      {alignmentLines.map((line) => {
        const isVertical = line.type === 'vertical';
        const style = {
          position: 'absolute' as const,
          backgroundColor: '#ff0071',
          [isVertical ? 'height' : 'width']: '100%',
          [isVertical ? 'width' : 'height']: '1px',
          [isVertical ? 'left' : 'top']: `${
            line.position * viewport.zoom + viewport[isVertical ? 'x' : 'y']
          }px`,
          pointerEvents: 'none' as const,
          zIndex: 1000,
        };

        return <div key={line.id} style={style} />;
      })}
    </>
  );
};

export default AlignmentLines;
