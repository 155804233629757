import React, { useRef, useState, useEffect } from 'react';
import { Button } from '@fluency/ui/components/ui/button';
import { Input } from '@fluency/ui/components/ui/input';
import { Label } from '@fluency/ui/components/ui/label';
import { LoaderCircle, X } from 'lucide-react';
import { convertToPng } from '../../utils/convertToPng';
import { Logger } from '@fluency/ui/features/Logger';
import useReplaceScreenshot from '@fluency/ui/features/ViewDocument/hooks/useReplaceScreenshot';
import { Alert, AlertDescription } from '@fluency/ui/components/ui/alert';

interface ChangeScreenshotSettingsProps {
  stepId: string;
  documentId: string;
  onClose?: () => void;
}

export const ChangeScreenshotSettings: React.FC<
  ChangeScreenshotSettingsProps
> = ({ stepId, documentId, onClose }) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const dropZoneRef = useRef<HTMLDivElement>(null);
  const [file, setFile] = useState<File | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [error, setError] = useState<string>('');
  const { mutate: replaceScreenshot, isPending } =
    useReplaceScreenshot(documentId);

  const handleFile = async (newFile: File) => {
    try {
      if (!newFile.type.startsWith('image/')) {
        setError('Please select an image file');
        return;
      }
      setError('');
      setFile(newFile);
      const url = URL.createObjectURL(newFile);
      setPreviewUrl(url);
    } catch (error) {
      Logger.error('Error processing file:', error);
      handleCancel();
    }
  };

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = async (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(false);

    const droppedFile = e.dataTransfer.files[0];
    if (droppedFile) {
      await handleFile(droppedFile);
    }
  };

  const handlePaste = async (e: ClipboardEvent) => {
    const items = e.clipboardData?.items;
    if (!items) return;

    for (const item of Array.from(items)) {
      if (item.type.startsWith('image/')) {
        const file = item.getAsFile();
        if (file) {
          await handleFile(file);
          break;
        }
      }
    }
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newFile = event.target.files?.[0];
    if (newFile) {
      await handleFile(newFile);
    }
  };

  const handleSubmit = async (event: React.MouseEvent) => {
    event.preventDefault();

    if (!file) return;

    try {
      const pngFile = await convertToPng(file);
      await replaceScreenshot(
        {
          file: pngFile,
          stepId,
          fileType: 'png',
        },
        {
          onSuccess: () => {
            handleCancel();
            if (onClose) {
              onClose();
            }
          },
        }
      );
    } catch (error) {
      Logger.error('Error replacing screenshot:', error);
    }
  };

  const handleCancel = () => {
    setFile(null);
    if (previewUrl) {
      URL.revokeObjectURL(previewUrl);
      setPreviewUrl(null);
    }
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    setError('');
  };

  useEffect(() => {
    document.addEventListener('paste', handlePaste);
    return () => {
      document.removeEventListener('paste', handlePaste);
      // Clean up any remaining preview URLs when component unmounts
      if (previewUrl) {
        URL.revokeObjectURL(previewUrl);
      }
    };
  }, []);

  return (
    <div className="space-y-4">
      <div className="grid gap-4">
        <div className="space-y-2">
          <Label htmlFor="screenshot">Screenshot</Label>
          <Input
            id="screenshot"
            type="file"
            accept="image/*"
            onChange={handleFileChange}
            ref={fileInputRef}
            className="hidden"
          />

          <div
            ref={dropZoneRef}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            onClick={() => fileInputRef.current?.click()}
            className={`
          relative cursor-pointer border-2 border-dashed rounded-lg p-2
          transition-all duration-200 ease-in-out
          ${
            isDragging
              ? 'border-primary bg-primary/5'
              : 'border-gray-200 hover:border-primary hover:bg-primary/5'
          }
        `}
          >
            <div className="text-center text-sm text-gray-600">
              {file ? (
                <div className="flex items-center justify-center gap-2">
                  <span className="flex-1">{file.name}</span>
                  <Button
                    variant="ghost"
                    size="icon"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleCancel();
                    }}
                    className="h-8 w-8 z-10 hover:bg-gray-300"
                  >
                    <X className="h-4 w-4" />
                  </Button>
                </div>
              ) : (
                'Drag and drop an image here or click to select'
              )}
            </div>
          </div>
        </div>

        {error && (
          <Alert variant="destructive" className="py-2">
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        )}

        {previewUrl && (
          <div className="mt-4 relative rounded-lg overflow-hidden">
            <img
              src={previewUrl}
              alt="Preview"
              className="max-h-48 w-full object-contain bg-slate-100"
            />
          </div>
        )}

        <div className="flex gap-2">
          <Button
            variant="outline"
            onClick={handleCancel}
            size="sm"
            disabled={isPending}
          >
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            disabled={isPending || !file}
            className="flex-1"
            size="sm"
          >
            {isPending ? (
              <>
                <LoaderCircle className="animate-spin w-4 h-4 mr-2" />
                Uploading...
              </>
            ) : (
              'Upload'
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ChangeScreenshotSettings;
