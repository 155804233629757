import { ErrorBoundary } from '@fluency/ui/components';
import { Toaster } from '@fluency/ui/components/ui/toaster';
import '@fluency/ui/globals.css';
import {
  AuthProvider,
  useAuth,
} from '@fluency/ui/providers/auth/AuthProvider.tsx';
import { AuthContextType } from '@fluency/ui/providers/auth/types/AuthContextType.ts';
import { ElectronProvider } from '@fluency/ui/providers/electron/ElectronContext';
import {
  PermissionGateProvider,
  usePermissionGate,
} from '@fluency/ui/providers/permissions/PermissionGateProvider.tsx';
import { RecordingProvider } from '@fluency/ui/providers/recording/RecordingContext.tsx';
import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { RouterProvider, createRouter } from '@tanstack/react-router';
import posthog from 'posthog-js';
import { StrictMode, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { routeTree } from './routeTree.gen.ts';
import { RouterContext } from './types/RouterContext.ts';

const environment = import.meta.env.NODE_ENV;
posthog.init(import.meta.env.VITE_POSTHOG_API_KEY, {
  api_host: `${import.meta.env.VITE_SERVER_API_URL}/proxyPath/sendPig`,
  person_profiles: 'always',
  ui_host: 'https://us.posthog.com',
});

Sentry.init({
  dsn: 'https://bb86cc243e255a72cc7a5019405a6457@o4505582792998912.ingest.us.sentry.io/4507740828991488',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 0.6,
  tracePropagationTargets: [
    'localhost',
    /^https:\/\/flueey\.com/,
    /^https:\/\/app\.getfluency\.com\.au/,
  ],
  profilesSampleRate: 0.5,
  // Session Replay
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  environment: environment,
});

// Create the router instance
const router = createRouter({
  routeTree,
  context: {} as RouterContext,
  trailingSlash: 'never',
});

// Declare the router
declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}

function ContextWrapper() {
  const auth: AuthContextType = useAuth();
  const permissionGate = usePermissionGate();
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider
        router={router}
        context={{
          auth,
          permissionGate,
        }}
      />
    </QueryClientProvider>
  );
}

export function PostHogIdentify() {
  const { user, orgId, organisationName } = useAuth();

  useEffect(() => {
    if (user) {
      posthog.identify(user.id, {
        email: user.email,
        name: user.firstName + ' ' + user.lastName,
        orgId,
        organisationName,
        context: 'web',
      });
    }
  }, [user]);

  return null;
}

// Root Web App
const rootElement = document.getElementById('root')!;

if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);

  root.render(
    <StrictMode>
      <ErrorBoundary>
        <ElectronProvider isElectron={false}>
          <AuthProvider>
            <PostHogIdentify />
            <PermissionGateProvider>
              <RecordingProvider>
                <ContextWrapper />
                <Toaster />
              </RecordingProvider>
            </PermissionGateProvider>
          </AuthProvider>
        </ElectronProvider>
      </ErrorBoundary>
    </StrictMode>
  );
}
