import { useToast } from '@fluency/ui/components/ui/use-toast';
import * as Sentry from '@sentry/react';
import { useMutation } from '@tanstack/react-query';
import { useAuth } from '../../providers/auth/AuthProvider';

interface ExportResponse {
  message: string;
  status: string;
  url: string;
}

interface ExportOptions {
  onSuccess?: (data: ExportResponse) => void;
  onError?: (error: Error) => void;
}

export const useDocumentExport = () => {
  const apiBaseUrl = import.meta.env.VITE_SERVER_API_URL;
  const { accessToken } = useAuth();
  const { toast } = useToast();

  const exportMutation = useMutation<
    ExportResponse,
    Error,
    { documentId: string; exportType: 'pdf' | 'word'; options?: ExportOptions }
  >({
    mutationFn: async ({ documentId, exportType }) => {
      if (!accessToken) {
        throw new Error('Access token is missing');
      }

      const endpoint = exportType === 'pdf' ? 'queueExportPdf' : 'exportMsWord';

      const response = await fetch(`${apiBaseUrl}/documentExport/${endpoint}`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `documentId=${encodeURIComponent(documentId)}`,
      });

      if (!response.ok) {
        throw new Error(`Failed to export ${exportType.toUpperCase()}`);
      }

      return await response.json();
    },
    onSuccess: (data, { options, exportType }) => {
      if (options?.onSuccess) {
        options.onSuccess(data);
      }
      if (exportType === 'pdf') {
        toast({
          title: 'PDF Export in Progress',
          description:
            'Your PDF export is being prepared. You will receive an email with the download link shortly.',
          duration: 5000,
        });
      } else if (exportType === 'word') {
        toast({
          title: 'Word Export Successful',
          description: (
            <div>
              <p>Your Word document has been exported successfully.</p>
              <a
                href={data.url}
                target="_blank"
                rel="noopener noreferrer"
                className="hover:underline text-blue-700"
              >
                Click here to open the document.
              </a>
            </div>
          ),
          duration: Infinity,
        });
      }
    },
    onError: (error, { options }) => {
      Sentry.captureException('Error exporting document');
      if (options?.onError) {
        options.onError(error);
      }
      toast({
        title: 'Export Failed',
        description:
          'There was an error exporting your document. Please try again.',
        variant: 'destructive',
        duration: 5000,
      });
    },
  });

  return {
    exportDocument: (
      documentId: string,
      exportType: 'pdf' | 'word',
      options?: ExportOptions
    ) => exportMutation.mutate({ documentId, exportType, options }),
    isExporting: exportMutation.isPending,
    exportError: exportMutation.error,
    exportData: exportMutation.data,
  };
};

export default useDocumentExport;
