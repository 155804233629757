import { useEffect } from 'react';
import { useAuth } from '@fluency/ui/providers/auth/AuthProvider';
import { Loading } from '@fluency/ui/components';
import { useNavigate } from '@tanstack/react-router';
import { Logger } from '../Logger';

const Logout = () => {
  const { logout, isAuthenticated, isLoggingOut } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const performLogout = async () => {
      if (isAuthenticated && !isLoggingOut) {
        Logger.log('[/logout] Initiating logout process');
        await logout();
        // The logout function in AuthProvider now handles the redirection
      } else if (!isAuthenticated) {
        Logger.log('[/logout] User is already logged out');
        navigate({ to: '/login', replace: true });
      }
    };

    performLogout();
  }, [logout, isAuthenticated, isLoggingOut]);

  return <Loading message="Logging out..." />;
};

export default Logout;
