import { ElementType } from 'react';
import { Link, useRouter } from '@tanstack/react-router';
import { cn } from '@fluency/ui/lib/utils';

export interface NavItem {
  title: string;
  path: string;
  setting: string;
  icon?: ElementType;
  type?: 'danger';
}

interface NavSectionProps {
  title: string;
  items: NavItem[];
  id?: string;
}

const NavSection = ({ title, items, id }: NavSectionProps) => {
  if (items.length === 0) return null;
  const router = useRouter();
  const fullPath = router.state.location.pathname;

  return (
    <div className="space-y-2">
      <div className="flex items-center gap-2 px-2 py-2">
        <span className="text-xs font-medium uppercase tracking-wider text-slate-500">
          {title}
        </span>
      </div>
      <nav className="grid gap-1">
        {items.map((item, index) => (
          <Link
            key={index}
            to={`${item.path}${id ? `/${id}` : ''}`}
            className={cn(
              'flex items-center gap-2 rounded-md px-2 py-1.5',
              'text-sm font-normal transition-colors',
              item.type === 'danger'
                ? cn(
                    'text-red-600 hover:bg-red-50',
                    fullPath.includes(item.path) ? 'bg-red-50' : ''
                  )
                : cn(
                    'hover:bg-accent hover:text-accent-foreground',
                    fullPath.includes(item.path)
                      ? 'bg-accent text-accent-foreground'
                      : 'text-foreground'
                  )
            )}
          >
            {item.icon && (
              <item.icon
                className={cn(
                  'h-4 w-4',
                  item.type === 'danger' ? 'text-red-600' : ''
                )}
              />
            )}
            {item.title}
          </Link>
        ))}
      </nav>
    </div>
  );
};

export default NavSection;
