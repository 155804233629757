import React, { useEffect, useRef } from 'react';
import { Input } from '@fluency/ui/components/ui/input';
import { Textarea } from '@fluency/ui/components/ui/textarea';
import { Button } from '@fluency/ui/components/ui/button';
import { PencilLine } from 'lucide-react';
import {
  usePermissionGate,
  PermissionGateProvider,
} from '@fluency/ui/providers/permissions/PermissionGateProvider';

import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { titleSchema, contentSchema } from '../schemas';

const DEFAULT_TITLE = 'Empty Document';
const DEFAULT_DESCRIPTION = 'This is a new empty document';

interface EditableInputProps {
  isEdit: boolean;
  currentValue: string;
  updateDoc: ({
    documentationName,
    documentationDescription,
  }: {
    documentationName?: string;
    documentationDescription?: string;
  }) => void;
  setCurrentValue: (value: string) => void;
  setIsEdit: (edit: boolean) => void;
  isTitle: boolean;
  isMostRecentVersion: boolean;
}

const EditableInput: React.FC<EditableInputProps> = ({
  isEdit,
  currentValue,
  updateDoc,
  setCurrentValue,
  setIsEdit,
  isTitle,
  isMostRecentVersion,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { hasPermissions } = usePermissionGate();

  const schema = isTitle ? titleSchema : contentSchema;

  const form = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      value: currentValue,
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = form;

  const onSubmit = (data: { value: string }) => {
    // If empty after editing default values, revert to defaults
    const newValue =
      data.value.trim() === ''
        ? isTitle
          ? DEFAULT_TITLE
          : DEFAULT_DESCRIPTION
        : data.value;

    if (newValue !== currentValue) {
      updateDoc({
        [isTitle ? 'documentationName' : 'documentationDescription']: newValue,
      });
      setCurrentValue(newValue);
    }
    setIsEdit(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        isEdit &&
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        handleSubmit(onSubmit)();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isEdit, handleSubmit, onSubmit]);

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (isTitle && e.key === 'Enter') {
      e.preventDefault();
      handleSubmit(onSubmit)();
    } else if (!isTitle && e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(onSubmit)();
    } else if (e.key === 'Escape') {
      setIsEdit(false);
      form.reset({ value: currentValue });
    }
  };

  useEffect(() => {
    if (isEdit) {
      // Clear the input if it contains default value
      const isDefault =
        currentValue === (isTitle ? DEFAULT_TITLE : DEFAULT_DESCRIPTION);
      form.reset({ value: isDefault ? '' : currentValue });
    }
  }, [isEdit, currentValue, form, isTitle]);

  const renderText = () => {
    const className = `font-${
      isTitle ? 'medium text-lg' : 'normal text-md mt-1'
    } flex items-center ${
      isEdit ? 'cursor-pointer' : 'cursor-default'
    } break-all`;
    const content = isTitle ? (
      <h2 className={className}>{currentValue}</h2>
    ) : (
      <p className={`${className} whitespace-pre-wrap text-sm`}>
        {currentValue}
      </p>
    );

    return (
      <div
        onClick={
          isMostRecentVersion && hasPermissions(['documents:update'])
            ? () => setIsEdit(true)
            : undefined
        }
      >
        {content}
      </div>
    );
  };

  return (
    <div ref={containerRef} className="flex items-start gap-2">
      {isEdit ? (
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex items-start gap-2 w-full"
        >
          {isTitle ? (
            <div className="flex flex-col gap-2">
              <Input
                {...register('value')}
                onKeyDown={handleKeyDown}
                autoFocus
              />
              {errors.value && (
                <p className="text-red-500 mt-1 font-semibold w-96 text-sm">
                  {errors.value.message}
                </p>
              )}
            </div>
          ) : (
            <div className="w-full">
              <Textarea
                {...register('value')}
                onKeyDown={handleKeyDown}
                autoFocus
                rows={4}
                className="w-full"
              />
              {errors.value && (
                <p className="text-red-500 mt-1 font-semibold text-xs">
                  {errors.value.message}
                </p>
              )}
            </div>
          )}
          <div className="print:hidden flex ml-2 gap-2">
            <Button type="submit" variant="default" size="sm">
              Save
            </Button>
            <Button
              onClick={() => {
                setIsEdit(false);
                form.reset({ value: currentValue });
              }}
              variant="outline"
              size="sm"
            >
              Cancel
            </Button>
          </div>
        </form>
      ) : (
        <>
          {renderText()}
          <PermissionGateProvider permissions={['documents:update']}>
            {isMostRecentVersion && (
              <span className="print:hidden">
                <Button
                  onClick={() => {
                    setIsEdit(true);
                  }}
                  className="h-7 w-7 text-gray-400"
                  variant="ghost"
                  size="icon"
                >
                  <PencilLine className="h-4 w-4 " />
                </Button>
              </span>
            )}
          </PermissionGateProvider>
        </>
      )}
    </div>
  );
};

export default EditableInput;
