import { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@fluency/ui/components/ui/dialog';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@fluency/ui/components/ui/form';
import { Input } from '@fluency/ui/components/ui/input';
import { Button } from '@fluency/ui/components/ui/button';
import { AlertCircle, Plus } from 'lucide-react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  createOrganisationSchema,
  CreateOrgFormData,
} from './schema/createOrganisationSchema';
import { useOrganisationOperations } from './hooks/useOrganisationOperations';
import { Logger } from '../../Logger';
import { Alert, AlertDescription } from '@fluency/ui/components/ui/alert';
import { toast } from '@fluency/ui/components/ui/use-toast';

const CreateOrganisationDialog = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { useCreateOrganisation } = useOrganisationOperations();
  const { mutateAsync, isPending, error } = useCreateOrganisation();

  const form = useForm<CreateOrgFormData>({
    resolver: zodResolver(createOrganisationSchema),
    defaultValues: {
      orgName: '',
    },
  });

  const onSubmit = async (data: CreateOrgFormData) => {
    try {
      await mutateAsync(data);
      setIsOpen(false);
      toast({
        title: 'Success',
        description: `Organisation ${data.orgName} created successfully`,
      });
    } catch (error) {
      Logger.error('Failed to create organisation', error);
    }
  };

  const handleOpenChange = () => {
    setIsOpen(!isOpen);
    form.reset();
  };

  return (
    <Dialog open={isOpen} onOpenChange={handleOpenChange}>
      <DialogTrigger asChild>
        <Button size="sm">
          <Plus className="mr-2 h-4 w-4" /> Create Organisation
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Create New Organisation</DialogTitle>
        </DialogHeader>

        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
            <FormField
              control={form.control}
              name="orgName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Organisation Name</FormLabel>
                  <FormControl>
                    <Input {...field} placeholder="Enter organisation name" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            {error && (
              <Alert variant="destructive" className="mt-4">
                <AlertCircle className="h-4 w-4" />
                <AlertDescription>
                  <div>{'Failed to create organisation'}</div>
                  <div>{`Current Created Ids:`}</div>
                  <div>{`WorkOS ID: ${
                    error.details?.createdIds?.workosId ?? 'N/A'
                  }`}</div>
                  <div>{`Stripe ID: ${
                    error.details?.createdIds?.stripeId ?? 'N/A'
                  }`}</div>
                  <div>{`DB ID: ${
                    error.details?.createdIds?.dbId ?? 'N/A'
                  }`}</div>
                </AlertDescription>
              </Alert>
            )}

            <DialogFooter>
              <Button type="submit" disabled={isPending}>
                {isPending ? 'Creating...' : 'Create Organisation'}
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default CreateOrganisationDialog;
