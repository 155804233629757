import { QueryClient } from '@tanstack/react-query';
import { useNavigate } from '@tanstack/react-router';

interface DocumentToastContentProps {
  message: string;
}

interface ShowDocumentToastParams {
  message: string;
  link: string;
  docId: string;
  toast: (props: {
    title: string;
    description: React.ReactNode;
    variant?: 'default' | 'destructive';
    onClick?: () => void;
    className?: string;
  }) => void;
  queryClient: QueryClient;
  navigate: ReturnType<typeof useNavigate>;
}

export const DocumentToastContent: React.FC<DocumentToastContentProps> = ({
  message,
}) => (
  <div className="space-y-2">
    <p>{message}</p>
    <p className="text-sm text-fluency-600">Click here to open</p>
  </div>
);

export const showDocumentToast = async ({
  message,
  link,
  docId,
  toast,
  queryClient,
  navigate,
}: ShowDocumentToastParams): Promise<void> => {
  // First invalidate and wait for the new data
  await queryClient.invalidateQueries({
    queryKey: ['docInfo', docId],
  });

  navigate({ to: link as any, replace: true });
  // Then show toast and handle navigation
  toast({
    title: 'Document Created',
    description: <DocumentToastContent message={message} />,
    variant: 'default',
    onClick: () => {
      navigate({ to: link as any, replace: false });
    },
    className: 'cursor-pointer',
  });
};
