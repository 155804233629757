import { Building, UserCog, UserPlus, Wallet } from 'lucide-react';
import { NavItem } from '../../Layout/NavSection';

export const navigationItems: { title: string; items: NavItem[] }[] = [
  {
    title: 'Account',
    items: [
      {
        title: 'Organisation',
        path: '/admin/organisation/detail',
        setting: 'detail',

        icon: UserCog,
      },
      {
        title: 'Subscription',
        path: '/admin/organisation/subscription',
        setting: 'subscription',
        icon: Wallet,
      },
    ],
  },
  {
    title: 'Team',
    items: [
      {
        title: 'Member',
        path: '/admin/organisation/member',
        setting: 'member',
        icon: UserPlus,
      },
    ],
  },
  {
    title: 'Administration',
    items: [
      {
        title: 'Advanced Settings',
        path: '/admin/organisation/advanced-settings',
        setting: 'settings',
        icon: Building,
      },
    ],
  },
];
